/*-------- 8. Instagram style ---------*/

.instagram-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -40px;
    @media #{$xx-layout} {
        margin: 0 -20px;
    }
    @media #{$xl-layout} {
        margin: 0 -15px;
    }
    @media #{$lg-layout} {
        margin: 0 -15px;
    }
    @media #{$md-layout} {
        margin: 0 -20px;
    }
    @media #{$xs-layout} {
        margin: 0 -15px;
    }
    @media #{$sm-layout} {
        margin: 0 -15px;
    }
    .instagram-item {
        position: relative;
        flex: 0 0 25%;
        padding: 0 40px;
        @media #{$xx-layout} {
            padding: 0 20px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 15px;
        }
        @media #{$md-layout} {
            padding: 0 22px 40px;
            flex: 0 0 50%;
        }
        @media #{$xs-layout} {
            padding: 0 15px 30px;
            flex: 0 0 100%;
            &:last-child {
                padding: 0 15px 0px;
            }
        }
        @media #{$sm-layout} {
            padding: 0 15px 30px;
            flex: 0 0 50%;
        }
        > a {
            display: block;
            position: relative;
            &::before {
                background-color: rgba(0, 0, 0, 0.4);
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                transition: all 0.3s ease 0s;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                height: 100%;
                width: 100%;
                z-index: 9;
            }
            img {
                width: 100%;
            }
        }
        > .instagram-hvr-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease 0s;
            z-index: 99;
            span {
                i {
                    color: #6ec1e4;
                    margin-right: 3px;
                }
                font-size: 20px;
                margin: 0 5px;
                color: #fff;
                @media #{$lg-layout} {
                    font-size: 18px;
                }
            }
        }
        &:hover > a::before {
            opacity: 1;
            visibility: visible;
        }
        &:hover > .instagram-hvr-content {
            opacity: 1;
            visibility: visible;
        }
    }
    &.white-instagram {
        .instagram-item {
            > .instagram-hvr-content {
                span {
                    i {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.instagram-wrap-2 {
    display: flex;
}

.instagram-wrap-3 {
    display: flex;
    flex-wrap: wrap;
    li {
        position: relative;
        flex: 0 0 20%;
        list-style: none;
        > a {
            display: block;
            position: relative;
            &::before {
                background-color: rgba(84, 89, 95, 0.6);
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                transition: all 0.3s ease 0s;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                height: 100%;
                width: 100%;
                z-index: 9;
            }
            img {
                width: 100%;
            }
        }
        &:hover > a::before {
            opacity: 1;
            visibility: visible;
        }
        @media #{$xs-layout} {
            flex: 0 0 50%;
        }
        @media #{$sm-layout} {
            flex: 0 0 33.33%;
        }
    }
}


.instagram-feed-hm18 {
    margin: 0 -10px;
    .instagram-wrap-4 {
        display: flex;
        flex-wrap: wrap;
        li {
            position: relative;
            flex: 0 0 25%;
            list-style: none;
            padding: 0 10px;
            margin-bottom: 20px;
            @media #{$xs-layout} {
                flex: 0 0 50%;
            }
            @media #{$sm-layout} {
                flex: 0 0 25%;
            }
            > a {
                display: block;
                position: relative;
                &::before {
                    background-color: rgba(0, 0, 0, 0.8);
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    height: 100%;
                    width: 100%;
                    z-index: 9;
                }
                img {
                    width: 100%;
                }
            }
            &:hover > a::before {
                opacity: 1;
                visibility: visible;
            }
            .instagram-plus {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.35s ease 0s;
                opacity: 0;
                visibility: hidden;
            }
            &:hover > .instagram-plus {
                opacity: 1;
                visibility: visible;
                z-index: 9;
            }
        }
    }
} 

.instagram-feed-thumb {
    @media #{$xs-layout} {
        &.mt-65 {
            margin-top: 26px;
        }
        &.mt-55 {
            margin-top: 26px;
        }
    }
}

@media #{$sm-layout} {
    .instagram-area {
        &.pb-100 {
            padding-bottom: 25px;
        }
    }
}

@media #{$md-layout} {
    .instagram-area {
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}













