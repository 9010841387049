/*-------- 6. Product style ---------*/

.pro-tab-viewmore-wrap {
    position: relative;
    .pro-viewmore {
        position: absolute;
        right: 40px;
        top: 0;
        @media #{$xs-layout} {
            position: static;
            right: 0px;
            left: 0;
            text-align: center;
            margin-bottom: 30px;
        }
        a {
            font-size: 11px;
            color: #373737;
            display: inline-block;
            position: relative;
            &:hover {
                color: $theme-color;
            }
            &::before {
                position: absolute;
                content: "";
                height: 1px;
                width: 30px;
                background-color: #ccc;
                right: -40px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.product-tab-list {
	display: flex;
	justify-content: center;
    @media #{$xs-layout} {
        &.mt-60 {
            margin-top: 20px;
        }
        &.mt-40 {
            margin-top: 20px;
        }
        &.mb-25 {
            margin-bottom: 15px;
        }
    }
    &.tab-list-width {
        max-width: 1000px;
        margin: 60px auto 25px;
        @media #{$xl-layout} {
            max-width: 800px;
        }
        @media #{$lg-layout} {
            max-width: 600px;
        }
        @media #{$md-layout} {
            max-width: 460px;
        }
        @media #{$xs-layout} {
            margin: 20px auto 0px;
            max-width: 100%;
        }
        a {
            @media #{$md-layout} {
                margin: 0 6px;
            }
        }
    }
    a {
        color: #373737;
        text-transform: uppercase;
        margin: 0 20px;
        display: inline-block;
        position: relative;
        padding: 0 0 22px 0;
        @media #{$xs-layout} {
            margin: 0 10px;
        }
        &::before {
            content: "";
            bottom: 0;
            width: 2px;
            height: 2px;
            background: #262626;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            margin: auto;
            opacity: 0;
            transition: all 0.3s;
            @media #{$xs-layout} {
                bottom: 5px;
            }
        }
        &.active , &:hover {
            color: #262626;
        }
        &.active::before , &:hover::before {
            opacity: 1;
            width: 2px;
            height: 18px;
        }
    }
    &.tab-color-2 {
        a {
            color: #777777;
            &.active , &:hover {
                color: #262626;
            }
        }
    }
}

.product-wrap {
    .product-img {
        position: relative;
        overflow: hidden;
        > a {
            display: block;
            position: relative;
            img {
                width: 100%;
                &.hover-img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
                }
            }
            > span.price-dec {
                background-color: #DE3535;
                display: inline-block;
                color: #fff;
                right: 20px;
                top: 20px;
                width: 55px;
                height: 55px;
                line-height: 55px;
                border-radius: 100%;
                text-align: center;
                position: absolute;
            }
            > span.out-of-stock {
                background-color: #262626;
                display: inline-block;
                color: #fff;
                right: 20px;
                top: 20px;
                padding: 10px 20px;
                text-align: center;
                position: absolute;
                font-size: 10px;
                line-height: 1;
            }
        }
        .product-action {
            display: inline-block;
            position: absolute;
            right: 20px;
            bottom: 75px;
            z-index: 99;
            a {
                background-color: rgba(38, 38, 38, 0.9);
                border-radius: 50px;
                color: #fff;
                display: block;
                font-size: 18px;
                height: 45px;
                left: 30px;
                margin-bottom: 5px;
                opacity: 0;
                position: relative;
                text-align: center;
                visibility: hidden;
                width: 45px;
                i {
                    line-height: 45px;
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                span {
                    visibility: hidden;
                    position: absolute;
                    background-color: inherit;
                    text-align: center;
                    padding: 5px 12px;
                    z-index: 3;
                    opacity: 0;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 11px;
                    right: 100%;
                    line-height: 14px;
                    top: 50%;
                    margin-top: -12px;
                    margin-right: 0;
                    &::after {
                        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e77878;
                        border-style: solid;
                        border-width: 5px 0 5px 5px;
                        content: "";
                        height: 0;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 100%;
                        width: 0;
                    }
                }
                &:hover {
                    background-color: $theme-color;
                }
                &:hover span {
                    visibility: visible;
                    opacity: 1;
                    margin-right: 10px;
                }
            }
            &.pro-action-width-dec {
                a {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    i {
                        line-height: 30px;
                    }
                }
            }
            &.pro-action-width-dec-2 {
                bottom: 45px;
                @media #{$xx-layout} {
                    bottom: 5px;
                }
                @media #{$xl-layout} {
                    bottom: 5px;
                }
                @media #{$lg-layout} {
                    bottom: 5px;
                }
                @media #{$md-layout} {
                    bottom: 5px;
                }
                @media #{$xs-layout} {
                    bottom: 5px;
                }
                @media #{$sm-layout} {
                    bottom: 20px;
                }
                a {
                    width: 40px;
                    height: 40px;
                    font-size: 18px;
                    margin-bottom: 10px;
                    @media #{$xx-layout} {
                        margin-bottom: 5px;
                    }
                    @media #{$xl-layout} {
                        margin-bottom: 5px;
                        width: 35px;
                        height: 35px;
                        font-size: 15px;
                    }
                    @media #{$lg-layout} {
                        margin-bottom: 5px;
                        width: 35px;
                        height: 35px;
                        font-size: 15px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 5px;
                        width: 35px;
                        height: 35px;
                        font-size: 15px;
                    }
                    @media #{$xs-layout} {
                        margin-bottom: 5px;
                        width: 35px;
                        height: 35px;
                        font-size: 15px;
                    }
                    i {
                        line-height: 40px;
                        @media #{$xl-layout} {
                            line-height: 35px;
                        }
                        @media #{$lg-layout} {
                            line-height: 35px;
                        }
                        @media #{$md-layout} {
                            line-height: 35px;
                        }
                        @media #{$xs-layout} {
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .product-action-2 {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            transform: translate(0, 40px);
            text-align: center;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            transition: all 250ms ease-out;
            a {
                display: block;
                background-color: rgba(38, 38, 38, 0.9);
                color: #fff;
                text-align: center;
                font-size: 12px;
                text-transform: uppercase;
                padding: 20px 20px 23px;
                line-height: 1;
                &:hover {
                    background-color: $theme-color;
                }
            }
        }
        .product-action-3 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
            .product-action-3-top {
                display: flex;
                width: auto;
                max-width: 165px;
                position: static;
                right: 10px;
                bottom: 70px;
                a {
                    color: #111;
                    background-color: #fff;
                    font-size: 18px;
                    width: 45px;
                    height: 45px;
                    text-align: center;
                    margin: 5px;
                    transition: all 250ms ease-out;
                    position: relative;
                    opacity: 0;
                    visibility: hidden;
                    transform: translate(0, 30px);
                    &:nth-child(1) {
                        transition-delay: 100ms;
                    }
                    &:nth-child(2) {
                        transition-delay: 150ms;
                    }
                    &:nth-child(3) {
                        transition-delay: 200ms;
                    }
                    i {
                        line-height: 45px;
                    }
                    span {
                        position: absolute;
                        background-color: inherit;
                        text-align: center;
                        padding: 5px 12px;
                        z-index: 3;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity .6s, margin .3s;
                        white-space: pre;
                        font-size: 11px;
                        font-family: sans-serif;
                        line-height: 14px;
                        right: auto;
                        top: auto;
                        bottom: 100%;
                        margin-top: 0;
                        transform: translateX(-50%);
                        left: 50%;
                        &:after {
                            left: 50%;
                            transform: translateX(-50%);
                            top: 100%;
                            margin-top: 0;
                            content: "";
                            position: absolute;
                            border-top: 5px solid #e77878;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                        }
                    }
                    &:hover {
                        background-color: $theme-color;
                        color: #fff;
                    }
                    &:hover span {
                        visibility: visible;
                        opacity: 1;
                        margin-bottom: 10px;
                    }
                }
            }
            .product-action-3-bottom {
                a {
                    text-transform: uppercase;
                    color: #111;
                    background-color: #fff;
                    font-size: 12px;
                    display: inline-block;
                    padding: 17px 15px 16px;
                    line-height: 1;
                    margin: 5px;
                    opacity: 0;
                    visibility: hidden;
                    transform: translate(0, 30px);
                    transition: all 250ms ease-out;
                    transition-delay: 100ms;
                    width: 155px;
                    text-align: center;
                    &:hover {
                        background-color: $theme-color;
                        color: #fff;
                    }
                }
            }
        }
        .product-price-3 {
            position: absolute;
            left: 20px;
            bottom: 15px;
            opacity: 0;
            visibility: hidden;
            transition: all .5s ease 0s;
            span {
                font-size: 16px;
            }
        }
        &.product-border {
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: rgba(0,0,0,0.16);
        }
        .shop-list-quickview {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            z-index: 99;
            margin-top: 20px;
            visibility: hidden;
            opacity: 0;
            transition: all .35s ease 0s;
            a {
                color: #000;
                background-color: #fff;
                display: inline-block;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                font-size: 18px;
                &:hover {
                    background-color: $theme-color;
                    color: #fff;
                }
                i {
                    line-height: 50px;
                }
            }
        }
        .product-content-11 {
            position: absolute;
            width: calc(100% - 60px);
            bottom: 0;
            left: 0;
            padding: 20px;
            opacity: 0;
            visibility: hidden;
            transition: all .35s ease 0s;
            z-index: 9999;
            h3 {
                font-size: 16px;
                color: #fff;
                margin: 0;
                a {
                    color: #fff;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .product-price-5 {
                margin: 10px 0 2px;
                display: block;
                span {
                    line-height: 20px;
                    font-size: 16px;
                    color: #fff;
                    &.old {
                        text-decoration: line-through;
                        margin-right: 5px;
                    }
                }
            }
            .product-rating-2 {
                line-height: 1;
                i {
                    color: #d5d6d8;
                    font-size: 12px;
                    &.yellow {
                        color: #f5b223;
                    }
                }
            }
        }
        &:hover {
            &.default-overlay::before {
                background-color: rgba(38, 38, 38, 0.2);
                z-index: 9;
                transition: all 250ms ease-out;
                pointer-events: none;
                opacity: 1;
            }
            &.default-overlay.metro-overlay::before {
                background-color: rgba(38, 38, 38, 0.4);
                z-index: 9;
                transition: all 250ms ease-out;
                pointer-events: none;
                opacity: 1;
            }
            & img.hover-img {
                opacity: 1;
            }
        }
    }
    .product-content {
        h3 {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
        }
        .product-price {
            margin: 8px 0 3px;
            span {
                font-size: 16px;
                &.old {
                    text-decoration: line-through;
                    opacity: .6;
                    margin-right: 2px;
                }
            }
        }
        .product-rating {
            line-height: 1;
            i {
                font-size: 12px;
                color: #d5d6d8;
                &.yellow {
                    color: #f5b223;
                }
            }
        }
        &.product-content-hm14 {
            h3 {
                font-size: 14px;
                font-weight: 300;
            }
        }
        &.pro-content-pro-details {
            h3 {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .product-content-8 {
        h3 {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;
        }
    }
    .product-content-9 {
        h3 {
            font-size: 20px;
            font-weight: 400;
            margin: 0 0 6px;
        }
        .product-price {
            span {
                font-size: 16px;
                font-weight: bold;
                &.old {
                    text-decoration: line-through;
                    margin-right: 3px;
                    color: #777;
                }
            }
        }
        &.font-width-dec3 {
            h3 {
                font-weight: 300;
                margin: 0 0 9px;
            }
            .product-price {
                span {
                    font-weight: 600;
                }
            }
        }
    }
    .product-content-10 {
        h3 {
            font-family: $robotocondensed;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 12px;
            a {
                color: #262626;
                &:hover {
                    color: #bdbd74;
                }
            }
        }
        .product-price-4 {
            span {
                color: #bdbd74;
                font-family: $robotocondensed;
                font-size: 24px;
                font-weight: 400;
            }
        }
        &.pro-content-10-white {
            h3 {
                a {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 300;
                    margin: 0 0 9px;
                    &:hover {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
            .product-price-4 {
                span {
                    color: #fff;
                }
            }
        }
    }
    &:hover {
        .product-action-2 {
            transform: translate(0px, 0);
            opacity: 1;
            visibility: visible;
        }
        .product-action-3 {
            .product-action-3-top {
                a {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, 0px);
                }
            }
        }
        .product-action-3 {
            .product-action-3-bottom {
                a {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, 0px);
                }
            }
        }
        .product-price-3 {
            opacity: 1;
            visibility: visible;
        }
        .pro-action-width-dec a {
            left: 0;
            opacity: 1;
            visibility: visible;
        }
        .default-overlay.pro-overlay-hover::before {
            background-color: rgba(38, 38, 38, 0.2);
            z-index: 9;
            transition: all 250ms ease-out;
            pointer-events: none;
            opacity: 1;
        }
        .product-action a {
            left: 0;
            opacity: 1;
            visibility: visible;
        }
        .product-content-11 {
            opacity: 1;
            visibility: visible;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 30px;
        }
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
    &.product-wrap-hm18 {
        .product-img {
            .product-action {
                a {
                    span {
                        &::after {
                            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #bdbd74;
                        }
                    }
                    &:hover {
                        background-color: #bdbd74;
                    }
                }
            }
            .product-action-2 {
                a {
                    &:hover {
                        background-color: #bdbd74;
                    }
                }
            }
        }
    }
}
@media #{$xs-layout} {
    .product-slider-active {
        &.mt-70 {
            margin-top: 26px;
        }
    }
    .special-offer-area {
        &.pt-40 {
            padding-top: 10px;
        }
    }
    .new-trend-area {
        &.pb-45 {
            padding-bottom: 15px;
        }
    }
    .product-area {
        &.pb-45 {
            padding-bottom: 20px;
        }
        &.pb-40 {
            padding-bottom: 15px;
        }
    }
    .popular-collection-area {
        &.pb-85 {
            padding-bottom: 35px;
        }
    }
    .popular-collection-active {
        &.pt-60 {
            padding-top: 30px;
        }
    }
}

.tab-content{
    &.jump {
        .tab-pane {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
            &.active {
                display: block;
                height: auto;
                opacity: 1;
                overflow: visible;
                animation-name: moveUp;
                animation-duration: 500ms;
                animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
                animation-play-state: running;
            }
        }
    }
}

.padding-10-row-col {
    .row {
        margin-right: -10px;
        margin-left: -10px;
        div[class^="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
} 
.padding-5-row-col {
    .row {
        margin-right: -5px;
        margin-left: -5px;
        div[class^="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
} 

.padding-32-row-col {
    .row {
        margin-right: -32.5px;
        margin-left: -32.5px;
        div[class^="col-"] {
            padding-left: 32.5px;
            padding-right: 32.5px;
        }
    }
} 

.custom-container {
	width: 1430px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
    @media #{$xx-layout} {
        width: 1266px;
    }
    @media #{$xl-layout} {
        width: 1200px;
    }
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        max-width: 720px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
    @media #{$sm-layout} {
        max-width: 540px;
    }
}

.custom-container-5 {
	width: 1270px;
	margin-right: auto;
	margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media #{$xx-layout} {
        width: 1266px;
    }
    @media #{$xl-layout} {
        width: 1200px;
    }
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        width: 708px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
}

.product-viewmore-wrap {
    line-height: 1;
    a {
        position: relative;
        display: inline-block;
        line-height: 1;
        overflow: hidden;
        margin-right: 1px;
        .pro-viewmore-common {
            transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
            padding: 16px 40px 20px 40px;
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: rgba(0,0,0,0.14);
            text-align: center;
            span {
                color: #373737;
                font-size: 11px;
            }
            &.viewmore-hm14 {
                span {
                    font-size: 12px;
                    font-weight: 300;
                }
            }
            &.viewmore-hm14-2 {
                padding: 12px 40px 16px 40px;
            }
            &.viewmore-hm15 {
                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #262626;
                }
            }
        }
        .pro-viewmore-normal {
            opacity: 1;
            transform: scale(1);
        }
        .pro-viewmore-hover {
            opacity: 0;
            transform: scale(1.5);
            background-color: #54595f;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            span {
                color: #fff;
            }
            &.viewmore-hm14-2 {
                background-color: $theme-color;
            }
            &.viewmore-hm15 {
                background-color: $theme-color;
            }
        }
        &:hover > .pro-viewmore-normal {
            opacity: 0;
            transform: scale(0);
        }
        &:hover > .viewmore-hm14-2 {
            border-color: $theme-color;
        }
        &:hover > .viewmore-hm15 {
            border-color: $theme-color;
            span {
                color: #fff;
            }
        }
        &:hover > .pro-viewmore-hover {
            opacity: 1;
            transform: scale(1);
        }
    }
    &.about-learnwmore-btn {
        a {
            .pro-viewmore-common {
                padding: 12px 40px 14px 40px;
                span {
                    font-size: 12px;
                }
            }
            .pro-viewmore-hover {
                background-color: $theme-color;
            }
            &:hover > .pro-viewmore-common {
                border-color: $theme-color;
            }
        }
    }
    &.deal-btn-wrap {
        width: 100%;
        text-align: right;
        @media #{$xs-layout} {
            text-align: center;
        }
        a {
            .pro-viewmore-common {
                padding: 23px 40px 23px 40px;
                background-color: #50e3c2;
                border: none;
                @media #{$md-layout} {
                    padding: 15px 15px 15px 15px;
                }
                @media #{$xs-layout} {
                    padding: 15px 20px 15px 20px;
                }
                span {
                    font-size: 16px;
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #262626;
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    @media #{$md-layout} {
                        font-size: 13px;
                    }
                    @media #{$xs-layout} {
                        font-size: 14px;
                    }
                }
            }
            .pro-viewmore-hover {
                background-color: $theme-color;
            }
            &:hover > .pro-viewmore-common {
                border-color: $theme-color;
                border: none;
            }
        }
    }
}

.call-to-action-area {
    position: relative;
    .call-to-action-img-area {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;
        perspective: 1200px;
        .call-to-action-img {
            background-size: auto;
            background-position: 0% calc(50% + 78.5px);
            position: absolute;
            left: 0;
            top: -10%;
            height: 120%;
            width: 100%;
            background-repeat: no-repeat;
            @media #{$xx-layout} {
                background-size: 70% !important;
                background-position: -40% calc(50% + 118.5px);
            }
            @media #{$xl-layout} {
                background-size: 70% !important;
                background-position: -40% calc(50% + 118.5px);
            }
            @media #{$lg-layout} {
                background-size: 70% !important;
                background-position: -40% calc(50% + 35.5px);
            }
            @media #{$md-layout} {
                background-size: 70% !important;
                background-position: -40% calc(50% + 5.5px);
            }
            @media #{$xs-layout} {
                background-size: 95% !important;
                background-position: -40% calc(50% + 5.5px);
            }
        }
    }
    .call-to-action-content {
        margin-left: 30px;
        position: relative;
        z-index: 9;
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        h5 {
            font-size: 20px;
            letter-spacing: 2.5px;
            font-weight: 300;
            font-family: $playfairdisplay;
            margin: 0;
            line-height: 1;
            @media #{$xs-layout} {
                font-size: 17px;
                letter-spacing: 0px;
            }
        }
        h1 {
            font-size: 120px;
            letter-spacing: 15px;
            font-weight: 300;
            font-family: $playfairdisplay;
            line-height: 1em;
            margin: 20px 0 51px;
            @media #{$xx-layout} {
                font-size: 100px;
            }
            @media #{$xl-layout} {
                font-size: 100px;
            }
            @media #{$lg-layout} {
                font-size: 80px;
            }
            @media #{$md-layout} {
                font-size: 60px;
                margin: 20px 0 35px;
            }
            @media #{$xs-layout} {
                font-size: 37px;
                margin: 15px 0 25px;
                letter-spacing: 10px;
            }
        }
    }
    @media #{$xx-layout} {
        &.pt-200 {
            padding-top: 100px;
        }
        &.pb-200 {
            padding-bottom: 100px;
        }
    }
    @media #{$xl-layout} {
        &.pt-200 {
            padding-top: 100px;
        }
        &.pb-200 {
            padding-bottom: 100px;
        }
    }
    @media #{$lg-layout} {
        &.pt-200 {
            padding-top: 100px;
        }
        &.pb-200 {
            padding-bottom: 100px;
        }
    }
    @media #{$md-layout} {
        &.pt-200 {
            padding-top: 80px;
        }
        &.pb-200 {
            padding-bottom: 80px;
        }
    }
    @media #{$xs-layout} {
        &.pt-200 {
            padding-top: 50px;
        }
        &.pb-200 {
            padding-bottom: 50px;
        }
    }
}
.call-to-action-wrap {
    line-height: 1;
    z-index: 9;
    position: relative;
    a {
        position: relative;
        display: inline-block;
        line-height: 1;
        overflow: hidden;
        margin-right: 1px;
        .call-to-action-common {
            transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
            padding: 18px 40px 20px 40px;
            border: 1px solid #262626;
            text-align: center;
            @media #{$xs-layout} {
                padding: 14px 30px 16px 30px;
            }
            span {
                color: #262626;
                font-size: 14px;
                font-weight: 500;
            }
            &.btn-wd-dec {
                padding: 23px 40px 25px 40px;
                @media #{$xs-layout} {
                    padding: 14px 30px 16px 30px;
                }
                span {
                    font-weight: 400;
                } 
            }
            &.deal-btn {
                padding: 12px 40px 14px 40px;
                border: 2px solid #fff;
                span {
                    font-weight: 500;
                    font-size: 11px;
                    color: #fff;
                } 
            }
            &.white-action-btn {
                border: 1px solid #fff;
                span {
                    color: #fff;
                }
            }
            &.viewmore-hm14 {
                border: 2px solid #54595f;
                padding: 12px 40px 16px 40px;
                span {
                    font-size: 11px;
                    font-weight: bold;
                }
            }
            &.error-btn {
                padding: 22px 40px 21px 40px;
                span {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            &.soon-btn {
                padding: 22px 40px 21px 40px;
                border: 2px solid #fff;
                @media #{$xs-layout} {
                    padding: 15px 30px 14px 30px;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
        .call-to-action-normal {
            opacity: 1;
            transform: scale(1);
        }
        .call-to-action-hover {
            opacity: 0;
            transform: scale(1.5);
            background-color: #54595f;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            span {
                color: #fff;
            }
            &.hover-tm-color {
                background-color: $theme-color;
            }
            &.viewmore-hm14 {
                background-color: $theme-color;
            }
        }
        &:hover {
            > .call-to-action-common {
                border: 1px solid #54595f;
                &.hover-tm-color {
                    border: 1px solid $theme-color;
                }
                &.deal-btn {
                    border: 2px solid $theme-color;
                }
                &.viewmore-hm14 {
                    border: 2px solid $theme-color;
                }
                &.soon-btn {
                    border: 2px solid $theme-color;
                }
            }
            > .call-to-action-normal {
                opacity: 0;
                transform: scale(0);
            }
            > .call-to-action-hover {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.section-padding-2 {
    .container-fluid {
        padding: 0 170px; 
        @media #{$xx-layout} {
            padding: 0 65px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 45px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    }
}

.section-padding-7 {
    .container-fluid {
        padding: 0 315px; 
        @media #{$xx-layout} {
            padding: 0 65px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 45px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    }
}

.padding-30-row-col {
    .row {
        margin-right: -30px;
        margin-left: -30px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$md-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 30px;
            padding-right: 30px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$md-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
} 

.padding-35-row-col {
    .row {
        margin-right: -35px;
        margin-left: -35px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -30px;
            margin-left: -30px;
        }
        @media #{$md-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 35px;
            padding-right: 35px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 30px;
                padding-right: 30px;
            }    
            @media #{$md-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
} 

.padding-40-row-col {
    .row {
        margin-right: -40px;
        margin-left: -40px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -20px;
            margin-left: -20px;
        }
        @media #{$md-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 40px;
            padding-right: 40px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 20px;
                padding-right: 20px;
            }    
            @media #{$md-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
} 

.section-padding-3 {
    .container-fluid {
        padding: 0 150px; 
        @media #{$xx-layout} {
            padding: 0 65px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 45px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    }
}

.column-pro-custom {
    position: relative;
	display: flex;
    padding-left: 15px;
    padding-right: 15px;
    &.pro-width-22 {
        flex: 0 0 22%;
        max-width: 22%;
        @media #{$md-layout} {
            flex: 0 0 30%;
            max-width: 30%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.pro-width-78 {
        flex: 0 0 78%;
        max-width: 78%;
        @media #{$md-layout} {
            flex: 0 0 70%;
            max-width: 70%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.pro-categories-wrap-all {
    width: 100%;
    margin-right: 15px;
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .pro-categories-wrap {
        padding: 40px 40px 35px 40px;
        border: 1px solid #cfcfcf;
        @media #{$lg-layout} {
            padding: 40px 15px 35px 15px;
        }
        @media #{$md-layout} {
            padding: 30px 15px 25px 15px;
        }
        @media #{$xs-layout} {
            padding: 30px 20px 25px 20px;
        }
        .pro-categorie-title {
            margin: 0 0 40px;
            h3 {
                font-family: $playfairdisplay;
                font-size: 36px;
                font-weight: 300;
                margin: 0;
                line-height: 1;
            }
        }
        .pro-categorie-list {
            ul {
                li {
                    padding-bottom: 17px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                    a {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .pro-categorie-btn {
            &.pro-categorie-border {
                border-top-style: solid;
                border-top-width: 1px;
                border-top-color: rgba(0,0,0,0.1);
                padding-top: 8px;
                margin-top: 33px;
            }
            &.pro-categorie-border2 {
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-bottom-color: rgba(0,0,0,0.1);
                padding-bottom: 8px;
                margin-top: 38px;
            }
            a {
                font-size: 11px;
                display: block;
                line-height: 1;
            }
        }
    }
}

.pro-column-width-100 {
    width: 100%;
    margin-left: 15px;
    @media #{$xl-layout} {
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
}


.product-tab-list-2 {
    display: flex;
    justify-content: center;
    a {
        color: #d5d5d5;
        font-size: 36px;
        font-family: $playfairdisplay;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 50px;
        line-height: 35px;
        &.active , &:hover {
            color : #262626;
        }
        @media #{$lg-layout} {
            margin: 0 20px;
        }
        @media #{$md-layout} {
            margin: 0 15px;
            font-size: 30px;
        }
        @media #{$xs-layout} {
            margin: 0 10px 15px;
            font-size: 20px;
            letter-spacing: 0px;
            line-height: 18px;
        }
        @media #{$sm-layout} {
            margin: 0 10px 0px;
        }
    }
    @media #{$md-layout} {
        &.mb-65 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-65 {
            margin-bottom: 30px;
        }
    }
}

.product-tab-list-3 {
    display: flex;
    justify-content: center;
    a {
        color: #777777;
        font-size: 16px;
        font-weight: 300;
        display: inline-block;
        border: 1px solid transparent;
        padding: 15px 30px 16px;
        margin: 0 2px;
        line-height: 1;
        @media #{$lg-layout} {
            padding: 13px 16px 14px;
        }
        @media #{$md-layout} {
            margin: 0 2px 4px;
        }
        @media #{$xs-layout} {
            margin: 0 2px 4px;
            padding: 8px 8px 9px;
            font-size: 14px;
            &.mt-50 {
                margin-top: 30px;
            }
        }
        &.active , &:hover {
            border: 1px solid #262626;
        }
    }
    @media #{$xs-layout} {
        &.mt-50 {
            margin-top: 15px;
        }
        &.mb-40 {
            margin-bottom: 20px;
        }
        &.mb-50 {
            margin-bottom: 20px;
        }
    }
    &.tab-list-hm17 {
        a {
            border: none;
            background-color: transparent;
            &.active , &:hover {
                background-color: $theme-color;
                color: #fff;
            }
        }
    }
    &.tab-list-hm18 {
        a {
            border: none;
            background-color: transparent;
            font-size: 16px;
            font-weight: 400;
            font-family: $robotocondensed;
            color: #777777;
            &.active , &:hover {
                background-color: #bdbd74;
                color: #fff;
            }
        }
    }
}
.product-tab-list-4 {
    display: flex;
    justify-content: center;
    a {
        color: #373737;
        font-size: 12px;
        display: inline-block;
        margin: 0 20px;
        line-height: 1;
        @media #{$xs-layout} {
            margin: 0 10px 10px;
        }
        @media #{$sm-layout} {
            margin: 0 7px 10px;
        }
        &.active , &:hover {
            text-decoration: underline;
        }
    }
    @media #{$xs-layout} {
        &.mt-60 {
            margin-top: 23px;
        }
        &.mb-35 {
            margin-bottom: 10px;
        }
    }
}

.section-padding-4 {
    .container-fluid {
        padding: 0 250px; 
        @media #{$xx-layout} {
            padding: 0 100px;
        }
        @media #{$xl-layout} {
            padding: 0 100px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 45px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    }
}

.product-wrap-2 {
    @media #{$xs-layout} {
        padding: 40px 0 20px;
    }
    .product-content-2 {
        h2 {
            font-size: 80px;
            letter-spacing: 2px;
            margin: 0 0 30px;
            line-height: 114px;
            @media #{$xx-layout} {
                font-size: 65px;
                line-height: 80px;
            }
            @media #{$xl-layout} {
                font-size: 50px;
                line-height: 70px;
            }
            @media #{$lg-layout} {
                font-size: 50px;
                line-height: 70px;
            }
            @media #{$md-layout} {
                font-size: 36px;
                line-height: 50px;
            }
            @media #{$xs-layout} {
                font-size: 28px;
                line-height: 40px;
                letter-spacing: 1px;
                margin: 0 0 10px;
            }
        }
    }
}

.product-ten-img {
    img {
        width: 100%;
    }
}

.bg-flex {
    display: flex;
    align-items: center;
    .product-wrap-3 {
        padding-left: 98px;
        @media #{$lg-layout} {
            padding-left: 30px;
        }
        @media #{$md-layout} {
            padding-left: 45px;
        }
        @media #{$xs-layout} {
            padding-left: 15px;
        }
        .product-content-3 {
            position: relative;
            z-index: 9;
            h2 {
                font-size: 80px;
                letter-spacing: 2px;
                line-height: 1.4074;
                margin: 0 0 30px;
                font-family: $playfairdisplay;
                @media #{$xx-layout} {
                    font-size: 50px;
                }
                @media #{$xl-layout} {
                    font-size: 50px;
                }
                @media #{$lg-layout} {
                    font-size: 50px;
                }
                @media #{$md-layout} {
                    font-size: 36px;
                }
                @media #{$xs-layout} {
                    font-size: 30px;
                    margin: 0 0 15px;
                }
            }
        }
    }
}

.bg-flex-2 {
    display: flex;
    align-items: flex-end;
    @media #{$xs-layout} {
        align-items: center;
    }
    @media #{$md-layout} {
        align-items: center;
    }
    .product-wrap-4 {
        padding-left: 243px;
        padding-bottom: 160px;
        @media #{$xl-layout} {
            padding-left: 100px;
        }
        @media #{$lg-layout} {
            padding-left: 30px;
        }
        @media #{$md-layout} {
            padding-left: 45px;
            padding-bottom: 0px;
        }
        @media #{$xs-layout} {
            padding-left: 15px;
            padding-bottom: 0px;
        }
        .product-content-4 {
            h2 {
                font-size: 64px;
                letter-spacing: 3px;
                line-height: 1.4074;
                margin: 0 0 30px;
                font-family: $playfairdisplay;
                color: #fff;
                @media #{$md-layout} {
                    font-size: 36px;
                }
                @media #{$xs-layout} {
                    font-size: 30px;
                    margin: 0 0 15px;
                }
            }
        }
    }
}

.product-wrap-5 {
    padding: 195px 0 320px 250px;
    @media #{$xx-layout} {
        padding: 95px 0 220px 250px;
    }
    @media #{$xl-layout} {
        padding: 95px 0 220px 100px;
    }
    @media #{$lg-layout} {
        padding: 95px 0 220px 30px;
    }
    @media #{$md-layout} {
        padding: 45px 0 170px 45px;
    }
    @media #{$xs-layout} {
        padding: 0px 0 30px 15px;
    }
    @media #{$sm-layout} {
        padding: 0px 0 70px 15px;
    }
    .product-content-5 {
        h2 {
            font-size: 64px;
            line-height: 1.4074;
            margin: 0 0 30px;
            @media #{$xx-layout} {
                font-size: 50px;
            }
            @media #{$xl-layout} {
                font-size: 50px;
            }
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 36px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 0 0 15px;
            }
        }
    }
}
.bg-flex-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    .product-wrap-6 {
        text-align: center;
        .product-content-6 {
            h2 {
                font-size: 120px;
                line-height: 1.4074;
                margin: 0 0 40px;
                font-family: $playfairdisplay;
                color: #fff;
                @media #{$xx-layout} {
                    font-size: 100px;
                }
                @media #{$xl-layout} {
                    font-size: 80px;
                }
                @media #{$lg-layout} {
                    font-size: 80px;
                }
                @media #{$md-layout} {
                    font-size: 50px;
                    margin: 0 0 20px;
                }
                @media #{$xs-layout} {
                    font-size: 30px;
                    margin: 0 0 20px;
                }
            }
        }
    }
}


.section-padding-5 {
    padding: 0 70px; 
    @media #{$xx-layout} {
        padding: 0 65px;
    }
    @media #{$xl-layout} {
        padding: 0 15px;
    }
    @media #{$lg-layout} {
        padding: 0 30px;
    }
    @media #{$md-layout} {
        padding: 0 45px;
    }
    @media #{$xs-layout} {
        padding: 0 15px;
    }
    @media #{$sm-layout} {
        padding: 0 30px;
    }
}

.section-padding-6 {
    .container-fluid {
        padding: 0 210px; 
        @media #{$xx-layout} {
            padding: 0 65px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 45px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
    }
}

.single-popular-collection {
    overflow: hidden;
    a {
        img {
            transform: scale(1);
            transition: opacity 0.35s, transform 0.35s;
        }
    }
    &:hover a img {
        transform: scale(1.2);
    }
}

.slick-initialized {
    .product-wrap-7.slick-slide {
        display: flex;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
    }
}

.product-wrap-7 {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background: #FFFFFF;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
	padding: 45px 35px;
    position: relative;
    margin: 20px 15px;
    @media #{$lg-layout} {
        padding: 25px 15px;
    }
    @media #{$xs-layout} {
        padding: 25px 20px;
    }
    .product-img-2 {
        flex: 0 0 200px;
        margin-right: 30px;
        position: relative;
        overflow: hidden;
        @media #{$lg-layout} {
            flex: 0 0 170px;
            margin-right: 15px;
        }
        @media #{$xs-layout} {
            margin-right: 0px;
            margin-bottom: 10px;
        }
        @media #{$sm-layout} {
            margin-right: 15px;
            margin-bottom: 0px;
        }
        a {
            display: block;
            img {
                width: 100%;
            }
        }
        .product-action-4 {
            display: inline-block;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
            > a {
                background-color: rgba(38, 38, 38, 0.9);
                border-radius: 100%;
                color: #fff;
                display: block;
                font-size: 14px;
                height: 36px;
                left: 30px;
                margin-bottom: 5px;
                opacity: 0;
                position: relative;
                text-align: center;
                visibility: hidden;
                width: 36px;
                font-weight: bold;
                border-style: solid;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    line-height: 31px;
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                span {
                    visibility: hidden;
                    position: absolute;
                    background-color: inherit;
                    text-align: center;
                    padding: 5px 12px;
                    z-index: 3;
                    opacity: 0;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 11px;
                    right: 100%;
                    line-height: 14px;
                    top: 50%;
                    margin-top: -12px;
                    margin-right: 0;
                    font-weight: 400;
                    &::after {
                        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e77878;
                        border-style: solid;
                        border-width: 5px 0 5px 5px;
                        content: "";
                        height: 0;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 100%;
                        width: 0;
                    }
                }
                &:hover {
                    background-color: $theme-color;
                }
                &:hover span {
                    visibility: visible;
                    opacity: 1;
                    margin-right: 10px;
                }
            }
        }
    }
    .product-content-7 {
        h3 {
            font-size: 18px;
            line-height: 30px;
            margin: 0;
            @media #{$lg-layout} {
                font-size: 16px;
                line-height: 25px;
            }
        }
        .product-price-2 {
            margin: 13px 0 14px;
            span {
                font-size: 18px;
            }
        }
        p {
            font-size: 11px;
            line-height: 2;
            color: #777777;
            margin: 0 0 19px;
        }
        .pro-add-to-cart {
            a {
                font-size: 11px;
                display: inline-block;
                border: 2px solid #262626;
                line-height: 1;
                padding: 14px 40px 15px;
                &:hover {
                    background-color: #262626;
                    color: #fff;
                }
            }
        }
    }
    &:hover {
        .product-action-4 a {
            left: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}

.featured-slider-active {
	margin: 40px 0px 0;
    @media #{$xs-layout} {
        margin: 5px 0px 0;
    }
    .slick-list {
        margin: 0px -15px 0;
    }
}

.slick-dot-style-1 {
    .slick-dots {
        display: flex;
        justify-content: center;
        li {
            margin: 0 11px;
            button {
                padding: 0;
                border: none;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                text-indent: 10em;
                overflow: hidden;
                background-color: rgba(0,0,0,0.17);
            }
            &.slick-active {
                button {
                    background-color: #000000;
                }
            }
        }
    }
    &.slick-dot-mrg1 {
        .slick-dots {
            margin-top: 25px;
            @media #{$xs-layout} {
                margin-top: 15px;
            }
        }
    }
}

.product-collection-area {
	position: relative;
    .collection-img {
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        width: 38.8%;
        z-index: 2;
        @media #{$xs-layout} {
            width: 100%;
            position: static;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
}
.col-width-61 {
	flex: 0 0 61.2%;
	max-width: 61.2%;
    @media #{$xs-layout} {
        width: 100%;
        max-width: 100%;
    }
}

.collection-wrap {
    margin: 105px 0px 70px 49px;
    @media #{$xl-layout} {
        margin: 50px 0px 44px 49px;
    }
    @media #{$lg-layout} {
        margin: 50px 0px 44px 30px;
    }
    @media #{$md-layout} {
        margin: 40px 0px 34px 45px;
    }
    @media #{$xs-layout} {
        margin: 50px 0px 44px 18px;
    }
    h2 {
        font-family: $robotocondensed;
        font-size: 46px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 0 75px 30px;
        color: #fff;
        @media #{$xl-layout} {
            margin: 0 0 30px 18px;
            font-size: 38px;
        }
        @media #{$lg-layout} {
            margin: 0 0 30px 10px;
            font-size: 32px;
        }
        @media #{$md-layout} {
            margin: 0 0 20px 10px;
            font-size: 26px;
        }
        @media #{$xs-layout} {
            margin: 0 0 20px 10px;
            font-size: 20px;
            letter-spacing: 1px;
        }
    }
    .collection-slider-active {
       .slick-list {
            padding-right: 23.5%;
            .product-wrap-hm18 {
                margin: 0px 32px 0;
                @media #{$xl-layout} {
                    margin: 0px 20px 0;
                }
                @media #{$lg-layout} {
                    margin: 0px 10px 0;
                }
                @media #{$md-layout} {
                    margin: 0px 10px 0;
                }
                @media #{$xs-layout} {
                    margin: 0px 10px 0;
                }
            }
            .product-wrap-hm18 {
                opacity: .6;
                &.slick-active {
                    opacity: 1;
                }
            }
        } 
    }
}

.slick-dot-style-2 {
    .slick-dots {
        margin-top: 55px;
        margin-left: 20px;
        @media #{$xl-layout} {
            margin-top: 30px;
            margin-left: 6px;
        }
        @media #{$lg-layout} {
            margin-top: 30px;
        }
        @media #{$md-layout} {
            margin-top: 30px;
            margin-left: 10px;
        }
        @media #{$xs-layout} {
            margin-top: 30px;
            margin-left: 11px;
        }
        li {
            margin: 0 14px;
            display: inline-block;
            @media #{$lg-layout} {
                margin: 0 28px 0 10px;
            }
            @media #{$md-layout} {
                margin: 0 20px 0 0px;
            }
            @media #{$xs-layout} {
                margin: 0 10px 0 0px;
            }
            button {
                padding: 0;
                border: none;
                width: 160px;
                height: 8px;
                text-indent: 12em;
                overflow: hidden;
                background-color: rgba(255,255,255,0.37);
                @media #{$md-layout} {
                    width: 65px;
                }
                @media #{$xs-layout} {
                    width: 48px;
                }
            }
            &.slick-active {
                button {
                    background-color: rgba(255,255,255,1);
                }
            }
        }
    }   
} 

@media #{$xl-layout} {
    .bg-product-img {
        background-position: 0%;
    }
}
@media #{$lg-layout} {
    .bg-product-img {
        background-position: 3%;
    }
}

@media #{$md-layout} {
    .bg-product-img {
        background-position: 16%;
    }
}
@media #{$xs-layout} {
    .bg-product-img {
        background-position: 0%;
    }
    .bow-area {
        &.pt-130 {
            padding-top: 60px;
        }
    }
    .deal-area-2 {
        padding: 50px 15px;
    }
    .height-100vh.height-100vh-xs {
        height: auto;
        padding: 80px 0;
    }
}



