/*-----------------------------------------------------------------------------------

    Template Name: Payna - Minimal eCommerce HTML Template
    Version: 1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. category style
    5. Section title style
    6. Product style
    7. Animation style
    8. Instagram style
    9. Footer style
    10. Others style
    11. Subscribe style
    12. Brand logo style
    13. Banner style
    14. Feature style
    15. Blog style
    16. About us style
    17. Testimonial style
    18. Breadcrumb style
    19. Shop style
    20. Sidebar style
    21. Product details style
    22. Login register style
    23. Compare style
    24. Cart style
    25. Order tracking style
    26. Checkout style
    27. Contact style
    28. Faq style
    29. My account style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common'; 
@import 'header'; 
@import 'slider'; 
@import 'category'; 
@import 'section-title'; 
@import 'product'; 
@import 'animation'; 
@import 'instagram'; 
@import 'footer'; 
@import 'others'; 
@import 'subscribe'; 
@import 'brand-logo'; 
@import 'banner'; 
@import 'feature'; 
@import 'blog'; 
@import 'about-us'; 
@import 'testimonial'; 
@import 'breadcrumb'; 
@import 'shop'; 
@import 'sidebar'; 
@import 'product-details'; 
@import 'login-register'; 
@import 'compare'; 
@import 'cart'; 
@import 'order-tracking'; 
@import 'checkout'; 
@import 'contact'; 
@import 'faq'; 
@import 'my-account'; 













