
/*-------- 16. About us style ---------*/


.about-us-content {
    img {
        width: 133px;
        margin: 0 auto;
    }
    h3 {
        font-size: 34px;
        letter-spacing: 4px;
        margin: 44px 0 14px;
        font-family: $playfairdisplay;
        @media #{$xs-layout} {
            font-size: 25px;
            margin: 20px 0 14px;
        }
    }
    p {
        line-height: 1.9em;
        font-size: 16px;
        font-weight: 300;
        color: #777;
        width: 65%;
        margin: 0 auto 40px;
        @media #{$lg-layout} {
            width: 82%;
        }
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 0 auto 20px;
        }
    }
}

.about-us-img2 {
    margin-right: 100px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    img {
        width: 100%;
    }
}

.about-us-content-2 {
    margin-left: -30px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 15px;
    }
    @media #{$sm-layout} {
        margin-top: 0px;
    }
    h2 {
        line-height: 1.4074;
        font-size: 64px;
        margin: 0;
        font-family: $playfairdisplay;
        @media #{$xl-layout} {
            line-height: 1.2074;
            font-size: 50px;
        }
        @media #{$lg-layout} {
            line-height: 1.2074;
            font-size: 50px;
        }
        @media #{$md-layout} {
            line-height: 1.2074;
            font-size: 40px;
        }
        @media #{$xs-layout} {
            line-height: 1.2074;
            font-size: 32px;
        }
    }
    p {
        line-height: 1.8em;
        font-size: 16px;
        font-weight: 300;
        color: #777777;
        margin: 18px 0 19px;
        width: 85%;
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
    .about-us-signature {
        img {
            width: 158px;
            @media #{$md-layout} {
                width: 90px;
            }
            @media #{$xs-layout} {
                width: 90px;
            }
        }
    }
}

.store-content {
    padding: 80px 15px 79px;
    @media #{$md-layout} {
        margin-top: 40px;
    }
    @media #{$xs-layout} {
        padding: 41px 15px 50px;
        margin-top: 40px;
    }
    h3 {
        font-family: $dancingscript;
        font-size: 36px;
        margin: 0;
        line-height: 52px;
    }
    p {
        line-height: 32px;
        font-weight: 300;
        margin: 28px auto 30px;
        width: 77%;
        @media #{$lg-layout} {
            width: 85%;
        }
        @media #{$md-layout} {
            width: 80%;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 12px auto 30px;
        }
    }
}

.store-img {
    margin-right: 20px;
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    img {
        width: 100%;
    }
}

.store-content-2 {
    margin: 75px 0 0 21px;
    @media #{$lg-layout} {
        margin: 0px 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0px 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 30px 0 0 0px;
    }
    h2 {
        font-family: $dancingscript;
        font-size: 66px;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 50px;
        }
        @media #{$md-layout} {
            font-size: 40px;
        }
        @media #{$xs-layout} {
            font-size: 37px;
        }
    }
    p {
        font-family: $dancingscript;
        font-size: 24px;
        margin: 28px 0 50px;
        line-height: 1.4074;
        width: 94%;
        @media #{$lg-layout} {
            margin: 28px 0 30px;
        }
        @media #{$md-layout} {
            margin: 12px 0 20px;
            width: 100%;
        }
        @media #{$xs-layout} {
            margin: 15px 0 30px;
            width: 100%;
        }
    }
}

.choose-us-area {
	position: relative;
    .choose-us-img {
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: 2;
        @media #{$md-layout} {
            position: static;
            width: 100%;
        }
        @media #{$xs-layout} {
            position: static;
            width: 100%;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
}

.choose-us-content {
    padding: 115px 0px 90px 80px;
    @media #{$xl-layout} {
        padding: 70px 0px 62px 60px;
    }
    @media #{$lg-layout} {
        padding: 30px 30px 30px 30px;
    }
    @media #{$md-layout} {
        padding: 30px 15px 30px 15px;
    }
    @media #{$xs-layout} {
        padding: 30px 15px 30px 15px;
    }
    h2 {
        font-size: 46px;
        font-family: $dancingscript;
        margin: 0 0 20px;
        @media #{$xs-layout} {
            font-size: 40px;
        }
    }
    p {
        font-size: 24px;
        font-family: $dancingscript;
        margin: 25px 0 58px;
        line-height: 1.4074;
        width: 60%;
        @media #{$xl-layout} {
            width: 93%;
        }
        @media #{$lg-layout} {
            width: 100%;
            margin: 15px 0 30px;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 20px 0 30px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 15px 0 30px;
        }
    }
    .choose-us-list {
        .single-choose-list {
            display: flex;
            align-items: center;
            .choose-icon {
                margin-right: 30px;
                i {
                    font-size: 24px;
                    color: #fff;
                    background-color: #ce869c;
                    width: 64px;
                    height: 64px;
                    line-height: 64px;
                    border-radius: 100%;
                    display: inline-block;
                    text-align: center;
                }
            }
            .choose-text {
                h4 {
                    font-family: $dancingscript;
                    font-size: 24px;
                    margin: 0;
                }
            }
        }
    }
}

.about-us-content-3 {
    padding-left: 90px;
    @media #{$xs-layout} {
        padding-left: 50px;
    }
    h2 {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 4px;
        margin: 0 0 55px;
        font-family: $playfairdisplay;
        position: relative;
        @media #{$lg-layout} {
            font-size: 30px;
            margin: 0 0 12px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            margin: 0 0 20px;
            letter-spacing: 1px;
        }
        &:before {
            width: 47px;
            height: 2px;
            background-color: #262626;
            position: absolute;
            content: "";
            left: -90px;
            top: 24px;
            @media #{$xs-layout} {
                left: -60px;
                top: 12px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 32px;
    }
}


.about-us-img {
    margin-left: 123px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        margin-top: 40px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 30px;
    }
    img {
        width: 100%;
    }
}

.service-content {
    h3 {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 20px;
        @media #{$xs-layout} {
            margin: 0 0 7px;
        }
    }
    p {
        font-size: 16px;
        line-height: 32px;
        margin: 0 0 20px;
        width: 90%;
        @media #{$xs-layout} {
            width: 100%;
        }
    }
    @media #{$xs-layout} {
        &.mb-85 {
            margin-bottom: 35px;
        }
    }
}

.custom-container-7 {
	width: 1220px;
	margin-right: auto;
	margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    @media #{$xx-layout} {
        width: 1266px;
    }
    @media #{$xl-layout} {
        width: 1200px;
    }
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        width: 708px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
}

.team-wrap {
    .team-img {
        position: relative;
        img {
            width: 100%;
        }
        .team-social {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            bottom: 20px;
            z-index: 99;
            transition: all 0.4s 0.1s;
            transform: translateY(20px);
            opacity: 0;
            visibility: hidden;
            a {
                display: inline-block;
                margin: 0 5px;
                width: 38px;
                height: 38px;
                line-height: 38px;
                border: 1px solid #fff;
                font-size: 16px;
                text-align: center;
                border-radius: 100%;
                color: #fff;
                @media #{$md-layout} {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                }
                &:hover {
                    border: 1px solid $theme-color;
                    background-color: $theme-color;
                }
            }
        }
    }
    .team-content {
        margin: 28px 0 0;
        h3 {
            letter-spacing: 2.67px;
            font-size: 24px;
            font-family: $playfairdisplay;
            margin: 0 0 5px;
        }
        span {
            letter-spacing: 1.78px;
            font-size: 16px;
            font-family: $playfairdisplay;
            color: #777777;
        }
    }
    &:hover .team-img::before {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
        transition: all 250ms ease-out;
        pointer-events: none;
        opacity: 1;
    }
    &:hover .team-social {
        transform: translateY(0px);
        opacity: 1;
        visibility: visible;
    }
}

.custom-container-8 {
	width: 1180px;
	margin-right: auto;
	margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    @media #{$xx-layout} {
        width: 1266px;
    }
    @media #{$xl-layout} {
        width: 1200px;
    }
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        width: 708px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
}

@media #{$xs-layout} {
    .team-area {
        &.pb-60 {
            padding-bottom: 15px;
        }
    }
}








