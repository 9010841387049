/*-------- 28. Faq style ---------*/

.faq-wrap {
    margin: 0 0 0px;
    h2 {
        color: $theme-color;
        font-size: 36px;
        margin: 0 0 67px;
        @media #{$md-layout} {
            margin: 0 0 30px;
        }
        @media #{$xs-layout} {
            margin: 0 0 20px;
            font-size: 23px;
        }
    }
    .single-faq-wrap {
        h3 {
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 32px;
            @media #{$md-layout} {
                margin: 0 0 10px;
            }
            @media #{$xs-layout} {
                margin: 0 0 10px;
                font-size: 18px;
            }
        }
        p {
            line-height: 28px;
            color: #777;
            margin: 0;
            width: 88%;
            @media #{$xs-layout} {
                width: 100%;
            }
        }
        @media #{$xs-layout} {
            &.mb-50 {
                margin-bottom: 30px;
            }
        }
    }
}









