/*-----------------------------------------------------------------------------------

    Template Name: Payna - Minimal eCommerce HTML Template
    Version: 1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. category style
    5. Section title style
    6. Product style
    7. Animation style
    8. Instagram style
    9. Footer style
    10. Others style
    11. Subscribe style
    12. Brand logo style
    13. Banner style
    14. Feature style
    15. Blog style
    16. About us style
    17. Testimonial style
    18. Breadcrumb style
    19. Shop style
    20. Sidebar style
    21. Product details style
    22. Login register style
    23. Compare style
    24. Cart style
    25. Order tracking style
    26. Checkout style
    27. Contact style
    28. Faq style
    29. My account style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Alegreya:400,500,700,800|Dancing+Script:400,700|Caveat:400,700|Roboto+Condensed:300,400,700|Montserrat:300,400,500,600,700,800|Playfair+Display:400,400i,700,900&display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #262626;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #262626;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #262626;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #262626;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.border-top-1 {
  border-top: 1px solid #d5d5d5;
}

.border-top-2 {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.border-top-3 {
  border-top: 1px solid #b9b9b9;
}

.border-bottom-1 {
  border-bottom: 1px solid #bebebe;
}

.border-bottom-2 {
  border-bottom: 1px solid #767676;
}

a:hover {
  color: #e77878;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .mt-100 {
    margin-top: 50px;
  }
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

@media only screen and (max-width: 767px) {
  .pt-85 {
    padding-top: 45px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 45px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 40px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .pb-50 {
    padding-bottom: 20px;
  }
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 20px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 40px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 45px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 86px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

button, input, optgroup, select, textarea {
  font-family: "Montserrat", sans-serif;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-8 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-8 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-8 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-8 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-8 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #e77878;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
  background-color: #e7e9ed;
}

.bg-gray-2 {
  background-color: #f9f9f9;
}

.bg-gray-3 {
  background-color: #f1f1f1;
}

.bg-black {
  background-color: #262626;
}

.bg-black-2 {
  background-color: #1a1a1a;
}

.bg-light-pink {
  background-color: #e8dddd;
}

.bg-light-pink2 {
  background-color: #fff8f8;
}

.blue-green {
  background-color: #d5ecf1;
}

.bg-mediumaquamarine {
  background-color: #dcf4eb;
}

.bg-aqua {
  background-color: #50e3c2;
}

.bg-avocado-green {
  background-color: #bdbd74;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all .35s ease 0s;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.custom-column {
  position: relative;
  display: flex;
}

.custom-column.column-width-56 {
  flex: 0 0 56.129%;
}

@media only screen and (max-width: 767px) {
  .custom-column.column-width-56 {
    flex: 0 0 100%;
  }
}

.custom-column.column-width-26 {
  flex: 0 0 26.452%;
}

@media only screen and (max-width: 767px) {
  .custom-column.column-width-26 {
    flex: 0 0 100%;
  }
}

.custom-column.column-width-17 {
  flex: 0 0 17.415%;
}

@media only screen and (max-width: 767px) {
  .custom-column.column-width-17 {
    flex: 0 0 100%;
  }
}

/*-------- 2. Header style ---------*/
.main-header-wrap, .header-sidebar-wrap-all, .header-sidebar-wrap-all2, .clickable-mainmenu-wrapper-hm1 {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-header-wrap, .header-sidebar-wrap-all, .header-sidebar-wrap-all2, .clickable-mainmenu-wrapper-hm1 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-header-wrap, .header-sidebar-wrap-all, .header-sidebar-wrap-all2, .clickable-mainmenu-wrapper-hm1 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.pt-45 {
    padding-top: 30px;
  }
  .header-area.pb-45 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-area.pt-45 {
    padding-top: 25px;
  }
  .header-area.pb-45 {
    padding-bottom: 25px;
  }
}

.header-padding-1 .container-fluid {
  padding: 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-1 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-1 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .header-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.menu-social-wrap {
  display: flex;
  align-items: center;
}

.menu-social-wrap .header-social a {
  font-size: 18px;
  color: #777;
  margin-right: 24px;
}

.menu-social-wrap .header-social a:last-child {
  margin-right: 0px;
}

.menu-social-wrap .header-social a:hover {
  color: #e77878;
}

.menu-social-wrap .header-social.h-social-white a {
  font-size: 20px;
  color: #D6D6D6;
  margin-right: 27px;
}

.menu-social-wrap .header-social.h-social-white a:hover {
  color: #e77878;
}

.menu-icon {
  line-height: 1;
}

.menu-icon a {
  font-size: 24px;
  color: #262626;
}

.menu-icon.white-menu-icon a {
  font-size: 20px;
  color: #fff;
}

.menu-icon.white-menu-icon a:hover {
  color: #e77878;
}

.menu-icon.menu-icon-hm1 {
  margin-top: 47px;
}

.logo.logo-width a img {
  width: 150px;
}

.logo.logo-width-2 a img {
  width: 144px;
}

.logo.logo-width-3 a img {
  width: 156px;
}

.lang-cart-search-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lang-cart-search-wrap .language ul li {
  display: inline-block;
  margin-right: 14px;
  position: relative;
}

.lang-cart-search-wrap .language ul li:last-child {
  margin-right: 0px;
}

.lang-cart-search-wrap .language ul li::before {
  content: "/";
  position: absolute;
  right: -10px;
  font-size: 11px;
  top: 2px;
}

.lang-cart-search-wrap .language ul li:last-child::before {
  display: none;
}

.lang-cart-search-wrap .language ul li a {
  color: #777777;
  font-size: 11px;
  letter-spacing: 2px;
}

.lang-cart-search-wrap .language ul li a.active, .lang-cart-search-wrap .language ul li a:hover {
  font-weight: 600;
  color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lang-cart-search-wrap .language.mr-55 {
    margin-right: 20px;
  }
}

.lang-cart-search-wrap .language.white-language ul li::before {
  color: #fff;
}

.lang-cart-search-wrap .language.white-language ul li a {
  color: #fff;
}

.lang-cart-search-wrap .language.white-language ul li a:hover {
  color: #e77878;
}

.lang-cart-search-wrap .same-style {
  margin-top: 5px;
}

.lang-cart-search-wrap .same-style a {
  font-size: 20px;
}

.lang-cart-search-wrap .same-style.white-search a {
  color: #fff;
}

.lang-cart-search-wrap .same-style.white-search a:hover {
  color: #e77878;
}

.lang-cart-search-wrap .same-style.white-login a {
  color: #fff;
}

.lang-cart-search-wrap .same-style.white-login a:hover {
  color: #e77878;
}

.cart-wrap a {
  position: relative;
  font-size: 20px;
}

.cart-wrap a i {
  transform: rotateY(-180deg);
  display: inline-block;
}

.cart-wrap a span.count-style {
  position: absolute;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  display: block;
  top: -19px;
  right: -11px;
  width: 20px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background-color: #e77878;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cart-wrap a span.count-style {
    right: -8px;
  }
}

.cart-wrap.white-cart a {
  color: #fff;
}

.cart-wrap.white-cart a:hover {
  color: #e77878;
}

.cart-wrap.white-cart a span.count-style {
  background-color: #ffffff;
  color: #262626;
}

.cart-wrap.white-cart-2 a {
  color: #fff;
}

.cart-wrap.white-cart-2 a:hover {
  color: #e77878;
}

.search-content-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232324;
}

.search-content-wrap.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-content-wrap.search-visible .search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-content-wrap > .search-close {
  font-size: 34px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s ease 0s;
}

.search-content-wrap > .search-close i:hover {
  color: #e77878;
}

.search-content-wrap .search-content {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-content-wrap .search-content p {
  font-size: 18px;
  margin: 0 0 15px;
  color: #232324;
  font-weight: 300;
}

.search-content-wrap .search-content .search-form {
  position: relative;
}

.search-content-wrap .search-content .search-form input {
  width: 800px;
  background-color: transparent;
  border: 0;
  text-align: center;
  font-size: 30px;
  padding: 25px 40px 21px 36px;
  color: #232324;
  transition: all .3s ease-out;
  font-weight: 300;
  max-width: 100%;
  height: auto;
  border-bottom: 2px solid #232324;
}

.search-content-wrap .search-content .search-form .button-search {
  position: absolute;
  top: 28px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  font-size: 30px;
  height: 30px;
}

.search-content-wrap .search-content .search-form .button-search:hover {
  color: #e77878;
}

.main-wrapper .body-overlay, .main-wrapper .body-overlay-2, .main-wrapper-2 .body-overlay, .main-wrapper-2 .body-overlay-2 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay, .main-wrapper.overlay-active-2 .body-overlay-2, .main-wrapper-2.overlay-active .body-overlay, .main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 103px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 70px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 50px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 55px;
  right: 53px;
  font-size: 25px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #777777;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 25px;
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    right: 23px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  color: #e77878;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  margin: 0 0 45px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  max-height: -webkit-calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 10px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 75px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 5px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  font-family: Verdana,sans-serif;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 31px 0 41px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 14px;
  color: #777777;
  margin: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 18px;
  color: #262626;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 20px 20px 21px;
  background-color: #262626;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #e77878;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.header-small-mobile {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-mobile {
    display: block;
  }
}

.header-small-mobile .mobile-logo.logo-width a img {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .header-small-mobile .mobile-logo.logo-width a img {
    width: 120px;
  }
}

.header-small-mobile .mobile-header-right-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-small-mobile .mobile-header-right-wrap .cart-wrap {
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .mobile-off-canvas {
  margin-left: 30px;
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .mobile-off-canvas a {
  font-size: 20px;
}

.header-small-mobile .mobile-header-right-wrap .header-login {
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .header-login a {
  font-size: 20px;
  margin-right: 30px;
}

.header-small-mobile.header-small-mobile-ptb {
  padding: 30px 0;
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 260px;
    right: -260px;
  }
}

.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.mobile-off-canvas-active .mobile-aside-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: #343538;
}

.mobile-off-canvas-active .mobile-aside-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: #e77878;
  transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #333;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: #e77878;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #1f2226;
  transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #1f2226;
  transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 15px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #e77878;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 15px;
  display: block;
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 14px;
  position: relative;
  top: 5px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #e77878;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #e77878;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info {
  border-bottom: 1px solid #ddd;
  padding: 0 0 34px;
  margin: 0 0 34px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li i {
  font-size: 17px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
  color: #bd081c;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
  color: #e4405f;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
  color: #dd4b39;
}

.logo-header-about-wrap {
  display: flex;
  align-items: center;
}

.logo-header-about-wrap .header-about-icon {
  line-height: 1;
}

.logo-header-about-wrap .header-about-icon a {
  font-size: 20px;
}

.logo-header-about-wrap.white-header .header-about-icon a {
  color: #fff;
}

.logo-header-about-wrap.white-header .header-about-icon a:hover {
  color: #e77878;
}

.main-menu > nav > ul > li {
  display: inline-block;
  margin: 0 18px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li {
    margin: 0 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li {
    margin: 0 5px;
  }
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > a {
    font-size: 12px;
  }
}

.main-menu > nav > ul > li > a::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 2.5em;
  bottom: 0;
  width: 20%;
  height: 2px;
  background-color: #262626;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu > nav > ul > li > a.active::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu > nav > ul > li:hover > a::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 999;
}

.main-menu > nav > ul > li > ul li a.menu-title {
  color: #181818;
  font-size: 18px;
  letter-spacing: -1px;
  position: relative;
  padding-bottom: 15px;
}

.main-menu > nav > ul > li > ul li a.menu-title::before {
  position: absolute;
  content: "";
  width: 36px;
  height: 2px;
  bottom: -2px;
  background-color: #e77878;
}

.main-menu > nav > ul > li > ul li ul {
  width: 100%;
  display: block;
  margin: 44px 0 0;
}

.main-menu > nav > ul > li > ul li ul li {
  display: block;
  padding-bottom: 21px;
}

.main-menu > nav > ul > li > ul li ul li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul li ul li a {
  display: block;
  color: #181818;
}

.main-menu > nav > ul > li > ul li ul li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 {
  width: 770px;
  padding: 55px 0 52px;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 > li {
  width: 33.333%;
  float: left;
  display: inline-block;
  padding: 0 0 0 45px;
}

.main-menu > nav > ul > li > ul.menu-negative-mrg1 {
  left: -378px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -190px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg3 {
  left: 0px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  left: -570px;
  width: 1170px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li {
  float: left;
  display: inline-block;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width20 {
  width: 20%;
  padding: 55px 0 45px 45px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 {
  width: 37.5%;
  float: right;
  padding-left: 80px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap {
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap a {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap a img {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap .banner-menu-content {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 65px;
  z-index: 9;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap .banner-menu-content span {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  transition: all 0.35s;
  margin: 0 0 14px;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap .banner-menu-content h2 {
  line-height: 1em;
  font-size: 74px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap .banner-menu-content h2 {
    font-size: 45px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 > li.mega-menu-sub-width37 .banner-menu-content-wrap:hover span {
  letter-spacing: 1px;
}

.main-menu > nav > ul > li > ul.menu-negative-mrg2 {
  left: -570px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -435px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -400px;
    width: 1130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -332px;
    width: 950px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg4 {
  left: -69px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    width: 910px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 270px;
  padding: 38px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.sub-menu-width {
    width: 240px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width {
    width: 220px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 25px 19px;
  position: relative;
}

.main-menu > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #181818;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: 600;
  position: relative;
  top: 6px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
  padding: 38px 0px;
  width: 240px;
  transition: all 250ms ease-out;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  margin-left: -20px;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
    width: 180px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li:hover > ul.lavel-menu {
  margin-left: 0px;
  visibility: visible;
  opacity: 1;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu.main-menu-2 > nav > ul > li {
  margin: 0 26px 0 0;
}

.main-menu.main-menu-2 > nav > ul > li > a {
  font-size: 12px;
  font-weight: 500;
}

.main-menu.main-menu-2 > nav > ul > li > a::after {
  display: none;
}

.main-menu.main-menu-2 > nav > ul > li:hover > a {
  color: #e77878;
}

.main-menu.main-menu-hm18 > nav > ul > li {
  margin: 0 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.main-menu-hm18 > nav > ul > li {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-hm18 > nav > ul > li {
    margin: 0 7px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-hm18 > nav > ul > li {
    margin: 0 5px;
  }
}

.main-menu.main-menu-hm18 > nav > ul > li > a {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-hm18 > nav > ul > li > a {
    letter-spacing: 0px;
  }
}

.main-menu.main-menu-hm18 > nav > ul > li > a::after {
  background-color: #bdbd74;
}

.main-menu.main-menu-hm18 > nav > ul > li > a:hover {
  color: #262626;
}

.main-menu.main-menu-hm18 > nav > ul > li > a i {
  font-weight: 600;
  margin-left: 11px;
  display: inline-block;
  font-size: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-hm18 > nav > ul > li > a i {
    margin-left: 2px;
  }
}

.main-menu.main-menu-hm18 > nav > ul > li ul li a.menu-title::before {
  background-color: #bdbd74;
}

.main-menu.main-menu-hm18 > nav > ul > li ul li ul a:hover {
  color: #bdbd74;
  text-decoration: underline;
}

.main-menu.menu-lh-1 > nav > ul > li > a {
  line-height: 120px;
}

.main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 68px;
}

.main-menu.menu-lh-3 > nav > ul > li > a {
  line-height: 185px;
}

.main-menu.menu-white > nav > ul > li > a {
  color: #fff;
}

.main-menu.menu-white > nav > ul > li > a::after {
  background-color: #fff;
}

.main-menu.menu-border-none > nav ul li a::after {
  display: none;
}

.quickinfo-wrapper-active, .clickable-mainmenu-wrapper4 {
  text-align: left;
  transition: all 520ms ease;
  position: fixed;
  padding: 60px 50px 50px;
  top: 0;
  margin: 0;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  height: 100%;
  overflow: auto;
  z-index: 99;
  width: 400px;
}

.quickinfo-wrapper-active.quickinfo-toggle-left, .quickinfo-wrapper-active.menu4-toggle-left, .clickable-mainmenu-wrapper4.quickinfo-toggle-left, .clickable-mainmenu-wrapper4.menu4-toggle-left {
  transform: translateX(-410px);
  left: 0;
}

.quickinfo-wrapper-active.quickinfo-toggle-right, .clickable-mainmenu-wrapper4.quickinfo-toggle-right {
  transform: translateX(400px);
  right: 0;
}

.quickinfo-wrapper-active a.quickinfo-close, .clickable-mainmenu-wrapper4 a.quickinfo-close {
  color: #777;
  font-size: 26px;
}

.quickinfo-wrapper-active a.quickinfo-close:hover, .clickable-mainmenu-wrapper4 a.quickinfo-close:hover {
  color: #e77878;
}

.quickinfo-wrapper-active a.menu4-close, .clickable-mainmenu-wrapper4 a.menu4-close {
  color: #535353;
  font-size: 26px;
}

.quickinfo-wrapper-active a.menu4-close:hover, .clickable-mainmenu-wrapper4 a.menu4-close:hover {
  color: #e77878;
}

.quickinfo-wrapper-active.quickinfo-visible, .clickable-mainmenu-wrapper4.quickinfo-visible {
  transform: translateX(0);
}

.quickinfo-wrapper-active .quickinfo-wrap, .clickable-mainmenu-wrapper4 .quickinfo-wrap {
  padding: 40px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-menu nav ul li, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-menu nav ul li {
  display: block;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-menu nav ul li a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-menu nav ul li a {
  font-size: 16px;
  padding: 19px 0;
  display: block;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner {
  position: relative;
  margin: 11px 0 30px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner.default-overlay:before, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner.default-overlay:before {
  background-color: #000;
  opacity: 0;
  pointer-events: none;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner a {
  display: block;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner a img, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner a img {
  width: 100%;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner .quickinfo-banner-content, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner .quickinfo-banner-content {
  position: absolute;
  left: 25px;
  bottom: 7px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner .quickinfo-banner-content h3, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner .quickinfo-banner-content h3 {
  font-size: 57px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  line-height: 1;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner:hover:before, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-banner:hover:before {
  opacity: .4;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-address, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-address {
  margin: 0 0 18px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-address ul li, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-address ul li {
  color: #5d5d5d;
  font-size: 12px;
  line-height: 22px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-address ul li a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-address ul li a {
  color: #5d5d5d;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-address ul li a:hover, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-address ul li a:hover {
  color: #e77878;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-map-link a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-map-link a {
  color: #5d5d5d;
  font-size: 12px;
  display: inline-block;
  text-decoration: underline;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-map-link a:hover, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-map-link a:hover {
  color: #e77878;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-social {
  margin: 10px 0 36px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-social ul li {
  display: inline-block;
  margin: 0 21px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li:last-child, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-social ul li:last-child {
  margin: 0 0px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-social ul li a {
  font-size: 14px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-payment img, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-payment img {
  width: 180px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-copyright, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-copyright {
  margin: 13px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-copyright p, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-copyright p {
  color: #5d5d5d;
  font-size: 11px;
  margin: 0;
  line-height: 1;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-copyright p a, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-copyright p a {
  color: #5d5d5d;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-copyright p a:hover, .clickable-mainmenu-wrapper4 .quickinfo-wrap .quickinfo-copyright p a:hover {
  color: #e77878;
}

.header-info ul li {
  display: inline-block;
  margin-right: 14px;
  font-size: 11px;
  color: #262626;
}

.header-info ul li:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-info ul li {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-info ul li {
    margin-right: 8px;
  }
}

.header-info ul li i {
  font-size: 16px;
  position: relative;
  top: 3px;
  margin-right: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-info ul li i {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-info ul li i {
    margin-right: 5px;
  }
}

.header-info ul li.info-icon-roted i {
  transform: rotateY(180deg);
  display: inline-block;
}

.main-wrapper-3 .body-overlay-3 {
  background: rgba(51, 51, 51, 0.9) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  z-index: 99999;
}

.main-wrapper-3.overlay-active-3 .body-overlay-3 {
  opacity: 1;
  visibility: visible;
}

.login-wrap-active {
  position: absolute;
  top: 22%;
  width: 1070px;
  right: 0;
  left: 0;
  margin: 0 auto;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-out;
  background-color: #fff;
  z-index: 99999;
  padding: 55px 45px;
  transform: scale(0.9);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-wrap-active {
    width: 930px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-wrap-active {
    width: 710px;
  }
}

@media only screen and (max-width: 767px) {
  .login-wrap-active {
    width: 300px;
    padding: 40px 15px;
    top: 15%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .login-wrap-active {
    width: 520px;
  }
}

.login-wrap-active.inside {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.login-wrap-active a.login-close {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #292929;
  font-size: 18px;
  color: #fff;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .login-wrap-active a.login-close {
    right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .login-wrap-active a.login-close {
    right: -20px;
  }
}

.login-wrap-active a.login-close i {
  line-height: 54px;
}

.login-wrap-active a.login-close:hover {
  color: #e77878;
}

.login-wrap-active .header-login-content h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 20px;
}

.login-wrap-active .header-login-content .single-login-input {
  margin: 0 0 21px;
}

.login-wrap-active .header-login-content .single-login-input label {
  display: block;
  margin: 0 0 8px;
}

.login-wrap-active .header-login-content .single-login-input input {
  height: 50px;
  border: 1px solid #CDCDCD;
  padding: 2px 20px;
  background-color: transparent;
}

.login-wrap-active .header-login-content .single-login-input input:focus {
  border: 1px solid #262626;
}

.login-wrap-active .header-login-content .login-remember {
  margin: 21px 0 6px;
}

.login-wrap-active .header-login-content .login-remember label {
  margin: 0;
}

.login-wrap-active .header-login-content .login-remember label input {
  width: auto;
  height: auto;
}

.login-wrap-active .header-login-content .login-submit input {
  width: auto;
  height: auto;
  padding: 11px 54px 13px;
  background-color: #262626;
  color: #fff;
}

.login-wrap-active .header-login-content .login-submit input:hover {
  background-color: #e77878;
}

.login-wrap-active .header-login-content .login-links {
  margin: 9px 0 0;
}

.login-wrap-active .header-login-content .login-links a {
  color: #777;
  font-size: 13px;
}

.login-wrap-active .header-login-content .login-links a:hover {
  text-decoration: underline;
  color: #e77878;
}

.header-categorie-menu > nav > ul > li {
  display: inline-block;
  margin-right: 25px;
  position: relative;
}

.header-categorie-menu > nav > ul > li:last-child {
  margin-right: 0px;
}

.header-categorie-menu > nav > ul > li > a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 70px;
}

.header-categorie-menu > nav > ul > li > a i {
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
}

.header-categorie-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 999;
  width: 260px;
  padding: 38px 0px;
}

.header-categorie-menu > nav > ul > li > ul li {
  display: block;
  padding: 0 25px 19px;
  position: relative;
}

.header-categorie-menu > nav > ul > li > ul li:last-child {
  padding: 0 25px 0px;
}

.header-categorie-menu > nav > ul > li > ul li a {
  display: block;
  color: #777;
}

.header-categorie-menu > nav > ul > li > ul li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.header-categorie-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.search-content-2 {
  position: relative;
  width: 350px;
  float: right;
}

.search-content-2 input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  letter-spacing: .7px;
  font-weight: 300;
  color: #fff;
  padding: 2px 50px 2px 0;
}

.search-content-2 input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.search-content-2 input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.search-content-2 button {
  position: absolute;
  bottom: 1px;
  right: 0;
  background-color: #262626;
  border: none;
  padding: 11px 0 7px 40px;
  color: #fff;
  font-size: 20px;
}

.search-content-2 button:hover {
  background-color: #e77878;
}

.header-sidebar-wrap-all {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  width: 94px;
  background-color: #fff;
  padding: 40px 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.header-sidebar-wrap-all .header-sidebar-wrap {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
}

.header-sidebar-wrap-all .header-sidebar-wrap > a {
  margin: 25px 0 85px;
}

.header-sidebar-wrap-all .header-sidebar-wrap > a img {
  width: 22px;
}

.header-sidebar-wrap-all .header-sidebar-wrap .lang-cart-search-wrap {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.header-sidebar-wrap-all .header-sidebar-wrap .lang-cart-search-wrap.menu-icon-position {
  position: absolute;
  top: 45%;
  left: calc(50% - 20px);
}

.header-sidebar-wrap-all .header-sidebar-wrap .lang-cart-search-wrap.menu-icon-position .header-menu-icon a {
  font-size: 32px;
}

.header-sidebar-wrap-all.sidebar-boxshadow-2 {
  box-shadow: 0px 0px 60px 0px rgba(41, 44, 58, 0.06);
}

.header-sidebar-wrap-all2 {
  padding: 90px 60px 50px;
  background-color: #ffffff;
  width: 390px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.header-sidebar-wrap-all2.header-sidebar-shadow {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 60px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 20px 50px;
    width: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 20px 50px;
    width: 260px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap {
  justify-content: flex-start;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg {
  margin: 66px 0 108px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg {
    margin: 40px 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg {
    margin: 40px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg {
    margin: 40px 0 40px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg3 {
  margin: 42px 0 144px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg3 {
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg3 {
    margin: 42px 0 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap.sidebar-style-mrg3 {
    margin: 42px 0 60px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .lang-cart-search-wrap .same-style a {
  font-size: 22px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li {
  padding: 0 0 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 20px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li:last-child {
  padding: 0 0 0px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li > a {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 16px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li > a i {
  font-size: 17px;
  color: #262626;
  margin-left: 17px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul {
  padding: 42px 0px 6px 23px;
  display: none;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul li {
  padding-bottom: 20px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul li:last-child {
  padding-bottom: 0px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul li a {
  color: #777;
  font-size: 14px;
  display: block;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul li a i {
  margin-left: 5px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style2 > nav > ul > li ul li a:hover {
  text-decoration: underline;
  color: #e77878;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li {
  padding: 0 0 37px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li {
    padding: 0 0 20px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li:last-child {
  padding: 0 0 0px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li > a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li > a i {
  font-size: 17px;
  color: #262626;
  margin-left: 16px;
  position: relative;
  top: 2px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li > a:hover {
  text-decoration: underline;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul {
  padding: 42px 0px 6px 23px;
  display: none;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul li {
  padding-bottom: 20px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul li:last-child {
  padding-bottom: 0px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul li a {
  color: #777;
  font-size: 14px;
  display: block;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul li a i {
  font-size: 17px;
  color: #262626;
  margin-left: 16px;
  position: relative;
  top: 2px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .clickable-mainmenu-style3 > nav > ul > li ul li a:hover {
  text-decoration: underline;
  color: #e77878;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg2 {
  margin: 165px 0 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg2 {
    margin: 60px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg2 {
    margin: 80px 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg2 {
    margin: 80px 0 10px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg4 {
  margin: 125px 0 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg4 {
    margin: 70px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg4 {
    margin: 70px 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social.sidebar-style-mrg4 {
    margin: 60px 0 10px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 18px;
  color: #777;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  text-align: center;
  margin-right: 6px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a:last-child {
  margin-right: 0px;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a:hover {
  background-color: #e77878;
  color: #fff;
  border: 1px solid #e77878;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a i {
  line-height: 38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a i {
    line-height: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-social a i {
    line-height: 28px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .sidebar-copyright p {
  margin: 0;
  color: #848484;
  font-size: 12px;
}

.clickable-mainmenu-style4 {
  margin: 63px 0 0;
}

.clickable-mainmenu-style4 > nav > ul > li {
  display: block;
  padding-bottom: 47px;
  line-height: 1;
}

.clickable-mainmenu-style4 > nav > ul > li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-style4 > nav > ul > li > a {
  font-size: 16px;
  display: block;
  text-transform: capitalize;
}

.clickable-mainmenu-style4 > nav > ul > li > a i {
  float: right;
  top: 2px;
  position: relative;
}

.clickable-mainmenu-style4 > nav > ul > li ul {
  padding: 38px 0 0px 25px;
}

.clickable-mainmenu-style4 > nav > ul > li ul li {
  padding-bottom: 22px;
}

.clickable-mainmenu-style4 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-style4 > nav > ul > li ul li a {
  color: #535353;
  font-size: 14px;
  display: block;
}

.clickable-mainmenu-style4 > nav > ul > li ul li a:hover {
  color: #e77878;
}

.clickable-mainmenu-style4 > nav > ul > li ul li a i {
  float: right;
  top: -1px;
  position: relative;
}

.clickable-mainmenu-active {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(24, 24, 24, 0.95);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

.clickable-mainmenu-active.mainmenu-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

.clickable-mainmenu-active .clickable-mainmenu-width {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
  position: fixed;
  top: 22%;
  right: 10%;
  font-size: 32px;
  color: #fff;
  z-index: 9;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
  display: flex;
  padding: 0 300px;
  overflow: auto;
  height: 90%;
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 80px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav {
  width: 100%;
  align-items: center;
  display: flex;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul {
  width: 1030px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  max-height: 90%;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li {
  min-width: 200px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li.has-sub-menu.active > a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
  color: #fff;
  font-size: 24px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover {
  color: #e77878;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul {
  padding: 30px 0;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li {
  padding: 0 0 12px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a {
  color: #fff;
  display: block;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a i {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  position: relative;
  top: 4px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a:hover {
  color: #e77878;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li:hover a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li.has-sub-menu.active a i {
  font-size: 15px;
  font-weight: 400;
  top: 0px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li ul {
  padding: 23px 0 10px;
}

.clickable-mainmenu-style5 {
  text-align: left;
  transition: all 520ms ease;
  position: fixed;
  height: 100% !important;
  top: 0;
  margin: 0;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  width: 400px;
  padding: 50px;
  left: 0;
  transform: translateX(-410px);
  overflow: auto;
}

.clickable-mainmenu-style5.open {
  margin-left: 94px;
  transform: translateX(0);
}

.clickable-mainmenu-style5.clickalbe-menu5-mrg {
  padding-top: 133px;
}

.clickable-mainmenu-style5 > nav > ul > li {
  display: block;
  line-height: 1;
}

.clickable-mainmenu-style5 > nav > ul > li > a {
  font-size: 20px;
  display: block;
  text-transform: capitalize;
  padding: 23px 0;
}

.clickable-mainmenu-style5 > nav > ul > li > a i {
  float: right;
  top: 2px;
  position: relative;
}

.clickable-mainmenu-style5 > nav > ul > li ul {
  padding: 14px 0 10px 29px;
}

.clickable-mainmenu-style5 > nav > ul > li ul li {
  padding-bottom: 22px;
}

.clickable-mainmenu-style5 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-style5 > nav > ul > li ul li a {
  color: #535353;
  font-size: 14px;
  display: block;
}

.clickable-mainmenu-style5 > nav > ul > li ul li a:hover {
  color: #e77878;
}

.clickable-mainmenu-style5 > nav > ul > li ul li a i {
  float: right;
  top: -1px;
  position: relative;
}

.clickable-mainmenu-style5 > nav > ul > li ul li ul {
  padding: 25px 0 4px 29px;
}

.menu-active-5, .menu-active-6 {
  position: relative;
}

.menu-active-5 i.m-close, .menu-active-6 i.m-close {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.menu-active-5.open i.m-open, .menu-active-6.open i.m-open {
  opacity: 0;
}

.menu-active-5.open i.m-close, .menu-active-6.open i.m-close {
  opacity: 1;
}

.clickable-mainmenu-wrapper6 {
  text-align: left;
  transition: all 520ms ease;
  position: fixed;
  height: 100% !important;
  top: 0;
  margin: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  width: 370px;
  padding: 40px 80px;
  left: 0;
  transform: translateX(-370px);
}

.clickable-mainmenu-wrapper6.open {
  margin-left: 94px;
  transform: translateX(0);
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 {
  overflow: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav {
  width: 100%;
  max-height: 100% !important;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul {
  width: 100%;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li {
  display: block;
  line-height: 1;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li a {
  font-size: 14px;
  display: block;
  text-transform: capitalize;
  padding: 20px 0;
  color: #777777;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li a i {
  margin-left: 13px;
  position: relative;
  top: 2px;
  font-size: 17px;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li ul {
  padding: 14px 0 10px 29px;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li ul li {
  padding-bottom: 22px;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li ul li a {
  padding: 0;
}

.clickable-mainmenu-wrapper6 .clickable-mainmenu-style6 > nav > ul > li ul li ul {
  padding: 25px 0 4px 29px;
}

body.body-menu-overlay {
  position: relative;
}

body.body-menu-overlay::before {
  content: "";
  background: #232324;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  z-index: 9;
  opacity: .5;
  width: 100%;
}

.clickable-mainmenu-wrapper-hm1 {
  width: 390px;
  padding: 90px 60px 50px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-wrapper-hm1 {
    width: 300px;
    padding: 60px 30px 50px;
  }
}

.clickable-mainmenu-wrapper-hm1 .clickable-mainmenu-wrapper-hm-all {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.clickable-mainmenu-hm1-bottom .social-icon-hm1 {
  margin-bottom: 40px;
}

.clickable-mainmenu-hm1-bottom .social-icon-hm1 a {
  display: block;
  margin-bottom: 22px;
}

.clickable-mainmenu-hm1-bottom .social-icon-hm1 a:lasy-child {
  margin-bottom: 0;
}

.clickable-mainmenu-hm1-bottom .copyright-hm1 p {
  font-size: 12px;
  color: #848484;
  margin: 0;
}

/*-------- 3. Slider style ---------*/
.main-sidebar-right {
  margin-left: 94px;
  padding: 0px 0px 0px 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right {
    padding: 0px 0px 0px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right {
    padding: 0px 0px 0px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right {
    padding: 0px 0px 0px 30px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right {
    padding: 0px 15px 0px 15px;
    margin-left: 0px;
  }
}

.main-product-slider-active .slick-list {
  padding-right: 30%;
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list {
    padding-right: 0%;
  }
}

.main-product-slider-active .slick-list .single-product-slider {
  position: relative;
  padding-bottom: 55vh;
  margin: 140px 10px 260px 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .slick-list .single-product-slider {
    margin: 100px 10px 260px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .slick-list .single-product-slider {
    margin: 80px 10px 260px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .slick-list .single-product-slider {
    margin: 0px 10px 260px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider {
    margin: 0px 0px 220px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider {
    margin: 0px 0px 165px 0px;
  }
}

.main-product-slider-active .slick-list .single-product-slider .product-slider-content {
  opacity: 0;
  visibility: hidden;
  margin: 0px 0 -230px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content {
    margin: 0px 0 -195px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content {
    margin: 0px 0 -208px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content {
    margin: 0px 0 -204px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content {
    margin: 0px 0 -210px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content {
    margin: 0px 0 -145px 0px;
  }
}

.main-product-slider-active .slick-list .single-product-slider .product-slider-content h2 {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content h2 {
    font-size: 24px;
    line-height: 1.1em;
  }
}

.main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
  color: #777777;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  margin: 25px 0 0;
  width: 55%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
    width: 77%;
    margin: 10px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
    width: 77%;
    margin: 10px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
    width: 75%;
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-content p {
    width: 100%;
    margin: 10px 0 0;
  }
}

.main-product-slider-active .slick-list .single-product-slider .product-slider-dot-style {
  position: absolute;
  bottom: -96px;
  right: 7px;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-dot-style {
    bottom: -50px;
  }
}

.main-product-slider-active .slick-list .single-product-slider .product-slider-dot-style a {
  color: #D5D5D5;
  font-size: 64px;
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .slick-list .single-product-slider .product-slider-dot-style a {
    font-size: 35px;
  }
}

.main-product-slider-active .slick-list .single-product-slider.slick-active .product-slider-content {
  opacity: 1;
  visibility: visible;
}

.main-product-slider-active .slick-list .single-product-slider.slick-active .product-slider-dot-style {
  opacity: 1;
  visibility: visible;
}

.main-product-slider-active .pro-slider-icon {
  background-color: rgba(0, 0, 0, 0.56);
  width: 60px;
  height: 60px;
  font-size: 23px;
  color: #fff;
  display: inline-block;
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
  transition: all 250ms ease-out;
  cursor: pointer;
  z-index: 999;
  bottom: 230px;
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .pro-slider-icon {
    transform: translateY(-140px);
    width: 35px;
    height: 35px;
  }
}

.main-product-slider-active .pro-slider-icon:hover {
  background-color: #e77878;
}

.main-product-slider-active .pro-slider-icon i {
  line-height: 60px;
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .pro-slider-icon i {
    line-height: 35px;
  }
}

.main-product-slider-active .pro-slider-icon.pro-slider-prev {
  top: auto;
  left: 62%;
  right: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-prev {
    left: 58%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-prev {
    left: 57%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-prev {
    left: 54%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-prev {
    left: 51.5%;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-prev {
    left: 0%;
  }
}

.main-product-slider-active .pro-slider-icon.pro-slider-next {
  top: auto;
  right: 30.6%;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-next {
    right: 30.8%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-next {
    right: 31%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-next {
    right: 31%;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-slider-active .pro-slider-icon.pro-slider-next {
    right: 0;
  }
}

.social-icon-name-wrap {
  width: 350px;
  margin: -193px 0 0 auto;
  z-index: 99;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-icon-name-wrap.social-icon-hm6 {
    width: 339px;
    margin: -218px 0px 0 auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-icon-name-wrap.social-icon-hm6 {
    width: 227px;
    margin: -218px 70px 0 auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-icon-name-wrap.social-icon-hm6 {
    width: 245px;
    margin: -220px 0 0 auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-icon-name-wrap.social-icon-hm6 {
    width: 212px;
    margin: -222px 0 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .social-icon-name-wrap.social-icon-hm6 {
    width: 100%;
    margin: 0px 0 30px auto;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-icon-name-wrap.social-icon-hm7 {
    width: 339px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-icon-name-wrap.social-icon-hm7 {
    width: 227px;
    margin: -193px 70px 0 auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-icon-name-wrap.social-icon-hm7 {
    width: 212px;
    margin: -169px auto 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-icon-name-wrap.social-icon-hm7 {
    width: 212px;
    margin: -220px auto 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .social-icon-name-wrap.social-icon-hm7 {
    width: 100%;
    margin: 30px 0 30px 0px;
    text-align: center;
  }
}

.social-icon-name-wrap .social-icon-name a {
  display: block;
  color: #262626;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-icon-name-wrap .social-icon-name a {
    font-size: 16px;
    margin: 0 0 5px;
  }
}

.social-icon-name-wrap .social-icon-name a:hover {
  color: #e77878;
}

.social-icon-name-wrap .social-icon-name a:last-child {
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .social-icon-name-wrap .social-icon-name a {
    font-size: 16px;
    margin: 0 0 8px;
  }
}

.social-icon-name-wrap .copyright-hm6 {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .social-icon-name-wrap .copyright-hm6 {
    margin: 15px 0 0;
  }
}

.social-icon-name-wrap .copyright-hm6 p {
  color: #848484;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.hm8-sidebar-right-2 {
  padding-left: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hm8-sidebar-right-2 {
    padding-left: 0px;
    border-top: 1px solid #ddd;
  }
}

@media only screen and (max-width: 767px) {
  .hm8-sidebar-right-2 {
    padding-left: 0px;
    border-top: 1px solid #ddd;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.mt-30 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area.mt-30 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  #rev_slider_25_1_wrapper .hm-18-layer3 #slide-72-layer-5 {
    font-size: 16px !important;
    line-height: 18px !important;
  }
  #rev_slider_25_1_wrapper .hm-18-layer3 #slide-72-layer-8 {
    font-size: 9px !important;
  }
  #rev_slider_25_1_wrapper .hm-18-layer2 #slide-71-layer-3 {
    font-size: 10px !important;
    line-height: 14px !important;
    margin: 5px 0 6px -24px !important;
  }
  #rev_slider_25_1_wrapper .hm-18-layer2 #slide-71-layer-4 {
    margin: 10px 0 0 0px !important;
  }
  #rev_slider_25_1_wrapper .hm-18-layer1 #slide-70-layer-8 {
    font-size: 9px !important;
  }
  #rev_slider_25_1_wrapper .rev-btn {
    padding: 6px 20px !important;
    font-size: 11px !important;
  }
  #rev_slider_8_1 .rev-btn {
    padding: 5px 30px !important;
  }
}

/*-------- 4. category style ---------*/
.section-padding-1 .container-fluid {
  padding: 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

.category-wrap {
  position: relative;
  overflow: hidden;
  transition: all .35s ease 0s;
}

.category-wrap a {
  display: block;
}

.category-wrap a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.category-wrap .category-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 21px 20px 22px;
  min-width: 180px;
  z-index: 9;
}

.category-wrap .category-content h4 {
  font-size: 18px;
  margin: 0;
  color: #fff;
  transition: all .35s ease 0s;
  text-align: center;
}

.category-wrap .category-content h4 a {
  color: #fff;
}

.category-wrap .category-content-3 {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap .category-content-3 {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrap .category-content-3 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .category-wrap .category-content-3 {
    left: 20px;
  }
}

.category-wrap .category-content-3 h4 {
  margin: 0;
  font-size: 24px;
  line-height: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap .category-content-3 h4 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrap .category-content-3 h4 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .category-wrap .category-content-3 h4 {
    font-size: 20px;
    line-height: 30px;
  }
}

.category-wrap:hover a img {
  transform: scale(1.05);
}

.category-wrap:hover .category-content h4 {
  letter-spacing: 1px;
}

.category-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.dot-style-1 .owl-dots {
  text-align: center;
  margin-top: 55px;
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .owl-dots {
    margin-top: 25px;
  }
}

.dot-style-1 .owl-dots .owl-dot {
  background-color: rgba(0, 0, 0, 0.14);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 12px;
  transition: all .3s ease 0s;
}

.dot-style-1 .owl-dots .owl-dot.active, .dot-style-1 .owl-dots .owl-dot:hover {
  background-color: #262626;
}

.dot-style-1.dot-style-mrg1 .owl-dots {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .dot-style-1.dot-style-mrg1 .owl-dots {
    margin-top: 20px;
  }
}

.dot-style-1.active-theme-color .owl-dots .owl-dot {
  background-color: #E7E7E7;
  margin: 0 6px;
}

.dot-style-1.active-theme-color .owl-dots .owl-dot.active, .dot-style-1.active-theme-color .owl-dots .owl-dot:hover {
  background-color: #e77878;
}

.category-wrap-2 {
  position: relative;
  overflow: hidden;
}

.category-wrap-2 a img {
  width: 100%;
}

.category-wrap-2 .category-content-2 {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  bottom: 65px;
  width: 100%;
  transition: transform 0.35s;
  text-align: center;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  visibility: hidden;
  padding: 26px 0 26px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category-wrap-2 .category-content-2 {
    padding: 15px 0 15px;
    bottom: 44px;
  }
}

.category-wrap-2 .category-content-2 h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category-wrap-2 .category-content-2 h3 {
    font-size: 15px;
  }
}

.category-wrap-2 .category-content-2 h3 a {
  color: #fff;
}

.category-wrap-2:hover .category-content-2 {
  transform: translate3d(0, 91%, 0);
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .category-area.pt-40 {
    padding-top: 15px;
  }
  .category-area.pb-85 {
    padding-bottom: 35px;
  }
}

/*-------- 5. Section title style ---------*/
.section-title-1 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 24px;
  line-height: 32px;
}

@media only screen and (max-width: 767px) {
  .section-title-1 h2 {
    font-size: 24px;
    margin: 0 0 8px;
    line-height: 32px;
  }
}

.section-title-1 p {
  color: #777777;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: 0;
}

.section-title-2 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 0px;
  line-height: 32px;
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 24px;
    margin: 0 0 8px;
    line-height: 32px;
  }
}

.section-title-3 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 0px;
  line-height: 32px;
  display: inline-block;
  padding: 25px 65px 29px 70px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 20px;
    line-height: 32px;
    padding: 9px 10px 10px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-70 {
    margin-bottom: 30px;
  }
}

.section-title-4 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 30px;
  line-height: 32px;
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    line-height: 25px;
    font-size: 25px;
    margin: 0 0 10px;
    letter-spacing: 1px;
  }
}

.section-title-4 p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
  line-height: 1.9em;
  margin: 0 auto;
  width: 65%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 p {
    width: 82%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-5 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 0px;
  line-height: 32px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.section-title-5 h2:before {
  position: absolute;
  content: "";
  left: -170px;
  top: 17px;
  width: 100px;
  height: 1px;
  background-color: #777;
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2:before {
    left: -40px;
    width: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-5 h2:before {
    left: -70px;
    width: 50px;
  }
}

.section-title-5 h2:after {
  position: absolute;
  content: "";
  right: -170px;
  top: 17px;
  width: 100px;
  height: 1px;
  background-color: #777;
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2:after {
    right: -40px;
    width: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-5 h2:after {
    right: -70px;
    width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    line-height: 25px;
    font-size: 25px;
    margin: 0 0 10px;
    letter-spacing: 1px;
  }
}

.section-title-6 h2 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 0px;
  line-height: 28px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 24px;
    margin: 0 0 8px;
    line-height: 32px;
  }
}

.section-title-7 img {
  margin: 0 auto;
  width: 34px;
}

.section-title-7 h2 {
  font-family: "Dancing Script", cursive;
  font-size: 36px;
  text-align: center;
  margin: 17px 0 0px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 25px;
    margin: 15px 0 8px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-8 h2 {
  font-family: "Dancing Script", cursive;
  font-size: 46px;
  text-align: center;
  margin: 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 35px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-9 h2 {
  font-size: 46px;
  text-align: center;
  margin: 0px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 29px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-10 h2 {
  font-size: 66px;
  text-align: center;
  margin: 0px;
  line-height: 55px;
  font-family: "Caveat", cursive;
}

@media only screen and (max-width: 767px) {
  .section-title-10 h2 {
    font-size: 40px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-11 h2 {
  font-size: 46px;
  text-align: center;
  margin: 0px;
  line-height: 35px;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  padding: 0 0 30px;
}

.section-title-11 h2::before {
  position: absolute;
  background-color: #bdbd74;
  z-index: 1;
  width: 80px;
  height: 2px;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2 {
    font-size: 27px;
    line-height: 19px;
    letter-spacing: 1px;
    padding: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-12 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 0px;
  line-height: 33px;
}

@media only screen and (max-width: 767px) {
  .section-title-12 h2 {
    font-size: 24px;
    margin: 0 0 8px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-13 h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 0px;
  line-height: 32px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.section-title-13 h2:before {
  position: absolute;
  content: "";
  left: -97px;
  top: 17px;
  width: 47px;
  height: 2px;
  background-color: #262626;
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2:before {
    left: -40px;
    width: 30px;
    top: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-13 h2:before {
    left: -70px;
    width: 50px;
  }
}

.section-title-13 h2:after {
  position: absolute;
  content: "";
  right: -97px;
  top: 17px;
  width: 47px;
  height: 2px;
  background-color: #262626;
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2:after {
    right: -40px;
    width: 30px;
    top: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-13 h2:after {
    right: -70px;
    width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2 {
    line-height: 25px;
    font-size: 25px;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13.mb-50 {
    margin-bottom: 30px;
  }
}

/*-------- 6. Product style ---------*/
.pro-tab-viewmore-wrap {
  position: relative;
}

.pro-tab-viewmore-wrap .pro-viewmore {
  position: absolute;
  right: 40px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .pro-tab-viewmore-wrap .pro-viewmore {
    position: static;
    right: 0px;
    left: 0;
    text-align: center;
    margin-bottom: 30px;
  }
}

.pro-tab-viewmore-wrap .pro-viewmore a {
  font-size: 11px;
  color: #373737;
  display: inline-block;
  position: relative;
}

.pro-tab-viewmore-wrap .pro-viewmore a:hover {
  color: #e77878;
}

.pro-tab-viewmore-wrap .pro-viewmore a::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 30px;
  background-color: #ccc;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
}

.product-tab-list {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .product-tab-list.mt-60 {
    margin-top: 20px;
  }
  .product-tab-list.mt-40 {
    margin-top: 20px;
  }
  .product-tab-list.mb-25 {
    margin-bottom: 15px;
  }
}

.product-tab-list.tab-list-width {
  max-width: 1000px;
  margin: 60px auto 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list.tab-list-width {
    max-width: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list.tab-list-width {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list.tab-list-width {
    max-width: 460px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list.tab-list-width {
    margin: 20px auto 0px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list.tab-list-width a {
    margin: 0 6px;
  }
}

.product-tab-list a {
  color: #373737;
  text-transform: uppercase;
  margin: 0 20px;
  display: inline-block;
  position: relative;
  padding: 0 0 22px 0;
}

@media only screen and (max-width: 767px) {
  .product-tab-list a {
    margin: 0 10px;
  }
}

.product-tab-list a::before {
  content: "";
  bottom: 0;
  width: 2px;
  height: 2px;
  background: #262626;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .product-tab-list a::before {
    bottom: 5px;
  }
}

.product-tab-list a.active, .product-tab-list a:hover {
  color: #262626;
}

.product-tab-list a.active::before, .product-tab-list a:hover::before {
  opacity: 1;
  width: 2px;
  height: 18px;
}

.product-tab-list.tab-color-2 a {
  color: #777777;
}

.product-tab-list.tab-color-2 a.active, .product-tab-list.tab-color-2 a:hover {
  color: #262626;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.product-wrap .product-img > a {
  display: block;
  position: relative;
}

.product-wrap .product-img > a img {
  width: 100%;
}

.product-wrap .product-img > a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}

.product-wrap .product-img > a > span.price-dec {
  background-color: #DE3535;
  display: inline-block;
  color: #fff;
  right: 20px;
  top: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 100%;
  text-align: center;
  position: absolute;
}

.product-wrap .product-img > a > span.out-of-stock {
  background-color: #262626;
  display: inline-block;
  color: #fff;
  right: 20px;
  top: 20px;
  padding: 10px 20px;
  text-align: center;
  position: absolute;
  font-size: 10px;
  line-height: 1;
}

.product-wrap .product-img .product-action {
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 75px;
  z-index: 99;
}

.product-wrap .product-img .product-action a {
  background-color: rgba(38, 38, 38, 0.9);
  border-radius: 50px;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 45px;
  left: 30px;
  margin-bottom: 5px;
  opacity: 0;
  position: relative;
  text-align: center;
  visibility: hidden;
  width: 45px;
}

.product-wrap .product-img .product-action a i {
  line-height: 45px;
}

.product-wrap .product-img .product-action a:nth-child(1) {
  transition-delay: 0.1s;
}

.product-wrap .product-img .product-action a:nth-child(2) {
  transition-delay: 0.2s;
}

.product-wrap .product-img .product-action a:nth-child(3) {
  transition-delay: 0.3s;
}

.product-wrap .product-img .product-action a span {
  visibility: hidden;
  position: absolute;
  background-color: inherit;
  text-align: center;
  padding: 5px 12px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 11px;
  right: 100%;
  line-height: 14px;
  top: 50%;
  margin-top: -12px;
  margin-right: 0;
}

.product-wrap .product-img .product-action a span::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e77878;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 0;
}

.product-wrap .product-img .product-action a:hover {
  background-color: #e77878;
}

.product-wrap .product-img .product-action a:hover span {
  visibility: visible;
  opacity: 1;
  margin-right: 10px;
}

.product-wrap .product-img .product-action.pro-action-width-dec a {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.product-wrap .product-img .product-action.pro-action-width-dec a i {
  line-height: 30px;
}

.product-wrap .product-img .product-action.pro-action-width-dec-2 {
  bottom: 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 {
    bottom: 20px;
  }
}

.product-wrap .product-img .product-action.pro-action-width-dec-2 a {
  width: 40px;
  height: 40px;
  font-size: 18px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a {
    margin-bottom: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a {
    margin-bottom: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a {
    margin-bottom: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a {
    margin-bottom: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.product-wrap .product-img .product-action.pro-action-width-dec-2 a i {
  line-height: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a i {
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a i {
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a i {
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.pro-action-width-dec-2 a i {
    line-height: 35px;
  }
}

.product-wrap .product-img .product-action-2 {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  transform: translate(0, 40px);
  text-align: center;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease-out;
}

.product-wrap .product-img .product-action-2 a {
  display: block;
  background-color: rgba(38, 38, 38, 0.9);
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 20px 23px;
  line-height: 1;
}

.product-wrap .product-img .product-action-2 a:hover {
  background-color: #e77878;
}

.product-wrap .product-img .product-action-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.product-wrap .product-img .product-action-3 .product-action-3-top {
  display: flex;
  width: auto;
  max-width: 165px;
  position: static;
  right: 10px;
  bottom: 70px;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a {
  color: #111;
  background-color: #fff;
  font-size: 18px;
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 5px;
  transition: all 250ms ease-out;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 30px);
}

.product-wrap .product-img .product-action-3 .product-action-3-top a:nth-child(1) {
  transition-delay: 100ms;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a:nth-child(2) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a:nth-child(3) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a i {
  line-height: 45px;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a span {
  position: absolute;
  background-color: inherit;
  text-align: center;
  padding: 5px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 11px;
  font-family: sans-serif;
  line-height: 14px;
  right: auto;
  top: auto;
  bottom: 100%;
  margin-top: 0;
  transform: translateX(-50%);
  left: 50%;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a span:after {
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 0;
  content: "";
  position: absolute;
  border-top: 5px solid #e77878;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a:hover {
  background-color: #e77878;
  color: #fff;
}

.product-wrap .product-img .product-action-3 .product-action-3-top a:hover span {
  visibility: visible;
  opacity: 1;
  margin-bottom: 10px;
}

.product-wrap .product-img .product-action-3 .product-action-3-bottom a {
  text-transform: uppercase;
  color: #111;
  background-color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 17px 15px 16px;
  line-height: 1;
  margin: 5px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 30px);
  transition: all 250ms ease-out;
  transition-delay: 100ms;
  width: 155px;
  text-align: center;
}

.product-wrap .product-img .product-action-3 .product-action-3-bottom a:hover {
  background-color: #e77878;
  color: #fff;
}

.product-wrap .product-img .product-price-3 {
  position: absolute;
  left: 20px;
  bottom: 15px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease 0s;
}

.product-wrap .product-img .product-price-3 span {
  font-size: 16px;
}

.product-wrap .product-img.product-border {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.16);
}

.product-wrap .product-img .shop-list-quickview {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all .35s ease 0s;
}

.product-wrap .product-img .shop-list-quickview a {
  color: #000;
  background-color: #fff;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 18px;
}

.product-wrap .product-img .shop-list-quickview a:hover {
  background-color: #e77878;
  color: #fff;
}

.product-wrap .product-img .shop-list-quickview a i {
  line-height: 50px;
}

.product-wrap .product-img .product-content-11 {
  position: absolute;
  width: calc(100% - 60px);
  bottom: 0;
  left: 0;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all .35s ease 0s;
  z-index: 9999;
}

.product-wrap .product-img .product-content-11 h3 {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.product-wrap .product-img .product-content-11 h3 a {
  color: #fff;
}

.product-wrap .product-img .product-content-11 h3 a:hover {
  text-decoration: underline;
}

.product-wrap .product-img .product-content-11 .product-price-5 {
  margin: 10px 0 2px;
  display: block;
}

.product-wrap .product-img .product-content-11 .product-price-5 span {
  line-height: 20px;
  font-size: 16px;
  color: #fff;
}

.product-wrap .product-img .product-content-11 .product-price-5 span.old {
  text-decoration: line-through;
  margin-right: 5px;
}

.product-wrap .product-img .product-content-11 .product-rating-2 {
  line-height: 1;
}

.product-wrap .product-img .product-content-11 .product-rating-2 i {
  color: #d5d6d8;
  font-size: 12px;
}

.product-wrap .product-img .product-content-11 .product-rating-2 i.yellow {
  color: #f5b223;
}

.product-wrap .product-img:hover.default-overlay::before {
  background-color: rgba(38, 38, 38, 0.2);
  z-index: 9;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}

.product-wrap .product-img:hover.default-overlay.metro-overlay::before {
  background-color: rgba(38, 38, 38, 0.4);
  z-index: 9;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}

.product-wrap .product-img:hover img.hover-img {
  opacity: 1;
}

.product-wrap .product-content h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.product-wrap .product-content .product-price {
  margin: 8px 0 3px;
}

.product-wrap .product-content .product-price span {
  font-size: 16px;
}

.product-wrap .product-content .product-price span.old {
  text-decoration: line-through;
  opacity: .6;
  margin-right: 2px;
}

.product-wrap .product-content .product-rating {
  line-height: 1;
}

.product-wrap .product-content .product-rating i {
  font-size: 12px;
  color: #d5d6d8;
}

.product-wrap .product-content .product-rating i.yellow {
  color: #f5b223;
}

.product-wrap .product-content.product-content-hm14 h3 {
  font-size: 14px;
  font-weight: 300;
}

.product-wrap .product-content.pro-content-pro-details h3 {
  font-size: 16px;
  font-weight: 400;
}

.product-wrap .product-content-8 h3 {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.product-wrap .product-content-9 h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 6px;
}

.product-wrap .product-content-9 .product-price span {
  font-size: 16px;
  font-weight: bold;
}

.product-wrap .product-content-9 .product-price span.old {
  text-decoration: line-through;
  margin-right: 3px;
  color: #777;
}

.product-wrap .product-content-9.font-width-dec3 h3 {
  font-weight: 300;
  margin: 0 0 9px;
}

.product-wrap .product-content-9.font-width-dec3 .product-price span {
  font-weight: 600;
}

.product-wrap .product-content-10 h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 12px;
}

.product-wrap .product-content-10 h3 a {
  color: #262626;
}

.product-wrap .product-content-10 h3 a:hover {
  color: #bdbd74;
}

.product-wrap .product-content-10 .product-price-4 span {
  color: #bdbd74;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.product-wrap .product-content-10.pro-content-10-white h3 a {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 9px;
}

.product-wrap .product-content-10.pro-content-10-white h3 a:hover {
  color: #fff;
  text-decoration: underline;
}

.product-wrap .product-content-10.pro-content-10-white .product-price-4 span {
  color: #fff;
}

.product-wrap:hover .product-action-2 {
  transform: translate(0px, 0);
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-3 .product-action-3-top a {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0px);
}

.product-wrap:hover .product-action-3 .product-action-3-bottom a {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0px);
}

.product-wrap:hover .product-price-3 {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .pro-action-width-dec a {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .default-overlay.pro-overlay-hover::before {
  background-color: rgba(38, 38, 38, 0.2);
  z-index: 9;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}

.product-wrap:hover .product-action a {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-content-11 {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-55 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.product-wrap.product-wrap-hm18 .product-img .product-action a span::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #bdbd74;
}

.product-wrap.product-wrap-hm18 .product-img .product-action a:hover {
  background-color: #bdbd74;
}

.product-wrap.product-wrap-hm18 .product-img .product-action-2 a:hover {
  background-color: #bdbd74;
}

@media only screen and (max-width: 767px) {
  .product-slider-active.mt-70 {
    margin-top: 26px;
  }
  .special-offer-area.pt-40 {
    padding-top: 10px;
  }
  .new-trend-area.pb-45 {
    padding-bottom: 15px;
  }
  .product-area.pb-45 {
    padding-bottom: 20px;
  }
  .product-area.pb-40 {
    padding-bottom: 15px;
  }
  .popular-collection-area.pb-85 {
    padding-bottom: 35px;
  }
  .popular-collection-active.pt-60 {
    padding-top: 30px;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: moveUp;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.padding-10-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-5-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

.padding-5-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-32-row-col .row {
  margin-right: -32.5px;
  margin-left: -32.5px;
}

.padding-32-row-col .row div[class^="col-"] {
  padding-left: 32.5px;
  padding-right: 32.5px;
}

.custom-container {
  width: 1430px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.custom-container-5 {
  width: 1270px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-5 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-5 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-5 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-5 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-5 {
    width: 100%;
  }
}

.product-viewmore-wrap {
  line-height: 1;
}

.product-viewmore-wrap a {
  position: relative;
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  margin-right: 1px;
}

.product-viewmore-wrap a .pro-viewmore-common {
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  padding: 16px 40px 20px 40px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.14);
  text-align: center;
}

.product-viewmore-wrap a .pro-viewmore-common span {
  color: #373737;
  font-size: 11px;
}

.product-viewmore-wrap a .pro-viewmore-common.viewmore-hm14 span {
  font-size: 12px;
  font-weight: 300;
}

.product-viewmore-wrap a .pro-viewmore-common.viewmore-hm14-2 {
  padding: 12px 40px 16px 40px;
}

.product-viewmore-wrap a .pro-viewmore-common.viewmore-hm15 span {
  font-size: 12px;
  font-weight: 400;
  color: #262626;
}

.product-viewmore-wrap a .pro-viewmore-normal {
  opacity: 1;
  transform: scale(1);
}

.product-viewmore-wrap a .pro-viewmore-hover {
  opacity: 0;
  transform: scale(1.5);
  background-color: #54595f;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.product-viewmore-wrap a .pro-viewmore-hover span {
  color: #fff;
}

.product-viewmore-wrap a .pro-viewmore-hover.viewmore-hm14-2 {
  background-color: #e77878;
}

.product-viewmore-wrap a .pro-viewmore-hover.viewmore-hm15 {
  background-color: #e77878;
}

.product-viewmore-wrap a:hover > .pro-viewmore-normal {
  opacity: 0;
  transform: scale(0);
}

.product-viewmore-wrap a:hover > .viewmore-hm14-2 {
  border-color: #e77878;
}

.product-viewmore-wrap a:hover > .viewmore-hm15 {
  border-color: #e77878;
}

.product-viewmore-wrap a:hover > .viewmore-hm15 span {
  color: #fff;
}

.product-viewmore-wrap a:hover > .pro-viewmore-hover {
  opacity: 1;
  transform: scale(1);
}

.product-viewmore-wrap.about-learnwmore-btn a .pro-viewmore-common {
  padding: 12px 40px 14px 40px;
}

.product-viewmore-wrap.about-learnwmore-btn a .pro-viewmore-common span {
  font-size: 12px;
}

.product-viewmore-wrap.about-learnwmore-btn a .pro-viewmore-hover {
  background-color: #e77878;
}

.product-viewmore-wrap.about-learnwmore-btn a:hover > .pro-viewmore-common {
  border-color: #e77878;
}

.product-viewmore-wrap.deal-btn-wrap {
  width: 100%;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .product-viewmore-wrap.deal-btn-wrap {
    text-align: center;
  }
}

.product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common {
  padding: 23px 40px 23px 40px;
  background-color: #50e3c2;
  border: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common {
    padding: 15px 15px 15px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common {
    padding: 15px 20px 15px 20px;
  }
}

.product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common span {
  font-size: 16px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
  color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-common span {
    font-size: 14px;
  }
}

.product-viewmore-wrap.deal-btn-wrap a .pro-viewmore-hover {
  background-color: #e77878;
}

.product-viewmore-wrap.deal-btn-wrap a:hover > .pro-viewmore-common {
  border-color: #e77878;
  border: none;
}

.call-to-action-area {
  position: relative;
}

.call-to-action-area .call-to-action-img-area {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  perspective: 1200px;
}

.call-to-action-area .call-to-action-img-area .call-to-action-img {
  background-size: auto;
  background-position: 0% calc(50% + 78.5px);
  position: absolute;
  left: 0;
  top: -10%;
  height: 120%;
  width: 100%;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .call-to-action-area .call-to-action-img-area .call-to-action-img {
    background-size: 70% !important;
    background-position: -40% calc(50% + 118.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .call-to-action-area .call-to-action-img-area .call-to-action-img {
    background-size: 70% !important;
    background-position: -40% calc(50% + 118.5px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .call-to-action-area .call-to-action-img-area .call-to-action-img {
    background-size: 70% !important;
    background-position: -40% calc(50% + 35.5px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-to-action-area .call-to-action-img-area .call-to-action-img {
    background-size: 70% !important;
    background-position: -40% calc(50% + 5.5px);
  }
}

@media only screen and (max-width: 767px) {
  .call-to-action-area .call-to-action-img-area .call-to-action-img {
    background-size: 95% !important;
    background-position: -40% calc(50% + 5.5px);
  }
}

.call-to-action-area .call-to-action-content {
  margin-left: 30px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .call-to-action-area .call-to-action-content {
    margin-left: 0px;
  }
}

.call-to-action-area .call-to-action-content h5 {
  font-size: 20px;
  letter-spacing: 2.5px;
  font-weight: 300;
  font-family: "Playfair Display", serif;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .call-to-action-area .call-to-action-content h5 {
    font-size: 17px;
    letter-spacing: 0px;
  }
}

.call-to-action-area .call-to-action-content h1 {
  font-size: 120px;
  letter-spacing: 15px;
  font-weight: 300;
  font-family: "Playfair Display", serif;
  line-height: 1em;
  margin: 20px 0 51px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .call-to-action-area .call-to-action-content h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .call-to-action-area .call-to-action-content h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .call-to-action-area .call-to-action-content h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-to-action-area .call-to-action-content h1 {
    font-size: 60px;
    margin: 20px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .call-to-action-area .call-to-action-content h1 {
    font-size: 37px;
    margin: 15px 0 25px;
    letter-spacing: 10px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .call-to-action-area.pt-200 {
    padding-top: 100px;
  }
  .call-to-action-area.pb-200 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .call-to-action-area.pt-200 {
    padding-top: 100px;
  }
  .call-to-action-area.pb-200 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .call-to-action-area.pt-200 {
    padding-top: 100px;
  }
  .call-to-action-area.pb-200 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-to-action-area.pt-200 {
    padding-top: 80px;
  }
  .call-to-action-area.pb-200 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .call-to-action-area.pt-200 {
    padding-top: 50px;
  }
  .call-to-action-area.pb-200 {
    padding-bottom: 50px;
  }
}

.call-to-action-wrap {
  line-height: 1;
  z-index: 9;
  position: relative;
}

.call-to-action-wrap a {
  position: relative;
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  margin-right: 1px;
}

.call-to-action-wrap a .call-to-action-common {
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  padding: 18px 40px 20px 40px;
  border: 1px solid #262626;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .call-to-action-wrap a .call-to-action-common {
    padding: 14px 30px 16px 30px;
  }
}

.call-to-action-wrap a .call-to-action-common span {
  color: #262626;
  font-size: 14px;
  font-weight: 500;
}

.call-to-action-wrap a .call-to-action-common.btn-wd-dec {
  padding: 23px 40px 25px 40px;
}

@media only screen and (max-width: 767px) {
  .call-to-action-wrap a .call-to-action-common.btn-wd-dec {
    padding: 14px 30px 16px 30px;
  }
}

.call-to-action-wrap a .call-to-action-common.btn-wd-dec span {
  font-weight: 400;
}

.call-to-action-wrap a .call-to-action-common.deal-btn {
  padding: 12px 40px 14px 40px;
  border: 2px solid #fff;
}

.call-to-action-wrap a .call-to-action-common.deal-btn span {
  font-weight: 500;
  font-size: 11px;
  color: #fff;
}

.call-to-action-wrap a .call-to-action-common.white-action-btn {
  border: 1px solid #fff;
}

.call-to-action-wrap a .call-to-action-common.white-action-btn span {
  color: #fff;
}

.call-to-action-wrap a .call-to-action-common.viewmore-hm14 {
  border: 2px solid #54595f;
  padding: 12px 40px 16px 40px;
}

.call-to-action-wrap a .call-to-action-common.viewmore-hm14 span {
  font-size: 11px;
  font-weight: bold;
}

.call-to-action-wrap a .call-to-action-common.error-btn {
  padding: 22px 40px 21px 40px;
}

.call-to-action-wrap a .call-to-action-common.error-btn span {
  font-size: 16px;
  font-weight: 400;
}

.call-to-action-wrap a .call-to-action-common.soon-btn {
  padding: 22px 40px 21px 40px;
  border: 2px solid #fff;
}

@media only screen and (max-width: 767px) {
  .call-to-action-wrap a .call-to-action-common.soon-btn {
    padding: 15px 30px 14px 30px;
  }
}

.call-to-action-wrap a .call-to-action-common.soon-btn span {
  font-size: 16px;
  font-weight: 400;
}

.call-to-action-wrap a .call-to-action-normal {
  opacity: 1;
  transform: scale(1);
}

.call-to-action-wrap a .call-to-action-hover {
  opacity: 0;
  transform: scale(1.5);
  background-color: #54595f;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.call-to-action-wrap a .call-to-action-hover span {
  color: #fff;
}

.call-to-action-wrap a .call-to-action-hover.hover-tm-color {
  background-color: #e77878;
}

.call-to-action-wrap a .call-to-action-hover.viewmore-hm14 {
  background-color: #e77878;
}

.call-to-action-wrap a:hover > .call-to-action-common {
  border: 1px solid #54595f;
}

.call-to-action-wrap a:hover > .call-to-action-common.hover-tm-color {
  border: 1px solid #e77878;
}

.call-to-action-wrap a:hover > .call-to-action-common.deal-btn {
  border: 2px solid #e77878;
}

.call-to-action-wrap a:hover > .call-to-action-common.viewmore-hm14 {
  border: 2px solid #e77878;
}

.call-to-action-wrap a:hover > .call-to-action-common.soon-btn {
  border: 2px solid #e77878;
}

.call-to-action-wrap a:hover > .call-to-action-normal {
  opacity: 0;
  transform: scale(0);
}

.call-to-action-wrap a:hover > .call-to-action-hover {
  opacity: 1;
  transform: scale(1);
}

.section-padding-2 .container-fluid {
  padding: 0 170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 315px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

.padding-30-row-col .row {
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-30-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-30-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-30-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-30-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-30-row-col .row div[class^="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-30-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-30-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-30-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-30-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-35-row-col .row {
  margin-right: -35px;
  margin-left: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-35-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-35-row-col .row {
    margin-right: -30px;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-35-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-35-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-35-row-col .row div[class^="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-35-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-35-row-col .row div[class^="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-35-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-35-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-40-row-col .row {
  margin-right: -40px;
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-40-row-col .row div[class^="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.column-pro-custom {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.column-pro-custom.pro-width-22 {
  flex: 0 0 22%;
  max-width: 22%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .column-pro-custom.pro-width-22 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .column-pro-custom.pro-width-22 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column-pro-custom.pro-width-78 {
  flex: 0 0 78%;
  max-width: 78%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .column-pro-custom.pro-width-78 {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .column-pro-custom.pro-width-78 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.pro-categories-wrap-all {
  width: 100%;
  margin-right: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-categories-wrap-all {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-categories-wrap-all {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-categories-wrap-all {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-categories-wrap-all {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.pro-categories-wrap-all .pro-categories-wrap {
  padding: 40px 40px 35px 40px;
  border: 1px solid #cfcfcf;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-categories-wrap-all .pro-categories-wrap {
    padding: 40px 15px 35px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-categories-wrap-all .pro-categories-wrap {
    padding: 30px 15px 25px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-categories-wrap-all .pro-categories-wrap {
    padding: 30px 20px 25px 20px;
  }
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-title {
  margin: 0 0 40px;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-title h3 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 300;
  margin: 0;
  line-height: 1;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-list ul li {
  padding-bottom: 17px;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-list ul li:last-child {
  padding-bottom: 0;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-list ul li a {
  text-transform: uppercase;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-btn.pro-categorie-border {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.1);
  padding-top: 8px;
  margin-top: 33px;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-btn.pro-categorie-border2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  margin-top: 38px;
}

.pro-categories-wrap-all .pro-categories-wrap .pro-categorie-btn a {
  font-size: 11px;
  display: block;
  line-height: 1;
}

.pro-column-width-100 {
  width: 100%;
  margin-left: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-column-width-100 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-column-width-100 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-column-width-100 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-column-width-100 {
    margin-left: 0px;
  }
}

.product-tab-list-2 {
  display: flex;
  justify-content: center;
}

.product-tab-list-2 a {
  color: #d5d5d5;
  font-size: 36px;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 50px;
  line-height: 35px;
}

.product-tab-list-2 a.active, .product-tab-list-2 a:hover {
  color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-2 a {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    margin: 0 15px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    margin: 0 10px 15px;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-2 a {
    margin: 0 10px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2.mb-65 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2.mb-65 {
    margin-bottom: 30px;
  }
}

.product-tab-list-3 {
  display: flex;
  justify-content: center;
}

.product-tab-list-3 a {
  color: #777777;
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  border: 1px solid transparent;
  padding: 15px 30px 16px;
  margin: 0 2px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-3 a {
    padding: 13px 16px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    margin: 0 2px 4px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0 2px 4px;
    padding: 8px 8px 9px;
    font-size: 14px;
  }
  .product-tab-list-3 a.mt-50 {
    margin-top: 30px;
  }
}

.product-tab-list-3 a.active, .product-tab-list-3 a:hover {
  border: 1px solid #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3.mt-50 {
    margin-top: 15px;
  }
  .product-tab-list-3.mb-40 {
    margin-bottom: 20px;
  }
  .product-tab-list-3.mb-50 {
    margin-bottom: 20px;
  }
}

.product-tab-list-3.tab-list-hm17 a {
  border: none;
  background-color: transparent;
}

.product-tab-list-3.tab-list-hm17 a.active, .product-tab-list-3.tab-list-hm17 a:hover {
  background-color: #e77878;
  color: #fff;
}

.product-tab-list-3.tab-list-hm18 a {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto Condensed", sans-serif;
  color: #777777;
}

.product-tab-list-3.tab-list-hm18 a.active, .product-tab-list-3.tab-list-hm18 a:hover {
  background-color: #bdbd74;
  color: #fff;
}

.product-tab-list-4 {
  display: flex;
  justify-content: center;
}

.product-tab-list-4 a {
  color: #373737;
  font-size: 12px;
  display: inline-block;
  margin: 0 20px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0 10px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0 7px 10px;
  }
}

.product-tab-list-4 a.active, .product-tab-list-4 a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4.mt-60 {
    margin-top: 23px;
  }
  .product-tab-list-4.mb-35 {
    margin-bottom: 10px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 250px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-4 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-2 {
    padding: 40px 0 20px;
  }
}

.product-wrap-2 .product-content-2 h2 {
  font-size: 80px;
  letter-spacing: 2px;
  margin: 0 0 30px;
  line-height: 114px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap-2 .product-content-2 h2 {
    font-size: 65px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap-2 .product-content-2 h2 {
    font-size: 50px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-2 .product-content-2 h2 {
    font-size: 50px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap-2 .product-content-2 h2 {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-2 .product-content-2 h2 {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 1px;
    margin: 0 0 10px;
  }
}

.product-ten-img img {
  width: 100%;
}

.bg-flex {
  display: flex;
  align-items: center;
}

.bg-flex .product-wrap-3 {
  padding-left: 98px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-flex .product-wrap-3 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex .product-wrap-3 {
    padding-left: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-flex .product-wrap-3 {
    padding-left: 15px;
  }
}

.bg-flex .product-wrap-3 .product-content-3 {
  position: relative;
  z-index: 9;
}

.bg-flex .product-wrap-3 .product-content-3 h2 {
  font-size: 80px;
  letter-spacing: 2px;
  line-height: 1.4074;
  margin: 0 0 30px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bg-flex .product-wrap-3 .product-content-3 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bg-flex .product-wrap-3 .product-content-3 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-flex .product-wrap-3 .product-content-3 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex .product-wrap-3 .product-content-3 h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-flex .product-wrap-3 .product-content-3 h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

.bg-flex-2 {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .bg-flex-2 {
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex-2 {
    align-items: center;
  }
}

.bg-flex-2 .product-wrap-4 {
  padding-left: 243px;
  padding-bottom: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bg-flex-2 .product-wrap-4 {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-flex-2 .product-wrap-4 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex-2 .product-wrap-4 {
    padding-left: 45px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-flex-2 .product-wrap-4 {
    padding-left: 15px;
    padding-bottom: 0px;
  }
}

.bg-flex-2 .product-wrap-4 .product-content-4 h2 {
  font-size: 64px;
  letter-spacing: 3px;
  line-height: 1.4074;
  margin: 0 0 30px;
  font-family: "Playfair Display", serif;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex-2 .product-wrap-4 .product-content-4 h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-flex-2 .product-wrap-4 .product-content-4 h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

.product-wrap-5 {
  padding: 195px 0 320px 250px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap-5 {
    padding: 95px 0 220px 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap-5 {
    padding: 95px 0 220px 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-5 {
    padding: 95px 0 220px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap-5 {
    padding: 45px 0 170px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-5 {
    padding: 0px 0 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap-5 {
    padding: 0px 0 70px 15px;
  }
}

.product-wrap-5 .product-content-5 h2 {
  font-size: 64px;
  line-height: 1.4074;
  margin: 0 0 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap-5 .product-content-5 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap-5 .product-content-5 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-5 .product-content-5 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap-5 .product-content-5 h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-5 .product-content-5 h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

.bg-flex-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-flex-3 .product-wrap-6 {
  text-align: center;
}

.bg-flex-3 .product-wrap-6 .product-content-6 h2 {
  font-size: 120px;
  line-height: 1.4074;
  margin: 0 0 40px;
  font-family: "Playfair Display", serif;
  color: #fff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bg-flex-3 .product-wrap-6 .product-content-6 h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bg-flex-3 .product-wrap-6 .product-content-6 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-flex-3 .product-wrap-6 .product-content-6 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-flex-3 .product-wrap-6 .product-content-6 h2 {
    font-size: 50px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-flex-3 .product-wrap-6 .product-content-6 h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

.section-padding-5 {
  padding: 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-5 {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-5 {
    padding: 0 30px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 210px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-6 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

.single-popular-collection {
  overflow: hidden;
}

.single-popular-collection a img {
  transform: scale(1);
  transition: opacity 0.35s, transform 0.35s;
}

.single-popular-collection:hover a img {
  transform: scale(1.2);
}

.slick-initialized .product-wrap-7.slick-slide {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .slick-initialized .product-wrap-7.slick-slide {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slick-initialized .product-wrap-7.slick-slide {
    display: flex;
  }
}

.product-wrap-7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  padding: 45px 35px;
  position: relative;
  margin: 20px 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-7 {
    padding: 25px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-7 {
    padding: 25px 20px;
  }
}

.product-wrap-7 .product-img-2 {
  flex: 0 0 200px;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-7 .product-img-2 {
    flex: 0 0 170px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-7 .product-img-2 {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap-7 .product-img-2 {
    margin-right: 15px;
    margin-bottom: 0px;
  }
}

.product-wrap-7 .product-img-2 a {
  display: block;
}

.product-wrap-7 .product-img-2 a img {
  width: 100%;
}

.product-wrap-7 .product-img-2 .product-action-4 {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.product-wrap-7 .product-img-2 .product-action-4 > a {
  background-color: rgba(38, 38, 38, 0.9);
  border-radius: 100%;
  color: #fff;
  display: block;
  font-size: 14px;
  height: 36px;
  left: 30px;
  margin-bottom: 5px;
  opacity: 0;
  position: relative;
  text-align: center;
  visibility: hidden;
  width: 36px;
  font-weight: bold;
  border-style: solid;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:last-child {
  margin-bottom: 0;
}

.product-wrap-7 .product-img-2 .product-action-4 > a i {
  line-height: 31px;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:nth-child(1) {
  transition-delay: 0.1s;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:nth-child(2) {
  transition-delay: 0.2s;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:nth-child(3) {
  transition-delay: 0.3s;
}

.product-wrap-7 .product-img-2 .product-action-4 > a span {
  visibility: hidden;
  position: absolute;
  background-color: inherit;
  text-align: center;
  padding: 5px 12px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 11px;
  right: 100%;
  line-height: 14px;
  top: 50%;
  margin-top: -12px;
  margin-right: 0;
  font-weight: 400;
}

.product-wrap-7 .product-img-2 .product-action-4 > a span::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e77878;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 0;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:hover {
  background-color: #e77878;
}

.product-wrap-7 .product-img-2 .product-action-4 > a:hover span {
  visibility: visible;
  opacity: 1;
  margin-right: 10px;
}

.product-wrap-7 .product-content-7 h3 {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-7 .product-content-7 h3 {
    font-size: 16px;
    line-height: 25px;
  }
}

.product-wrap-7 .product-content-7 .product-price-2 {
  margin: 13px 0 14px;
}

.product-wrap-7 .product-content-7 .product-price-2 span {
  font-size: 18px;
}

.product-wrap-7 .product-content-7 p {
  font-size: 11px;
  line-height: 2;
  color: #777777;
  margin: 0 0 19px;
}

.product-wrap-7 .product-content-7 .pro-add-to-cart a {
  font-size: 11px;
  display: inline-block;
  border: 2px solid #262626;
  line-height: 1;
  padding: 14px 40px 15px;
}

.product-wrap-7 .product-content-7 .pro-add-to-cart a:hover {
  background-color: #262626;
  color: #fff;
}

.product-wrap-7:hover .product-action-4 a {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.featured-slider-active {
  margin: 40px 0px 0;
}

@media only screen and (max-width: 767px) {
  .featured-slider-active {
    margin: 5px 0px 0;
  }
}

.featured-slider-active .slick-list {
  margin: 0px -15px 0;
}

.slick-dot-style-1 .slick-dots {
  display: flex;
  justify-content: center;
}

.slick-dot-style-1 .slick-dots li {
  margin: 0 11px;
}

.slick-dot-style-1 .slick-dots li button {
  padding: 0;
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  text-indent: 10em;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.17);
}

.slick-dot-style-1 .slick-dots li.slick-active button {
  background-color: #000000;
}

.slick-dot-style-1.slick-dot-mrg1 .slick-dots {
  margin-top: 25px;
}

@media only screen and (max-width: 767px) {
  .slick-dot-style-1.slick-dot-mrg1 .slick-dots {
    margin-top: 15px;
  }
}

.product-collection-area {
  position: relative;
}

.product-collection-area .collection-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 38.8%;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .product-collection-area .collection-img {
    width: 100%;
    position: static;
  }
}

.product-collection-area .collection-img img {
  height: 100%;
  width: 100%;
}

.col-width-61 {
  flex: 0 0 61.2%;
  max-width: 61.2%;
}

@media only screen and (max-width: 767px) {
  .col-width-61 {
    width: 100%;
    max-width: 100%;
  }
}

.collection-wrap {
  margin: 105px 0px 70px 49px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-wrap {
    margin: 50px 0px 44px 49px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-wrap {
    margin: 50px 0px 44px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-wrap {
    margin: 40px 0px 34px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-wrap {
    margin: 50px 0px 44px 18px;
  }
}

.collection-wrap h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 46px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 75px 30px;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-wrap h2 {
    margin: 0 0 30px 18px;
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-wrap h2 {
    margin: 0 0 30px 10px;
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-wrap h2 {
    margin: 0 0 20px 10px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-wrap h2 {
    margin: 0 0 20px 10px;
    font-size: 20px;
    letter-spacing: 1px;
  }
}

.collection-wrap .collection-slider-active .slick-list {
  padding-right: 23.5%;
}

.collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
  margin: 0px 32px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
    margin: 0px 20px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
    margin: 0px 10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
    margin: 0px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
    margin: 0px 10px 0;
  }
}

.collection-wrap .collection-slider-active .slick-list .product-wrap-hm18 {
  opacity: .6;
}

.collection-wrap .collection-slider-active .slick-list .product-wrap-hm18.slick-active {
  opacity: 1;
}

.slick-dot-style-2 .slick-dots {
  margin-top: 55px;
  margin-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slick-dot-style-2 .slick-dots {
    margin-top: 30px;
    margin-left: 6px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-dot-style-2 .slick-dots {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-dot-style-2 .slick-dots {
    margin-top: 30px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-dot-style-2 .slick-dots {
    margin-top: 30px;
    margin-left: 11px;
  }
}

.slick-dot-style-2 .slick-dots li {
  margin: 0 14px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-dot-style-2 .slick-dots li {
    margin: 0 28px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-dot-style-2 .slick-dots li {
    margin: 0 20px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-dot-style-2 .slick-dots li {
    margin: 0 10px 0 0px;
  }
}

.slick-dot-style-2 .slick-dots li button {
  padding: 0;
  border: none;
  width: 160px;
  height: 8px;
  text-indent: 12em;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.37);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-dot-style-2 .slick-dots li button {
    width: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-dot-style-2 .slick-dots li button {
    width: 48px;
  }
}

.slick-dot-style-2 .slick-dots li.slick-active button {
  background-color: white;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bg-product-img {
    background-position: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-product-img {
    background-position: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-product-img {
    background-position: 16%;
  }
}

@media only screen and (max-width: 767px) {
  .bg-product-img {
    background-position: 0%;
  }
  .bow-area.pt-130 {
    padding-top: 60px;
  }
  .deal-area-2 {
    padding: 50px 15px;
  }
  .height-100vh.height-100vh-xs {
    height: auto;
    padding: 80px 0;
  }
}

/*-------- 7. Animation style ---------*/
@keyframes lastudioFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lastudioZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes lastudioFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes lastudioFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

/*-------- 8. Instagram style ---------*/
.instagram-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-wrap {
    margin: 0 -20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-wrap {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap {
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap {
    margin: 0 -15px;
  }
}

.instagram-wrap .instagram-item {
  position: relative;
  flex: 0 0 25%;
  padding: 0 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-wrap .instagram-item {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-wrap .instagram-item {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap .instagram-item {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap .instagram-item {
    padding: 0 22px 40px;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap .instagram-item {
    padding: 0 15px 30px;
    flex: 0 0 100%;
  }
  .instagram-wrap .instagram-item:last-child {
    padding: 0 15px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap .instagram-item {
    padding: 0 15px 30px;
    flex: 0 0 50%;
  }
}

.instagram-wrap .instagram-item > a {
  display: block;
  position: relative;
}

.instagram-wrap .instagram-item > a::before {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.instagram-wrap .instagram-item > a img {
  width: 100%;
}

.instagram-wrap .instagram-item > .instagram-hvr-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  z-index: 99;
}

.instagram-wrap .instagram-item > .instagram-hvr-content span {
  font-size: 20px;
  margin: 0 5px;
  color: #fff;
}

.instagram-wrap .instagram-item > .instagram-hvr-content span i {
  color: #6ec1e4;
  margin-right: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap .instagram-item > .instagram-hvr-content span {
    font-size: 18px;
  }
}

.instagram-wrap .instagram-item:hover > a::before {
  opacity: 1;
  visibility: visible;
}

.instagram-wrap .instagram-item:hover > .instagram-hvr-content {
  opacity: 1;
  visibility: visible;
}

.instagram-wrap.white-instagram .instagram-item > .instagram-hvr-content span i {
  color: #fff;
}

.instagram-wrap-2 {
  display: flex;
}

.instagram-wrap-3 {
  display: flex;
  flex-wrap: wrap;
}

.instagram-wrap-3 li {
  position: relative;
  flex: 0 0 20%;
  list-style: none;
}

.instagram-wrap-3 li > a {
  display: block;
  position: relative;
}

.instagram-wrap-3 li > a::before {
  background-color: rgba(84, 89, 95, 0.6);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.instagram-wrap-3 li > a img {
  width: 100%;
}

.instagram-wrap-3 li:hover > a::before {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-3 li {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-3 li {
    flex: 0 0 33.33%;
  }
}

.instagram-feed-hm18 {
  margin: 0 -10px;
}

.instagram-feed-hm18 .instagram-wrap-4 {
  display: flex;
  flex-wrap: wrap;
}

.instagram-feed-hm18 .instagram-wrap-4 li {
  position: relative;
  flex: 0 0 25%;
  list-style: none;
  padding: 0 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .instagram-feed-hm18 .instagram-wrap-4 li {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-feed-hm18 .instagram-wrap-4 li {
    flex: 0 0 25%;
  }
}

.instagram-feed-hm18 .instagram-wrap-4 li > a {
  display: block;
  position: relative;
}

.instagram-feed-hm18 .instagram-wrap-4 li > a::before {
  background-color: rgba(0, 0, 0, 0.8);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.instagram-feed-hm18 .instagram-wrap-4 li > a img {
  width: 100%;
}

.instagram-feed-hm18 .instagram-wrap-4 li:hover > a::before {
  opacity: 1;
  visibility: visible;
}

.instagram-feed-hm18 .instagram-wrap-4 li .instagram-plus {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.35s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.instagram-feed-hm18 .instagram-wrap-4 li:hover > .instagram-plus {
  opacity: 1;
  visibility: visible;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .instagram-feed-thumb.mt-65 {
    margin-top: 26px;
  }
  .instagram-feed-thumb.mt-55 {
    margin-top: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-area.pb-100 {
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-area.pb-100 {
    padding-bottom: 60px;
  }
}

/*-------- 9. Footer style ---------*/
.footer-column {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-column.footer-width-32 {
  flex: 0 0 32%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-column.footer-width-32 {
    flex: 0 0 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-column.footer-width-32 {
    flex: 0 0 23%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-32 {
    flex: 0 0 23%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-32 {
    flex: 0 0 33.3%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-32 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-32 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-12 {
  flex: 0 0 12%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-column.footer-width-12 {
    flex: 0 0 14%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-column.footer-width-12 {
    flex: 0 0 14%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-12 {
    flex: 0 0 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-12 {
    flex: 0 0 33.3%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-12 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-12 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-31 {
  flex: 0 0 31.996%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-column.footer-width-31 {
    flex: 0 0 34.996%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-column.footer-width-31 {
    flex: 0 0 34.996%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-31 {
    flex: 0 0 28.996%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-31 {
    flex: 0 0 66.7%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-31 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-31 {
    flex: 0 0 100%;
  }
}

.footer-column.footer-width-45 {
  flex: 0 0 45.966%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-45 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-45 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-45 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-45 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-16 {
  flex: 0 0 16%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-16 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-16 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-16 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-16 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-22 {
  flex: 0 0 22.034%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-22 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-22 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-22 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-22 {
    flex: 0 0 50%;
  }
}

.footer-widget .footer-about .footer-logo a img {
  width: 150px;
}

.footer-widget .footer-about .footer-logo.footer-logo-width a img {
  width: 172px;
}

.footer-widget .footer-about .footer-info {
  margin: 40px 0 42px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-about .footer-info {
    margin: 23px 0 25px;
  }
}

.footer-widget .footer-about .footer-info ul li {
  color: #d5d5d5;
  font-size: 12px;
  display: block;
  line-height: 1.84;
  padding-bottom: 15px;
}

.footer-widget .footer-about .footer-info ul li:last-child {
  padding-bottom: 0px;
}

.footer-widget .footer-about .footer-info ul li a {
  color: #d5d5d5;
}

.footer-widget .footer-about .footer-info ul li a:hover {
  color: #e77878;
}

.footer-widget .footer-about .footer-info.footer-info-mrg-none {
  margin: -5px 0 17px;
}

.footer-widget .footer-about .footer-social ul li {
  display: inline-block;
  margin-right: 15px;
}

.footer-widget .footer-about .footer-social ul li:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-about .footer-social ul li {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-about .footer-social ul li {
    margin-right: 8px;
  }
}

.footer-widget .footer-about .footer-social ul li a {
  font-size: 18px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(255, 255, 255, 0.14);
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  color: #777777;
  line-height: 1;
  transition: all .5s;
  width: 38px;
  height: 38px;
}

.footer-widget .footer-about .footer-social ul li a i {
  line-height: 38px;
}

.footer-widget .footer-about .footer-social ul li a:hover {
  color: #fff;
}

.footer-widget .footer-about .footer-social ul li a.facebook:hover {
  background-color: #3b5998;
}

.footer-widget .footer-about .footer-social ul li a.twitter:hover {
  background-color: #1da1f2;
}

.footer-widget .footer-about .footer-social ul li a.youtube:hover {
  background-color: #cd201f;
}

.footer-widget .footer-about .footer-social ul li a.dribbble:hover {
  background-color: #ea4c89;
}

.footer-widget .footer-about .footer-social.footer-social-dec ul li a {
  border: none;
  width: 32px;
  height: 32px;
  font-size: 16px;
}

.footer-widget .footer-about .footer-social.footer-social-dec ul li a i {
  line-height: 32px;
}

.footer-widget .footer-title {
  margin-bottom: 30px;
}

.footer-widget .footer-title h3 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #fff;
  margin: 0;
}

.footer-widget .footer-list ul li {
  display: block;
  margin-bottom: 23px;
}

.footer-widget .footer-list ul li:last-child {
  margin-bottom: 0px;
}

.footer-widget .footer-list ul li a {
  color: #d5d5d5;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  display: block;
}

.footer-widget .footer-list ul li a:hover {
  color: #e77878;
}

.footer-widget .footer-list.avocado-green-color ul li a:hover {
  color: #bdbd74;
}

.footer-widget.subscribe-right {
  width: 370px;
  max-width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.subscribe-right {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.subscribe-right {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.subscribe-right {
    width: 100%;
  }
}

.footer-widget.subscribe-right .subscribe-form .mc-form input {
  font-size: 12px;
  font-weight: 300;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(255, 255, 255, 0.13);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.01);
  height: 60px;
  padding: 2px 30px;
  margin-bottom: 13px;
  color: #777777;
}

.footer-widget.subscribe-right .subscribe-form .mc-form .mc-news {
  display: none;
}

.footer-widget.subscribe-right .subscribe-form .mc-form .clear input {
  font-size: 14px;
  font-weight: 500;
  height: 55px;
  padding: 2px 30px;
  margin-bottom: 0px;
  background-color: #e77878;
  text-align: center;
  color: #fff;
}

.footer-widget.subscribe-right .subscribe-form .mc-form .clear input:hover {
  background-color: #c43e3e;
}

.footer-widget.subscribe-right-2 {
  width: 450px;
  max-width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-widget.subscribe-right-2 {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget.subscribe-right-2 {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.subscribe-right-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.subscribe-right-2 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.subscribe-right-2 {
    width: 100%;
  }
}

.footer-about-center {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .footer-about-center {
    align-items: inherit;
  }
}

.copyright p {
  color: #848484;
  font-weight: 300;
  font-size: 11px;
}

.footer-hm17-mrg {
  margin: 0 80px 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-hm17-mrg {
    margin: 0 30px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-hm17-mrg {
    margin: 0 45px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-hm17-mrg {
    margin: 0 15px 50px;
  }
}

.footer-hm17-mrg .footer-hm17-wrap {
  padding: 0 292px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-hm17-mrg .footer-hm17-wrap {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-hm17-mrg .footer-hm17-wrap {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-hm17-mrg .footer-hm17-wrap {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-hm17-mrg .footer-hm17-wrap {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-hm17-mrg .footer-hm17-wrap {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area.pt-70 {
    padding-top: 50px;
  }
  .copyright.pt-25 {
    padding-top: 0px;
  }
}

/*-------- 10. Others style ---------*/
.support-lists {
  position: fixed;
  top: 70%;
  right: 50px;
  list-style: none;
  width: 60px;
  background: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .support-lists {
    right: 38px;
    width: 40px;
    top: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .support-lists {
    right: 15px;
    width: 50px;
    top: 75%;
    display: none;
  }
}

.support-lists ul li {
  display: block;
}

.support-lists ul li a {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  color: #777777;
}

.support-lists ul li a:hover {
  color: #e77878;
}

.view-demo-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  z-index: 9999;
}

.view-demo-area > a {
  color: #fff;
  background: #282828;
  font-size: 14px;
  line-height: 18px;
  border: none;
  width: 76px;
  height: 76px;
  text-align: left;
  padding: 20px 10px 10px;
  position: absolute;
  right: 420px;
  top: 60%;
  transition: all 400ms ease-out;
  transform: translate(420px, 0);
}

@media only screen and (max-width: 767px) {
  .view-demo-area > a {
    right: 200px;
    transform: translate(200px, 0);
    font-size: 12px;
    width: 60px;
    height: 60px;
    padding: 10px 10px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area > a {
    right: 400px;
    transform: translate(400px, 0);
  }
}

.view-demo-area .view-demo-aside-active {
  position: absolute;
  width: 420px;
  background: #fff;
  top: 0;
  right: 0;
  height: 100%;
  padding: 50px 30px;
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.13);
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(420px, 0);
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(200px, 0);
    width: 200px;
    padding: 40px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(400px, 0);
    width: 400px;
  }
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list {
  margin-bottom: 34px;
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a {
  color: #282828;
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding: 0 0 3px;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a {
    margin-bottom: 10px;
  }
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a:last-child {
  margin-right: 0px;
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a.active {
  border-bottom: 2px solid #fc4f4f;
}

.view-demo-area.demo-visible > a {
  transform: translate(0px, 0);
}

.view-demo-area.demo-visible .view-demo-aside-active {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.demo-item .demo-item-img {
  position: relative;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .demo-item .demo-item-img {
    margin: 0 0 15px;
  }
}

.demo-item .demo-item-img::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all .3s;
  background: #FC4F4F;
  opacity: 0;
}

.demo-item .demo-item-img a {
  display: block;
  box-shadow: 0 0 5px 0 #ccc;
}

.demo-item .demo-item-img a img {
  width: 100%;
}

.demo-item .demo-item-img a span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  visibility: hidden;
  font-size: 13px;
  color: #fff;
  transition: all .3s ease 0s;
}

.demo-item .demo-item-content h4 {
  font-size: 13px;
  margin: 0;
  color: #282828;
}

.demo-item .demo-item-content h4 a {
  color: #282828;
}

.demo-item:hover .demo-item-img::before {
  opacity: 0.9;
}

.demo-item:hover .demo-item-img a span {
  opacity: 1;
  visibility: visible;
}

.discover-more-btn a {
  display: inline-block;
  color: #fff;
  background-color: #e77878;
  line-height: 1;
  padding: 15px 30px;
}

.discover-more-btn a:hover {
  background-color: #262626;
}

.popup-wrapper-area {
  background: rgba(51, 51, 51, 0.9) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

.popup-wrapper-area .popup-wrapper {
  background: #fff none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 206px;
  width: 1070px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .popup-wrapper-area .popup-wrapper {
    top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper {
    top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper {
    top: 50px;
    width: 940px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper {
    top: 50px;
    width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper {
    top: 50px;
    width: 290px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off {
  position: absolute;
  right: -18px;
  top: -6px;
  width: 54px;
  height: 54px;
  background-color: #292929;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    right: 0px;
    top: -15px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off i {
  line-height: 54px;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper span.popup-off i {
    line-height: 35px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off:hover {
  color: #e77878;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all {
  display: flex;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
  flex: 0 0 64%;
  padding: 56px 80px 33px 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
    padding: 30px 30px 33px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
    padding: 15px 15px 15px 15px;
    flex: 0 0 100%;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area span {
  color: #8a8a8a;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
  font-family: "Alegreya", serif;
  font-size: 46px;
  line-height: 1em;
  margin: 8px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 20px;
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 15px;
    font-size: 20px;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input {
  border: 1px solid #e0e0e8;
  height: auto;
  padding: 20px 20px 20px 20px;
  font-size: 12px;
  color: #777;
  background-color: transparent;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .mc-news {
  display: none;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
    margin-top: 15px;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input {
  width: auto;
  height: auto;
  color: #fff;
  background-color: #262626;
  padding: 15px 40px 16px 40px;
  font-size: 16px;
  text-transform: capitalize;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input:hover {
  background-color: #fc4f4f;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show {
  display: flex;
  align-items: center;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show input {
  width: auto;
  height: auto;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show label {
  color: #777;
  display: block;
  margin: 0 0 0 5px;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image {
  flex: 0 0 36%;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image {
    display: none;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image img {
  width: 100%;
}

.modal-dialog {
  margin: 12% auto 8%;
  max-width: 1358px;
  width: 1358px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .modal-dialog {
    width: 1300px;
    max-width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog {
    width: 1150px;
    max-width: 1150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -13px;
  top: -31px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -10px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 54px;
  height: 54px;
  line-height: 54px;
  background-color: #292929;
  border-radius: 100%;
  font-size: 18px;
  text-shadow: none;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #e77878;
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
  overflow-y: auto;
  max-height: 462px;
  height: 462px;
}

.quickview-content {
  padding: 42px 35px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content {
    padding: 15px 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content {
    padding: 20px 35px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content {
    padding: 15px 15px 15px;
  }
}

.quickview-content h2 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  margin: 0 0 2px;
}

@media only screen and (max-width: 767px) {
  .quickview-content h2 {
    font-size: 26px;
  }
}

.quickview-content .quickview-ratting-review {
  display: flex;
  align-items: center;
}

.quickview-content .quickview-ratting-review .quickview-ratting-wrap {
  display: flex;
}

.quickview-content .quickview-ratting-review .quickview-ratting-wrap .quickview-ratting i {
  color: #d5d6d8;
  font-size: 12px;
}

.quickview-content .quickview-ratting-review .quickview-ratting-wrap .quickview-ratting i.yellow {
  color: #f5b223;
}

.quickview-content .quickview-ratting-review .quickview-ratting-wrap a {
  color: #262626;
  font-size: 10px;
  margin-left: 5px;
}

.quickview-content .quickview-ratting-review .quickview-stock {
  margin-left: 24px;
}

.quickview-content .quickview-ratting-review .quickview-stock span {
  font-size: 10px;
}

.quickview-content .quickview-ratting-review .quickview-stock span i {
  font-size: 16px;
  color: #e77878;
  margin-right: 2px;
  position: relative;
  top: 3px;
}

.quickview-content > h3 {
  font-size: 24px;
  margin: 30px 0 18px;
}

.quickview-content > h3 span {
  text-decoration: line-through;
  font-size: 14px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content > h3 {
    margin: 20px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content > h3 {
    margin: 10px 0 10px;
  }
}

.quickview-content .quickview-peragraph p {
  font-size: 12px;
  line-height: 29px;
}

.quickview-content .quickview-peragraph ul {
  margin: 30px 0 0;
}

.quickview-content .quickview-peragraph ul li {
  font-size: 12px;
  line-height: 1;
  position: relative;
  padding-left: 12px;
  margin: 0 0 29px;
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-peragraph ul li {
    line-height: 24px;
    margin: 0 0 15px;
  }
}

.quickview-content .quickview-peragraph ul li:last-child {
  margin: 0 0 0px;
}

.quickview-content .quickview-peragraph ul li::before {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #e77878;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.quickview-content .quickview-action-wrap {
  display: flex;
  align-items: center;
  margin: 80px 0 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content .quickview-action-wrap {
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content .quickview-action-wrap {
    margin: 30px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap {
    margin: 20px 0 20px;
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-content .quickview-action-wrap {
    display: flex;
  }
}

.quickview-content .quickview-action-wrap .quickview-cart {
  margin: 0 26px 0 10px;
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-cart {
    margin: 0 26px 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-cart {
    margin: 0 26px 0 10px;
  }
}

.quickview-content .quickview-action-wrap .quickview-cart a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  padding: 20px 45px;
  background-color: #262626;
}

.quickview-content .quickview-action-wrap .quickview-cart a:hover {
  background-color: #e77878;
}

.quickview-content .quickview-action-wrap .quickview-cart.quickview-cart-mrg-none {
  margin: 0 26px 0 0px;
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-cart.quickview-cart-mrg-none {
    margin: 0 26px 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-cart.quickview-cart-mrg-none {
    margin: 0 26px 0 10px;
  }
}

.quickview-content .quickview-action-wrap .affiliate-btn {
  margin: 0 26px 0 0px;
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .affiliate-btn {
    margin: 0 26px 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .affiliate-btn {
    margin: 0 26px 0 0px;
  }
}

.quickview-content .quickview-action-wrap .affiliate-btn a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  padding: 20px 45px;
  background-color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content .quickview-action-wrap .affiliate-btn a {
    padding: 20px 26px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .affiliate-btn a {
    padding: 20px 30px;
  }
}

.quickview-content .quickview-action-wrap .affiliate-btn a:hover {
  background-color: #e77878;
}

.quickview-content .quickview-action-wrap .quickview-wishlist a, .quickview-content .quickview-action-wrap .quickview-compare a {
  font-size: 20px;
  font-weight: bold;
}

.quickview-content .quickview-action-wrap .quickview-compare {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-compare {
    margin-left: 0px;
    margin-top: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-content .quickview-action-wrap .quickview-compare {
    margin-left: 30px;
    margin-top: 0px;
  }
}

.quickview-content .quickview-meta {
  margin: 0 0 15px;
}

.quickview-content .quickview-meta > span {
  display: block;
  font-size: 12px;
  margin: 0 0 11px;
}

.quickview-content .quickview-meta > span a:hover {
  text-decoration: underline;
}

.quickview-content .quickview-meta > span:last-child {
  margin: 0 0 0px;
}

.quickview-content.product-details-content {
  padding: 0 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content.product-details-content {
    padding: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content.product-details-content {
    padding: 30px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content {
    padding: 30px 0 0 0px;
  }
}

.quickview-content.product-details-content h2 {
  margin: 0 0 13px;
}

.quickview-content.product-details-content h3 {
  margin: 30px 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content.product-details-content h3 {
    margin: 10px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content.product-details-content h3 {
    margin: 10px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content h3 {
    margin: 10px 0 12px;
  }
}

.quickview-content.product-details-content .quickview-action-wrap {
  margin: 60px 0 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content.product-details-content .quickview-action-wrap {
    margin: 20px 0 20px;
  }
  .quickview-content.product-details-content .quickview-action-wrap .quickview-cart a {
    padding: 20px 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content.product-details-content .quickview-action-wrap {
    margin: 20px 0 20px;
  }
  .quickview-content.product-details-content .quickview-action-wrap .quickview-cart a {
    padding: 20px 18px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content .quickview-action-wrap {
    margin: 20px 0 20px;
  }
  .quickview-content.product-details-content .quickview-action-wrap .quickview-cart a {
    padding: 12px 18px;
  }
}

.quickview-content.product-details-content .quickview-action-wrap.configurable-mrg-dec {
  margin: 37px 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content.product-details-content .quickview-action-wrap.configurable-mrg-dec {
    margin: 25px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content .quickview-action-wrap.configurable-mrg-dec {
    margin: 20px 0 15px;
  }
}

.quickview-content.product-details-content .quickview-action-wrap.quickview-action-mrg-dec {
  margin: 30px 0 38px;
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content .quickview-action-wrap.quickview-action-mrg-dec {
    margin: 20px 10px 20px;
  }
}

.quickview-content.product-details-content .quickview-meta {
  margin: 0 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content.product-details-content .quickview-meta {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content.product-details-content .quickview-meta {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content .quickview-meta {
    margin: 0 0 20px;
  }
}

.quickview-content.product-details-content.product-details-slider2 {
  padding: 0;
  margin: 85px 10px 0;
}

@media only screen and (max-width: 767px) {
  .quickview-content.product-details-content.product-details-slider2 {
    margin: 20px 10px 0;
  }
}

.quickview-quality {
  margin: 7px 0 0;
}

.quickview-quality .cart-plus-minus {
  display: inline-block;
  height: 56px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100px;
  text-align: center;
}

.quickview-quality .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.quickview-quality .cart-plus-minus .qtybutton.dec {
  height: 54px;
  top: 17px;
  left: 4px;
  font-weight: 600;
  font-size: 20px;
}

.quickview-quality .cart-plus-minus .qtybutton.inc {
  height: 54px;
  top: 18px;
  right: 4px;
  font-weight: 600;
  font-size: 20px;
}

.quickview-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #262626;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 100px;
  font-weight: 600;
}

.quickview-quality.quality-height-dec .cart-plus-minus {
  height: 45px;
}

.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
}

.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
}

.quickview-quality.quality-height-dec .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus {
  height: 45px;
  margin-left: -15px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
  font-weight: 500;
  left: 22px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
  font-weight: 500;
  right: 22px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
  width: 80px;
  font-weight: 400;
}

.default-social ul li {
  display: inline-block;
  margin-right: 5px;
}

.default-social ul li:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .default-social ul li {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-social ul li {
    margin-right: 8px;
  }
}

.default-social ul li a {
  font-size: 16px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e1e1e1;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  color: #262626;
  transition: all .5s;
  width: 38px;
  height: 38px;
}

.default-social ul li a i {
  line-height: 37px;
}

.default-social ul li a:hover {
  color: #fff;
}

.default-social ul li a.facebook:hover {
  background-color: #3b5998;
}

.default-social ul li a.twitter:hover {
  background-color: #1da1f2;
}

.default-social ul li a.youtube:hover {
  background-color: #cd201f;
}

.default-social ul li a.dribbble:hover {
  background-color: #ea4c89;
}

.quickview-slider-active.owl-carousel > .owl-nav div {
  color: #000;
  display: inline-block;
  font-size: 18px;
  left: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
}

.quickview-slider-active.owl-carousel > .owl-nav div.owl-next {
  right: 20px;
  left: auto;
}

.quickview-slider-active.owl-carousel > .owl-nav div:hover {
  color: #e77878;
}

.main-sidebar-right-2 {
  margin-left: 390px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right-2 {
    margin-left: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right-2 {
    margin-left: 260px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-2 {
    margin-left: 0px;
  }
}

.box-wrapper {
  width: 1270px;
  margin: 0 auto;
  background-color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .box-wrapper {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-wrapper {
    width: 930px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-wrapper {
    width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .box-wrapper {
    width: 100%;
  }
}

.deal-parallax-img {
  background-image: url("../../assets/images/banner/deal-prallax-01.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.deal-parallax-img .deal-content {
  position: relative;
  z-index: 99;
}

.deal-parallax-img .deal-content > span {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 8px;
  color: #fff;
  display: block;
  line-height: 1;
}

.deal-parallax-img .deal-content h2 {
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 84px;
  margin: 3px 0 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-parallax-img .deal-content h2 {
    line-height: 65px;
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-parallax-img .deal-content h2 {
    line-height: 50px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-parallax-img .deal-content h2 {
    line-height: 30px;
    font-size: 18px;
    margin: 15px 0 20px;
  }
}

.deal-parallax-img .deal-content .timer-style-1 {
  margin: 0 0 33px;
}

.deal-parallax-img .deal-content .timer-style-1 div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.deal-parallax-img .deal-content .timer-style-1 div > span {
  color: #fff;
  font-weight: 400;
  text-align: left;
  margin-right: 54px;
}

@media only screen and (max-width: 767px) {
  .deal-parallax-img .deal-content .timer-style-1 div > span {
    margin-right: 20px;
  }
}

.deal-parallax-img .deal-content .timer-style-1 div > span:last-child {
  margin-right: 0px;
}

.deal-parallax-img .deal-content .timer-style-1 div > span span {
  font-size: 24px;
}

.deal-parallax-img .deal-content .timer-style-1 div > span p {
  font-size: 10px;
  font-weight: 400;
  margin: 0px 0 0;
  line-height: 1;
  color: #fff;
}

.deal-area-2 {
  background-color: transparent;
  background-image: linear-gradient(90deg, #292933 3%, #4033a4 80%);
}

.deal-content-2 {
  text-align: center;
  width: 100%;
}

.deal-content-2 h2 {
  color: #fff;
  margin: 0;
  font-size: 46px;
  font-weight: 700;
  font-style: italic;
  text-shadow: 5px 5px 2px #262626;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-2 h2 {
    display: block;
    font-size: 22px;
  }
}

.deal-content-2 h2 > span {
  font-size: 24px;
  text-shadow: none;
  font-style: normal;
  margin: 4px 8px 0px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2 h2 > span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 h2 > span {
    font-size: 15px;
    margin: 0px 8px 0px 0;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-2 h2 > span {
    font-size: 15px;
    margin: 0px 8px 0px 0;
  }
}

.deal-img-2 {
  margin: 0 4px;
}

@media only screen and (max-width: 767px) {
  .deal-img-2 {
    margin: 20px 0px;
  }
}

.deal-img-2 img {
  width: 100%;
}

.header-bg {
  background-image: url(../../assets/images/banner/header-bg.jpg);
  background-position: top center !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-bg {
    background-image: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-bg {
    background-image: none;
  }
}

@media only screen and (max-width: 767px) {
  .deal-parallax-img.pt-70 {
    padding-top: 50px;
  }
  .deal-parallax-img.pb-70 {
    padding-bottom: 50px;
  }
}

.video-parallax-img {
  background-image: url("../../assets/images/banner/video-parallax.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% calc(60% + 115.1px);
  background-repeat: no-repeat;
  padding: 218px 0 205px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-parallax-img {
    padding: 143px 0 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-parallax-img {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-parallax-img {
    padding: 70px 0 70px;
  }
}

.video-parallax-img h2 {
  font-family: "Caveat", cursive;
  font-size: 110px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-parallax-img h2 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .video-parallax-img h2 {
    font-size: 40px;
  }
}

.video-btn {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: all .5s;
}

.video-btn::before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.video-btn a {
  display: block;
  z-index: 99;
  position: relative;
}

.video-btn a img {
  width: 102px;
}

.video-btn.video-btn-2 a img {
  width: 107px;
}

.video-btn:hover::before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

.video-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin: -10px;
  border-radius: 50%;
}

.video-parallax-img-2 {
  background-image: url("../../assets/images/banner/video-parallax-2.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% calc(60% + 115.1px);
  background-repeat: no-repeat;
  padding: 246px 0 246px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-parallax-img-2 {
    padding: 250px 0 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-parallax-img-2 {
    padding: 200px 0 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-parallax-img-2 {
    padding: 150px 0 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-parallax-img-2 {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-parallax-img-2 {
    padding: 70px 0 70px;
  }
}

.video-parallax-img-2 h3 {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  margin: 45px 0 0px;
  letter-spacing: 3px;
}

.main-sidebar-right-hm1 {
  margin-left: 390px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right-hm1 {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-hm1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-hm1 {
    margin-left: 0px;
  }
}

.error-area {
  height: 100vh;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .error-area {
    height: auto;
    padding: 50px 0;
  }
}

.error-content h2 {
  font-weight: 700;
  font-size: 150px;
  color: #e77878;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h2 {
    font-size: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h2 {
    font-size: 100px;
  }
}

.error-content h3 {
  font-weight: 700;
  font-size: 46px;
  margin: 45px 0 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h3 {
    margin: 25px 0 16px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h3 {
    margin: 15px 0 16px;
    font-size: 22px;
  }
}

.error-content p {
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 50px;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .error-content p {
    margin: 0 0 30px;
  }
}

.comming-soon-area {
  background-image: url("../../assets/images/banner/bg-comming-soon.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.comming-soon-area .comming-soon-align {
  height: 100%;
}

.comming-soon-area .comming-soon-align .comming-soon-content {
  text-align: center;
  margin: 0 0 0 -55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-area .comming-soon-align .comming-soon-content {
    margin: 0 0 0px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-area .comming-soon-align .comming-soon-content {
    margin: 0 0 0px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-area .comming-soon-align .comming-soon-content {
    margin: 0 0 0px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-area .comming-soon-align .comming-soon-content {
    margin: 0 15px 0px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content {
    margin: 0 15px 0px 15px;
    padding: 37px 0 50px;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content h1 {
  font-family: "Playfair Display", serif;
  font-size: 100px;
  font-weight: 400;
  font-style: italic;
  letter-spacing: -3px;
  color: #fff;
  line-height: 142px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-area .comming-soon-align .comming-soon-content h1 {
    line-height: 100px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-area .comming-soon-align .comming-soon-content h1 {
    line-height: 100px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-area .comming-soon-align .comming-soon-content h1 {
    line-height: 80px;
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-area .comming-soon-align .comming-soon-content h1 {
    line-height: 70px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content h1 {
    line-height: 40px;
    font-size: 30px;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
  margin: 43px 0 46px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
    margin: 23px 0 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
    margin: 23px 0 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
    margin: 23px 0 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
    margin: 23px 0 26px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text {
    margin: 23px 0 26px;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content .soon-text h3 {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 5.3px;
  color: #fff;
  display: inline-block;
  border-bottom: 3px solid rgba(255, 255, 255, 0.22);
  border-top: 3px solid rgba(255, 255, 255, 0.22);
  padding: 28px 0 35px;
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .soon-text h3 {
    font-size: 25px;
    letter-spacing: 0px;
    padding: 18px 0 25px;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 {
  margin: 0 0 57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 {
    margin: 0 0 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 {
    margin: 0 0 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 {
    margin: 0 0 27px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 {
    margin: 0 0 20px;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 div span {
  display: inline-block;
  color: #fff;
  margin: 0 17px 0;
}

@media only screen and (max-width: 767px) {
  .comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 div span {
    margin: 0 6px 0;
  }
}

.comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 div span > span {
  font-size: 32px;
  font-weight: 500;
}

.comming-soon-area .comming-soon-align .comming-soon-content .timer-style-2 div span > p {
  color: #fff;
}

/*-------- 11. Subscribe style ---------*/
.subscribe-wrap-3 h2 {
  font-size: 36px;
  letter-spacing: 4px;
  line-height: 1;
  font-family: "Playfair Display", serif;
  margin: 0 0 16px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-3 h2 {
    font-size: 24px;
    letter-spacing: 0px;
    margin: 0 0 10px;
  }
}

.subscribe-wrap-3 p {
  font-size: 16px;
  color: #777777;
  font-weight: 700;
  margin: 0;
}

.subscribe-wrap-3 .subscribe-form-3 {
  margin: 35px 10px 0;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-3 .subscribe-form-3 {
    margin: 20px 10px 0;
  }
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 {
  position: relative;
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e0e0e8;
  height: 38px;
  font-size: 12px;
  color: #777777;
  padding: 2px 70px 2px 0;
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 .mc-news {
  display: none;
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 .clear-3 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 .clear-3 input {
  width: auto;
  padding: 0;
  height: auto;
  border: none;
  color: #262626;
}

.subscribe-wrap-3 .subscribe-form-3 .mc-form-3 .clear-3 input:hover {
  color: #e77878;
}

.subscribe-wrap-3.scrb-font-width-dec p {
  font-weight: 400;
}

.subscribe-wrap-3.scrb-font-width-dec2 h2 {
  font-size: 30px;
  letter-spacing: 0px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-3.scrb-font-width-dec2 h2 {
    font-size: 23px;
  }
}

.subscribe-wrap-3.scrb-font-width-dec2 p {
  font-weight: 400;
}

.subscribe-wrap-3.white-subscribe-wrap-3 h2 {
  color: #fff;
}

.subscribe-wrap-3.white-subscribe-wrap-3 p {
  color: #fff;
}

.subscribe-wrap-3.white-subscribe-wrap-3 .subscribe-form-3 .mc-form-3 input {
  border-bottom: 1px solid #fff;
  color: #fff;
}

.subscribe-wrap-3.white-subscribe-wrap-3 .subscribe-form-3 .mc-form-3 input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-wrap-3.white-subscribe-wrap-3 .subscribe-form-3 .mc-form-3 .clear-3 input {
  color: #fff;
  border: none;
}

.subscribe-wrap-3.scrb-hm14 img {
  width: 34px;
}

.subscribe-wrap-3.scrb-hm14 h2 {
  font-family: "Dancing Script", cursive;
  letter-spacing: 0px;
  margin: 9px 0 7px;
}

.subscribe-wrap-3.scrb-hm14 p {
  font-weight: 300;
}

.subscribe-wrap-3.scrb-hm14 .subscribe-form-3 {
  margin: 40px 10px 0;
}

.subscribe-wrap-3.scrb-hm14 .subscribe-form-3 .mc-form-3 input {
  border-bottom: 1px solid #000;
  font-weight: 300;
}

.subscribe-wrap-3.scrb-hm14 .subscribe-form-3 .mc-form-3 .clear-3 input {
  border-bottom: none;
}

.subscribe-wrap-3.scrb-hm15 h2 {
  font-family: "Dancing Script", cursive;
  letter-spacing: 0px;
  margin: 0px 0 18px;
  font-size: 46px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-3.scrb-hm15 h2 {
    font-size: 35px;
  }
}

.subscribe-wrap-3.scrb-hm15 p {
  font-weight: 400;
}

.subscribe-wrap-3.scrb-hm15 .subscribe-form-3 {
  margin: 0px 10px 0;
}

.subscribe-wrap-3.scrb-hm15 .subscribe-form-3 .mc-form-3 input {
  border-bottom: 1px solid #000;
  font-weight: 300;
}

.subscribe-wrap-3.scrb-hm15 .subscribe-form-3 .mc-form-3 .clear-3 input {
  border-bottom: none;
  font-weight: 400;
}

.subscribe-wrap-3.scrb-hm17 h2 {
  font-family: "Caveat", cursive;
  letter-spacing: 0px;
  margin: 0px 0 13px;
  font-size: 66px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-3.scrb-hm17 h2 {
    font-size: 35px;
  }
}

.subscribe-wrap-3.scrb-hm17 p {
  font-weight: 400;
}

.subscribe-wrap-3.scrb-hm17 .subscribe-form-3 {
  margin: 40px 10px 0;
}

.subscribe-parallax-bg {
  background-image: url("../../assets/images/banner/subscribe-banner.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.subscribe-bg {
  background-color: #d5ecf1;
  background-image: url("../../assets/images/banner/newletter-bg15.png");
  background-position: top right;
  background-repeat: repeat-x;
}

.subscribe-wrap-4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 {
    display: block;
    text-align: center;
  }
}

.subscribe-wrap-4 .subscribe-content-4 {
  flex: 0 0 39.412%;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 .subscribe-content-4 {
    flex: 0 0 100%;
  }
}

.subscribe-wrap-4 .subscribe-content-4 h2 {
  font-size: 36px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-wrap-4 .subscribe-content-4 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-wrap-4 .subscribe-content-4 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 .subscribe-content-4 h2 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

.subscribe-wrap-4 .subscribe-form-4 {
  flex: 0 0 60.588%;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 .subscribe-form-4 {
    flex: 0 0 100%;
  }
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 {
  position: relative;
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 input {
  color: #262626;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #262626;
  height: 76px;
  padding: 5px 130px 5px 30px;
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 input::-webkit-input-placeholder {
  color: #262626 !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 .subscribe-form-4 .mc-form-4 input {
    padding: 5px 103px 5px 15px;
    height: 70px;
  }
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 .mc-news {
  display: none;
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 .clear-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap-4 .subscribe-form-4 .mc-form-4 .clear-4 {
    right: 10px;
  }
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 .clear-4 input {
  width: auto;
  border: none;
  padding: 10px;
}

.subscribe-wrap-4 .subscribe-form-4 .mc-form-4 .clear-4 input:hover {
  color: #e77878;
}

.subscribe-wrap-4.subscribe-hm18 .subscribe-content-4 h2 {
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-wrap-4.subscribe-hm18 .subscribe-content-4 h2 {
    letter-spacing: 0px;
  }
}

.subscribe-wrap-4.subscribe-hm18 .subscribe-form-4 .mc-form-4 input {
  font-family: "Roboto Condensed", sans-serif;
  padding: 5px 130px 5px 20px;
}

.subscribe-wrap-4.subscribe-hm18 .subscribe-form-4 .mc-form-4 .clear-4 {
  right: 20px;
}

.subscribe-wrap-4.subscribe-hm18 .subscribe-form-4 .mc-form-4 .clear-4 input {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  padding: 10px;
}

.subscribe-wrap-4.subscribe-hm18 .subscribe-form-4 .mc-form-4 .clear-4 input:hover {
  color: #666;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-area.pt-190 {
    padding-top: 115px;
  }
  .subscribe-area.pb-200 {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-area.pt-190 {
    padding-top: 115px;
  }
  .subscribe-area.pb-200 {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pt-190 {
    padding-top: 55px;
  }
  .subscribe-area.pt-70 {
    padding-top: 20px;
  }
  .subscribe-area.pb-200 {
    padding-bottom: 60px;
  }
  .subscribe-area-4.pt-70 {
    padding-top: 45px;
  }
  .subscribe-area-4.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 12. Brand logo style ---------*/
.brand-logo-wrap {
  margin: 50px 13px 0;
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap {
    margin: 0px 13px 0;
  }
}

.brand-logo-wrap .row {
  justify-content: center;
}

.brand-logo-wrap .row .single-brand-logo {
  text-align: center;
  opacity: .4;
  transition: all .35s ease 0s;
  padding: 25px 20px;
}

.brand-logo-wrap .row .single-brand-logo a {
  display: block;
}

.brand-logo-wrap .row .single-brand-logo a img {
  width: 100%;
}

.brand-logo-wrap .row .single-brand-logo:hover {
  opacity: 1;
}

.brand-logo-wrap.brand-opacity-none {
  margin: 0px 13px 0;
}

.brand-logo-wrap.brand-opacity-none .row .single-brand-logo {
  opacity: 1;
}

.brand-logo-wrap-3 {
  margin: 56px 13px 0;
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap-3 {
    margin: 30px 0px 0;
  }
}

.brand-logo-wrap-3 .row {
  justify-content: center;
  align-items: center;
}

.brand-logo-wrap-3 .row .single-brand-logo-3 {
  text-align: center;
  padding: 0px 37px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-logo-wrap-3 .row .single-brand-logo-3 {
    padding: 0px 25px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap-3 .row .single-brand-logo-3 {
    padding: 0px 15px 30px;
  }
}

.brand-logo-wrap-3 .row .single-brand-logo-3 a {
  display: block;
}

.brand-logo-wrap-3 .row .single-brand-logo-3 a img {
  width: 100%;
  transition: all .3s ease 0s;
}

.brand-logo-wrap-3 .row .single-brand-logo-3:hover a img {
  transform: scale(1.1);
}

.single-brand-logo-2 {
  text-align: center;
  opacity: .4;
  transition: all .35s ease 0s;
}

.single-brand-logo-2 a img {
  width: 126px;
}

.single-brand-logo-2:hover {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pb-60 {
    padding-bottom: 20px;
  }
  .brand-logo-area.pb-40 {
    padding-bottom: 20px;
  }
  .brand-logo-area.pt-70 {
    padding-top: 50px;
  }
}

/*-------- 13. Banner style ---------*/
.custom-container-2 {
  width: 1180px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-2 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-2 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-2 {
    width: 100%;
  }
}

.banner-wrap {
  overflow: hidden;
}

.banner-wrap a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.banner-wrap .banner-content {
  position: absolute;
  left: 0;
  bottom: 95px;
  right: 0;
  text-align: center;
  z-index: 99;
}

.banner-wrap .banner-content h4 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  font-family: "Playfair Display", serif;
  letter-spacing: 2.5px;
  transition: all .35s ease 0s;
  margin: 0 0 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content h4 {
    font-size: 16px;
    letter-spacing: 1.5px;
  }
}

.banner-wrap .banner-content h2 {
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 400;
  color: #fff;
  font-family: "Playfair Display", serif;
  letter-spacing: 10px;
  line-height: 1em;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content h2 {
    font-size: 35px;
  }
}

.banner-wrap .banner-content-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}

.banner-wrap .banner-content-2 span {
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 span {
    font-size: 16px;
  }
}

.banner-wrap .banner-content-2 h2 {
  font-size: 46px;
  font-weight: 700;
  line-height: 1;
  display: block;
  margin: 13px 0 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 30px;
    margin: 13px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 22px;
    margin: 13px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 20px;
    margin: 7px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    margin: 15px 0 20px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
}

.banner-wrap .banner-content-3 h5 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
  transition: all 0.35s;
}

.banner-wrap .banner-content-3 h2 {
  font-size: 120px;
  color: #fff;
  margin: 27px 0 44px;
  line-height: 90px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 66px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 60px;
    line-height: 48px;
    margin: 20px 0 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 45px;
    line-height: 35px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 40px;
    line-height: 30px;
    margin: 10px 0 20px;
  }
}

.banner-wrap .banner-content-3 .banner-btn a {
  display: inline-block;
  border: 2px solid #fff;
  line-height: 1;
  color: #fff;
  padding: 14px 30px 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 .banner-btn a {
    padding: 10px 30px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 .banner-btn a {
    padding: 10px 30px 12px;
  }
}

.banner-wrap .banner-content-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 80px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 {
    left: 30px;
  }
}

.banner-wrap .banner-content-4 h5 {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin: 0;
}

.banner-wrap .banner-content-4 h2 {
  font-size: 66px;
  color: #fff;
  margin: 33px 0 33px;
  line-height: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 60px;
    line-height: 45px;
    margin: 26px 0 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 45px;
    line-height: 35px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 40px;
    line-height: 30px;
    margin: 10px 0 20px;
  }
}

.banner-wrap .banner-content-4 .banner-btn a {
  display: inline-block;
  border: 2px solid #fff;
  line-height: 1;
  color: #fff;
  padding: 14px 30px 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 .banner-btn a {
    padding: 10px 30px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 .banner-btn a {
    padding: 10px 30px 12px;
  }
}

.banner-wrap .banner-content-5 {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -80%, 0);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.35s, color 0.35s;
}

.banner-wrap .banner-content-5 h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-6 {
  position: absolute;
  left: 50px;
  bottom: 48px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 {
    left: 35px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    left: 20px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    left: 15px;
  }
}

.banner-wrap .banner-content-6 h5 {
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-6 h5 {
    letter-spacing: 0px;
    font-size: 15px;
  }
}

.banner-wrap .banner-content-7 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
  margin: 0;
  z-index: 99;
}

.banner-wrap .banner-content-7 a {
  display: inline-block;
  color: #262626;
  background-color: #fff;
  font-size: 24px;
  font-family: "Dancing Script", cursive;
  padding: 18px 18px 19px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 a {
    padding: 14px 16px 15px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 a {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-8 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 99;
}

.banner-wrap .banner-content-8 h2 {
  font-size: 76px;
  font-weight: 700;
  font-family: "Dancing Script", cursive;
  margin: 0 0 29px;
  color: #fff;
  transition: all 0.35s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 32px;
    margin: 0 0 19px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 40px;
    margin: 0 0 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 26px;
  }
}

.banner-wrap .banner-content-8 a {
  font-size: 11px;
  display: inline-block;
  border: 2px solid #fff;
  line-height: 1;
  color: #fff;
  padding: 14px 45px 17px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 a {
    padding: 10px 30px 13px;
  }
}

.banner-wrap .banner-content-9 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 90px;
  text-align: center;
  z-index: 99;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 {
    bottom: 30px;
  }
}

.banner-wrap .banner-content-9 h3 {
  font-size: 46px;
  font-weight: 700;
  font-family: "Dancing Script", cursive;
  margin: 0px;
  color: #fff;
  transition: all 0.35s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-9 h2 {
  font-size: 86px;
  font-weight: 400;
  font-family: "Dancing Script", cursive;
  margin: 12px 0 58px;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 55px;
    margin: 12px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 40px;
    margin: 12px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 50px;
    margin: 12px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-9 a {
  font-size: 11px;
  display: inline-block;
  border: 2px solid #fff;
  line-height: 1;
  color: #fff;
  padding: 14px 45px 17px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 a {
    padding: 10px 30px 13px;
  }
}

.banner-wrap .banner-content-10 {
  position: absolute;
  left: 80px;
  bottom: 40px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-10 {
    left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-10 {
    left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 {
    left: 15px;
    bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 {
    left: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-10 {
    bottom: 20px;
  }
}

.banner-wrap .banner-content-10 h2 {
  font-family: "Caveat", cursive;
  font-size: 88px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 54px;
  }
}

.banner-wrap .banner-content-10 h3 {
  font-family: "Caveat", cursive;
  font-size: 36px;
  margin: 2px 0 30px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 23px;
    margin: 2px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 24px;
    margin: 2px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 25px;
    margin: 2px 0 18px;
  }
}

.banner-wrap .banner-content-10 a {
  font-family: "Caveat", cursive;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 50px;
  padding: 12px 40px 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 a {
    padding: 6px 30px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 a {
    padding: 6px 15px 10px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 a {
    padding: 8px 25px 12px;
    font-size: 22px;
  }
}

.banner-wrap .banner-content-11 {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-11 {
    top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 {
    top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 {
    top: 15px;
  }
}

.banner-wrap .banner-content-11 h3 {
  font-family: "Caveat", cursive;
  font-size: 32px;
  margin: 0;
  line-height: 1;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h3 {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h3 {
    font-size: 25px;
  }
}

.banner-wrap .banner-content-11 h4 {
  font-family: "Caveat", cursive;
  font-size: 36px;
  margin: 13px 0 30px;
  line-height: 1;
  color: #e77878;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 3px 0 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 3px 0 12px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 3px 0 9px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 8px 0 20px;
  }
}

.banner-wrap .banner-content-11 a {
  font-family: "Caveat", cursive;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 50px;
  padding: 12px 40px 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 a {
    padding: 6px 30px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 a {
    padding: 6px 15px 10px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 a {
    padding: 8px 25px 12px;
    font-size: 22px;
  }
}

.banner-wrap .banner-content-12 {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: transform 0.35s, color 0.35s;
  transform: translate3d(0, -80%, 0);
  z-index: 99;
}

.banner-wrap .banner-content-12 h3 {
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 h3 {
    letter-spacing: 0px;
    font-size: 20px;
  }
}

.banner-wrap .shop-banner-position1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 140px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .shop-banner-position1 {
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .shop-banner-position1 {
    bottom: 30px;
  }
}

.banner-wrap .shop-banner-position2 {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .shop-banner-position2 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .shop-banner-position2 {
    left: 30px;
  }
}

.banner-wrap .shop-banner-position3 {
  position: absolute;
  left: 80px;
  bottom: 105px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .shop-banner-position3 {
    left: 40px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .shop-banner-position3 {
    left: 30px;
    bottom: 30px;
  }
}

.banner-wrap .shop-banner-position4 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.banner-wrap .shop-banner-position5 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .shop-banner-position5 {
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .shop-banner-position5 {
    bottom: 30px;
  }
}

.banner-wrap .shop-banner-position6 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 145px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .shop-banner-position6 {
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .shop-banner-position6 {
    bottom: 30px;
  }
}

.banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
  font-size: 80px;
  margin: 0 0 30px;
  font-family: "Playfair Display", serif;
  color: #fff;
  font-weight: 700;
  line-height: 1.3em;
  transition: all 0.35s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
    font-size: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 h2, .banner-wrap .banner-content-14 h2 {
    font-size: 28px;
    margin: 0 0 20px;
  }
}

.banner-wrap .sb-letter-spacing h2 {
  letter-spacing: 7px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .sb-letter-spacing h2 {
    letter-spacing: 3px;
  }
}

.banner-wrap .banner-content-14 h2 {
  font-size: 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 35px;
  }
}

.banner-wrap .banner-content-15 h2 {
  font-size: 75px;
  margin: 0 0 30px;
  font-family: "Playfair Display", serif;
  color: #fff;
  font-weight: 700;
  line-height: 1.3em;
  transition: all 0.35s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 28px;
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-content-16 h2 {
  font-size: 80px;
  margin: 0 0 30px;
  font-family: "Playfair Display", serif;
  color: #262626;
  font-weight: 700;
  line-height: 1.3em;
  transition: all 0.35s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 34px;
    margin: 0 0 20px;
    line-height: 1.2em;
  }
}

.banner-wrap .shop-banner-btn a {
  display: inline-block;
  line-height: 1;
  background-color: #262626;
  color: #ffffff;
  padding: 20px 40px 20px 40px;
}

.banner-wrap .shop-banner-btn a:hover {
  background-color: #e77878;
}

.banner-wrap.banner-zoom:hover a img {
  transform: scale(1.05);
}

.banner-wrap:hover.banner-overlay-1:before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.banner-wrap:hover.banner-overlay-2:before {
  background-color: #000;
  opacity: .7;
  z-index: 1;
  pointer-events: none;
}

.banner-wrap:hover.banner-overlay-3:before {
  background-color: #000;
  opacity: .5;
  z-index: 1;
  pointer-events: none;
}

.banner-wrap:hover .banner-content h4 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-3 h5 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-8 h2 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-8 a {
  border: 2px solid #e77878;
  background-color: #e77878;
}

.banner-wrap:hover .banner-content-9 h3 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-9 a {
  border: 2px solid #e77878;
  background-color: #e77878;
}

.banner-wrap:hover .banner-content-10 a {
  border: 1px solid #e77878;
  background-color: #e77878;
  color: #fff;
}

.banner-wrap:hover .banner-content-11 h3 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-11 a {
  border: 1px solid #e77878;
  background-color: #e77878;
  color: #fff;
}

.banner-wrap:hover .banner-btn a {
  background-color: #e77878;
  border: 2px solid #e77878;
}

.banner-wrap:hover .banner-content-5 {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, -50%, 0);
}

.banner-wrap:hover:hover .banner-content-12 {
  opacity: 1;
  top: 50%;
  transition: transform 0.35s, color 0.35s;
  transform: translate3d(0, -50%, 0);
}

.banner-wrap:hover .shop-banner-btn a {
  background-color: #e77878;
}

.banner-wrap:hover .sb-letter-spacing h2 {
  letter-spacing: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap.mb-70 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

.banner-action-wrap {
  line-height: 1;
}

.banner-action-wrap a {
  position: relative;
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  margin-right: 1px;
}

.banner-action-wrap a .banner-action-common {
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  padding: 18px 30px 20px 30px;
  border: 1px solid #777;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-action-wrap a .banner-action-common {
    padding: 14px 30px 16px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-action-wrap a .banner-action-common {
    padding: 12px 25px 14px 25px;
  }
}

.banner-action-wrap a .banner-action-common span {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
}

.banner-action-wrap a .banner-action-normal {
  opacity: 1;
  transform: scale(1);
}

.banner-action-wrap a .banner-action-hover {
  opacity: 0;
  transform: scale(1.5);
  background-color: #e77878;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.banner-action-wrap a .banner-action-hover span {
  color: #fff;
}

.banner-action-wrap a:hover > .banner-action-common {
  border: 1px solid #e77878;
}

.banner-action-wrap a:hover > .banner-action-normal {
  opacity: 0;
  transform: scale(0);
}

.banner-action-wrap a:hover > .banner-action-hover {
  opacity: 1;
  transform: scale(1);
}

.custom-container-4 {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-4 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-4 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-4 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-4 {
    width: 100%;
  }
}

.banner-bg-hm14 {
  background-color: #ffe8e8;
  background-image: url("../../assets/images/banner/img-bannerhm14.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 314px auto;
}

.banner-bg-hm14 > span {
  font-size: 32px;
  font-family: "Dancing Script", cursive;
}

@media only screen and (max-width: 767px) {
  .banner-bg-hm14 > span {
    font-size: 30px;
  }
}

.banner-bg-hm14 h2 {
  color: #fe5e5e;
  font-size: 46px;
  font-weight: 700;
  letter-spacing: 7px;
  margin: 10px 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-hm14 h2 {
    letter-spacing: 5px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-hm14 h2 {
    letter-spacing: 2px;
    font-size: 20px;
    margin: 10px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-65 {
    padding-bottom: 20px;
  }
  .banner-area.pt-70 {
    padding-top: 50px;
  }
  .banner-area .mt-55 {
    margin-top: 20px;
  }
}

/*-------- 14. Feature style ---------*/
.custom-container-3 {
  width: 1450px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-3 {
    width: 1267px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-3 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-3 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-3 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-3 {
    width: 100%;
  }
}

.feature-wrap {
  display: flex;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature-wrap {
    justify-content: center;
  }
}

.feature-wrap .feature-icon {
  flex: 0 0 50px;
  line-height: 1;
  margin-right: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-icon {
    margin-right: 15px;
    flex: 0 0 35px;
  }
}

.feature-wrap .feature-icon i {
  color: #e77878;
  font-size: 50px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-icon i {
    font-size: 35px;
  }
}

.feature-wrap .feature-icon.f-icon-roted i {
  transform: rotateY(180deg);
}

.feature-wrap .feature-content h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-content h4 {
    font-size: 18px;
  }
}

.feature-wrap .feature-content span {
  color: #777;
  font-size: 12px;
  font-weight: 300;
  display: block;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .feature-wrap .feature-content span {
    line-height: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap .feature-content span {
    line-height: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap .feature-content span {
    line-height: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-content span {
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap .feature-content span {
    line-height: 20px;
  }
}

.feature-wrap-2 {
  display: flex;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature-wrap-2 {
    justify-content: center;
  }
}

.feature-wrap-2 .feature-icon-2 {
  flex: 0 0 56px;
  margin-right: 24px;
}

.feature-wrap-2 .feature-icon-2 i {
  font-size: 24px;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
  display: inline-block;
  color: #262626;
}

.feature-wrap-2 .feature-content-2 h4 {
  font-size: 16px;
  margin: 0 0 13px;
}

.feature-wrap-2 .feature-content-2 span {
  font-size: 12px;
  color: #777777;
  font-weight: 300;
}

/*-------- 15. Blog style ---------*/
.blog-wrap .blog-img {
  position: relative;
}

.blog-wrap .blog-img a img {
  width: 100%;
}

.blog-wrap .blog-img span {
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1;
}

.blog-wrap .blog-img span a {
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  background-color: #262626;
  line-height: 1;
  padding: 8px 20px 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 10px;
  }
  .blog-wrap .blog-img.mb-30 {
    margin-bottom: 10px;
  }
}

.blog-wrap .blog-img .blog-popup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.blog-wrap .blog-img .blog-popup a img {
  width: 66px;
}

.blog-wrap .blog-img .quote-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.blog-wrap .blog-img .quote-content p {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  max-width: 76%;
  margin: 0 auto;
  font-family: "Playfair Display", serif;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .quote-content p {
    font-size: 16px;
    max-width: 84%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content p {
    font-size: 12px;
    max-width: 95%;
    line-height: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content p {
    font-size: 14px;
    max-width: 95%;
    line-height: 25px;
  }
}

.blog-wrap .blog-img .quote-content .quote-author {
  display: block;
  margin: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content .quote-author {
    margin: 12px 0 0;
  }
}

.blog-wrap .blog-img .quote-content .quote-author span {
  display: inline-block;
  color: #fff;
  font-weight: 600;
  position: relative;
}

.blog-wrap .blog-img .quote-content .quote-author span:before {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  content: "";
  width: 40px;
  height: 2px;
}

.blog-wrap .blog-img .quote-content .quote-author span:after {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  content: "";
  width: 40px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.no-sidebar-code p {
    font-size: 11px;
    max-width: 95%;
    line-height: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.no-sidebar-code p {
    font-size: 14px;
    max-width: 95%;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.no-sidebar-code .quote-author {
    margin: 0px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.no-sidebar-code .quote-author {
    margin: 12px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote p {
    font-size: 14px;
    max-width: 92%;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote p {
    font-size: 15px;
    max-width: 92%;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote p {
    font-size: 14px;
    max-width: 95%;
    line-height: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote p {
    font-size: 16px;
    max-width: 95%;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote .quote-author {
    margin: 10px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .quote-content.blog-col3-quote .quote-author {
    margin: 12px 0 0;
  }
}

.blog-wrap .blog-content h3 {
  font-weight: 600;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 17px;
    line-height: 25px;
  }
}

.blog-wrap .blog-content .blog-meta {
  margin: 11px 0px 34px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content .blog-meta {
    margin: 11px 0px 20px;
  }
}

.blog-wrap .blog-content .blog-meta ul li {
  display: inline-block;
  font-size: 12px;
  color: #777777;
}

.blog-wrap .blog-content .blog-meta ul li:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.blog-wrap .blog-content .blog-meta ul li a {
  color: #777777;
  font-size: 12px;
}

.blog-wrap .blog-content .blog-meta ul li a:hover {
  color: #e77878;
}

.blog-wrap .blog-content .blog-btn a {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 1;
  padding: 15px 40px 15px;
}

.blog-wrap .blog-content .blog-btn a:hover {
  background-color: #e77878;
  color: #fff;
  border: 1px solid #e77878;
}

.blog-wrap .blog-content-2 h3 {
  font-size: 32px;
  font-family: "Dancing Script", cursive;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 25px;
  }
}

.blog-wrap .blog-content-2 p {
  color: #777777;
  margin: 23px auto 30px;
  line-height: 25px;
  width: 90%;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-2 p {
    margin: 10px auto 20px;
  }
}

.blog-wrap .blog-content-2 .blog-btn-2 a {
  display: inline-block;
  line-height: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  padding: 17px 40px 16px 40px;
}

.blog-wrap .blog-content-2 .blog-btn-2 a:hover {
  background-color: #ce869c;
  border: 1px solid #ce869c;
  color: #fff;
}

.blog-wrap .blog-content-3 h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.blog-wrap .blog-content-3 h3 a {
  color: #262626;
}

.blog-wrap .blog-content-3 h3 a:hover {
  color: #bdbd74;
}

.blog-wrap .blog-content-3 .blog-meta-3 {
  margin: 4px 0px 13px;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li {
  display: inline-block;
  font-size: 12px;
  color: #777777;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li:not(:first-child)::before {
  content: "-";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li.second-before:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  color: #535353;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li a {
  color: #777777;
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li a:hover {
  color: #bdbd74;
}

.blog-wrap .blog-content-3 .blog-meta-3 ul li:last-child a {
  color: #bdbd74;
}

.blog-wrap .blog-content-3 p {
  color: #777777;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin: 0;
}

.blog-wrap.blog-modify-1 .blog-content h3 {
  font-weight: 400;
}

.blog-wrap .blog-content-4 .blog-tag a {
  color: #e77878;
  letter-spacing: 1px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0 5px;
  display: block;
}

.blog-wrap .blog-content-4 h3 {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-4 h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4 h3 {
    font-size: 23px;
  }
}

.blog-wrap .blog-content-4 .blog-meta-4 {
  margin: 11px 0px 20px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4 .blog-meta-4 {
    margin: 4px 0px 10px;
  }
}

.blog-wrap .blog-content-4 .blog-meta-4 ul li {
  display: inline-block;
  font-size: 12px;
  color: #777777;
}

.blog-wrap .blog-content-4 .blog-meta-4 ul li:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.blog-wrap .blog-content-4 .blog-meta-4 ul li a {
  color: #777777;
  font-size: 12px;
}

.blog-wrap .blog-content-4 .blog-meta-4 ul li a:hover {
  color: #e77878;
}

.blog-wrap .blog-content-4 p {
  line-height: 1.714286;
  margin: 0 0 28px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4 p {
    margin: 0 0 12px;
  }
}

.blog-wrap .blog-content-4 .blog-btn-3 a {
  display: inline-block;
  color: #fff;
  background-color: #262626;
  padding: 16px 35px;
}

.blog-wrap .blog-content-4 .blog-btn-3 a:hover {
  background-color: #e77878;
}

.blog-wrap .blog-content-4.blog-no-sidebar {
  padding: 50px;
  max-width: 780px;
  margin: -60px auto 0px;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-4.blog-no-sidebar {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4.blog-no-sidebar {
    padding: 20px;
    max-width: 100%;
    margin: 0px auto 0px;
  }
}

.blog-wrap .blog-content-4.blog-no-sidebar .blog-tag a {
  letter-spacing: 0px;
  font-size: 12px;
}

.blog-wrap .blog-content-4.blog-no-sidebar h3 {
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-4.blog-no-sidebar h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4.blog-no-sidebar h3 {
    font-size: 23px;
  }
}

.blog-wrap .blog-content-4.blog-no-sidebar .blog-meta-4 {
  margin: 14px 0px 29px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4.blog-no-sidebar .blog-meta-4 {
    margin: 14px 0px 12px;
  }
}

.blog-wrap .blog-content-4.blog-no-sidebar .blog-meta-4 ul li {
  color: #262626;
}

.blog-wrap .blog-content-4.blog-no-sidebar .blog-meta-4 ul li a {
  color: #262626;
}

.blog-wrap .blog-content-4.blog-no-sidebar .blog-meta-4 ul li a:hover {
  color: #e77878;
}

.blog-wrap .blog-content-4.blog-3col-content h3 {
  line-height: 1.4074;
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

.blog-right-wrap {
  margin-left: 105px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-right-wrap {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-right-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-right-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area .mt-60 {
    margin-top: 25px;
  }
  .blog-area.pb-65 {
    padding-bottom: 20px;
  }
  .blog-sidebar-style {
    margin-top: 40px;
  }
}

.section-padding-9 .container-fluid {
  padding: 0 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-9 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px;
  }
}

.custom-container-9 {
  width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-9 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-9 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-9 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-9 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-9 {
    width: 100%;
  }
}

.custom-container-10 {
  width: 1410px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-10 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-10 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-10 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-10 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-10 {
    width: 100%;
  }
}

.blog-details-top-wrap {
  position: relative;
  float: left;
  margin-right: 55px;
  width: calc(100% - 105px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top-wrap {
    margin-right: 35px;
    width: calc(100% - 85px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap {
    margin-right: 0px;
    width: calc(100% - 0px);
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap {
    margin-right: 0px;
    width: calc(100% - 0px);
  }
}

.blog-details-top-wrap img {
  width: 100%;
}

.blog-details-top-wrap h2 {
  font-size: 48px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  margin: 35px 0 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top-wrap h2 {
    margin: 25px 0 24px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap h2 {
    margin: 15px 0 10px;
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap h2 {
    margin: 15px 0 10px;
    font-size: 23px;
  }
}

.blog-details-top-wrap .blog-details-meta {
  margin: 0 0 22px;
}

.blog-details-top-wrap .blog-details-meta ul li {
  display: inline-block;
  font-size: 12px;
}

.blog-details-top-wrap .blog-details-meta ul li:not(:first-child)::before {
  content: "-";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.blog-details-top-wrap p {
  line-height: 1.88;
  color: #777;
  margin: 0 0 21px;
}

.blog-details-top-wrap p.blog-details-mrg {
  margin: 30px 0 20px;
}

.blog-details-top-wrap .b-details-content-middle {
  margin: 50px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap .b-details-content-middle {
    margin: 25px 0 0;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap .b-details-content-middle {
    margin: 25px 0 0;
    margin: 0 0 20px;
  }
}

.blog-details-top-wrap .b-details-content-middle h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 38px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap .b-details-content-middle h3 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap .b-details-content-middle h3 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.blog-details-top-wrap .b-details-content-middle ul {
  padding: 0px 0 21px 20px;
}

.blog-details-top-wrap .b-details-content-middle ul li {
  padding-bottom: 22px;
  color: #777;
  list-style: disc;
}

.blog-details-top-wrap .b-details-content-middle ul li:last-child {
  padding-bottom: 0px;
}

.blog-details-top-wrap .b-details-content-middle .blog-details-img {
  margin-left: 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap .b-details-content-middle .blog-details-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap .b-details-content-middle .blog-details-img {
    margin-left: 0px;
  }
}

.blog-details-top-wrap .b-details-content-middle .blog-details-img img {
  width: 100%;
}

.blog-details-top-wrap .blog-details-tag {
  margin: 78px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top-wrap .blog-details-tag {
    margin: 18px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top-wrap .blog-details-tag {
    margin: 18px 0 0;
  }
}

.blog-details-top-wrap .blog-details-tag ul li {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
}

.blog-details-top-wrap .blog-details-tag ul li a {
  font-size: 12px;
  font-style: italic;
}

.b-details-content-social {
  overflow: hidden;
  width: 100%;
}

.blog-details-social-wrap {
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-social-wrap {
    margin: 30px 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-social-wrap {
    margin: 10px 0px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-social-wrap .blog-details-social ul li {
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-social-wrap .blog-details-social ul li {
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

.blog-details-social-wrap .blog-details-social ul li a {
  font-size: 20px;
  color: #565656;
  background-color: #EAEAEA;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  text-align: center;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .blog-details-social-wrap .blog-details-social ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
  }
}

.blog-details-social-wrap .blog-details-social ul li a:hover.facebook {
  background-color: #3b5999;
  color: #fff;
}

.blog-details-social-wrap .blog-details-social ul li a:hover.twitter {
  background-color: #55acee;
  color: #fff;
}

.blog-details-social-wrap .blog-details-social ul li a:hover.pinterest {
  background-color: #b90f16;
  color: #fff;
}

.blog-details-social-wrap .blog-details-social ul li a:hover.google-plus {
  background-color: #dd4b39;
  color: #fff;
}

.next-nextprev-wrap {
  border-top: 1px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0;
  padding: 37px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .next-nextprev-wrap {
    display: block;
    justify-content: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .next-nextprev-wrap {
    display: block;
    justify-content: inherit;
  }
}

.next-nextprev-wrap .single-bd-next-prev > a {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content {
  display: flex;
  margin-top: 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .next-nextprev-wrap .single-bd-next-prev .nextprev-img-content {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .next-nextprev-wrap .single-bd-next-prev .nextprev-img-content {
    margin-top: 5px;
  }
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-img {
  flex: 0 0 100px;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-img > a {
  position: relative;
  display: block;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-img > a:before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background: #282828;
  left: 0;
  transition: all .3s;
  opacity: 0;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-img > a img {
  width: 100%;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-content > a {
  font-size: 18px;
  font-family: "Playfair Display", serif;
  display: block;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-content .details-prev-meta {
  margin: 4px 0 0;
}

.next-nextprev-wrap .single-bd-next-prev .nextprev-img-content .nextprev-content .details-prev-meta a {
  font-size: 11px;
  color: #8A8A8A;
}

.next-nextprev-wrap .single-bd-next-prev.single-bd-next {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .next-nextprev-wrap .single-bd-next-prev.single-bd-next {
    text-align: left;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .next-nextprev-wrap .single-bd-next-prev.single-bd-next {
    text-align: left;
    margin-top: 30px;
  }
}

.next-nextprev-wrap .single-bd-next-prev:hover a {
  color: #e77878;
}

.next-nextprev-wrap .single-bd-next-prev:hover .nextprev-img-content .nextprev-content > a {
  color: #e77878;
}

.next-nextprev-wrap .single-bd-next-prev:hover .nextprev-img a:before {
  opacity: 0.4;
}

.blog-comment {
  margin: 85px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-comment {
    margin: 48px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-comment {
    margin: 20px 0 0;
  }
}

.blog-comment .no-review p {
  margin: 0 0 49px;
  color: #777;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-comment .no-review p {
    margin: 0 0 24px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-comment .no-review p {
    margin: 0 0 20px;
  }
}

.blog-comment .comment-form > h3 {
  font-size: 24px;
  margin: 0 0 7px;
}

.blog-comment .comment-form p {
  font-size: 12px;
  color: #777;
}

.blog-comment .comment-form p span {
  color: red;
}

.blog-comment .comment-form form .leave-form {
  margin: 0 0 15px;
}

.blog-comment .comment-form form .leave-form label {
  display: block;
  margin: 0 0 7px;
}

.blog-comment .comment-form form .leave-form label span {
  color: red;
}

.blog-comment .comment-form form .leave-form input {
  background-color: transparent;
  height: 50px;
  border: 1px solid #CDCDCD;
}

.blog-comment .comment-form form .leave-form input:focus {
  border: 1px solid #262626;
}

.blog-comment .comment-form form .leave-form textarea {
  background-color: transparent;
  height: 150px;
  border: 1px solid #CDCDCD;
}

.blog-comment .comment-form form .leave-form textarea:focus {
  border: 1px solid #262626;
}

.blog-comment .comment-form form .text-submit {
  margin-top: 7px;
}

.blog-comment .comment-form form .text-submit input {
  background-color: transparent;
  height: auto;
  border: none;
  display: inline-block;
  padding: 15px 35px;
  font-weight: 500;
  background-color: #262626;
  color: #fff;
  width: auto;
}

.blog-comment .comment-form form .text-submit input:hover {
  background-color: #e77878;
}

.related-post-active .blog-wrap .blog-content-4.blog-3col-content h3 {
  font-size: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .related-post-active .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related-post-active .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .related-post-active .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .related-post-active .blog-wrap .blog-content-4.blog-3col-content h3 {
    font-size: 24px;
  }
}

.related-post-active .blog-wrap .blog-content-4.blog-3col-content .blog-meta-4 {
  margin: 11px 0px 16px;
}

.related-post-active .blog-wrap .blog-content-4.blog-3col-content p {
  width: 98%;
}

@media only screen and (max-width: 767px) {
  .related-post-active .blog-wrap .blog-content-4.blog-3col-content p {
    width: 100%;
  }
}

.related-post-active .owl-nav div {
  color: #777;
  font-size: 30px;
  left: 10px;
  position: absolute;
  transform: translateY(-50%);
  top: calc(30% - 20px);
  transition: all 0.3s ease 0s;
  background-color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.related-post-active .owl-nav div.owl-next {
  left: auto;
  right: 10px;
}

.related-post-active .owl-nav div:hover {
  color: #e77878;
}

.blog-details-wrap2 {
  padding: 0 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrap2 {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap2 {
    padding: 0 0px;
  }
}

/*-------- 16. About us style ---------*/
.about-us-content img {
  width: 133px;
  margin: 0 auto;
}

.about-us-content h3 {
  font-size: 34px;
  letter-spacing: 4px;
  margin: 44px 0 14px;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .about-us-content h3 {
    font-size: 25px;
    margin: 20px 0 14px;
  }
}

.about-us-content p {
  line-height: 1.9em;
  font-size: 16px;
  font-weight: 300;
  color: #777;
  width: 65%;
  margin: 0 auto 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content p {
    width: 82%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    width: 100%;
    margin: 0 auto 20px;
  }
}

.about-us-img2 {
  margin-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-img2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-img2 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-img2 {
    margin-right: 0px;
  }
}

.about-us-img2 img {
  width: 100%;
}

.about-us-content-2 {
  margin-left: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-us-content-2 {
    margin-top: 0px;
  }
}

.about-us-content-2 h2 {
  line-height: 1.4074;
  font-size: 64px;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content-2 h2 {
    line-height: 1.2074;
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-2 h2 {
    line-height: 1.2074;
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 h2 {
    line-height: 1.2074;
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 h2 {
    line-height: 1.2074;
    font-size: 32px;
  }
}

.about-us-content-2 p {
  line-height: 1.8em;
  font-size: 16px;
  font-weight: 300;
  color: #777777;
  margin: 18px 0 19px;
  width: 85%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 p {
    width: 100%;
  }
}

.about-us-content-2 .about-us-signature img {
  width: 158px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 .about-us-signature img {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 .about-us-signature img {
    width: 90px;
  }
}

.store-content {
  padding: 80px 15px 79px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-content {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .store-content {
    padding: 41px 15px 50px;
    margin-top: 40px;
  }
}

.store-content h3 {
  font-family: "Dancing Script", cursive;
  font-size: 36px;
  margin: 0;
  line-height: 52px;
}

.store-content p {
  line-height: 32px;
  font-weight: 300;
  margin: 28px auto 30px;
  width: 77%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .store-content p {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-content p {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .store-content p {
    width: 100%;
    margin: 12px auto 30px;
  }
}

.store-img {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .store-img {
    margin-right: 0px;
  }
}

.store-img img {
  width: 100%;
}

.store-content-2 {
  margin: 75px 0 0 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .store-content-2 {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-content-2 {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .store-content-2 {
    margin: 30px 0 0 0px;
  }
}

.store-content-2 h2 {
  font-family: "Dancing Script", cursive;
  font-size: 66px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .store-content-2 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-content-2 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .store-content-2 h2 {
    font-size: 37px;
  }
}

.store-content-2 p {
  font-family: "Dancing Script", cursive;
  font-size: 24px;
  margin: 28px 0 50px;
  line-height: 1.4074;
  width: 94%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .store-content-2 p {
    margin: 28px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-content-2 p {
    margin: 12px 0 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .store-content-2 p {
    margin: 15px 0 30px;
    width: 100%;
  }
}

.choose-us-area {
  position: relative;
}

.choose-us-area .choose-us-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-us-area .choose-us-img {
    position: static;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .choose-us-area .choose-us-img {
    position: static;
    width: 100%;
  }
}

.choose-us-area .choose-us-img img {
  height: 100%;
  width: 100%;
}

.choose-us-content {
  padding: 115px 0px 90px 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .choose-us-content {
    padding: 70px 0px 62px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-us-content {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-us-content {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-us-content {
    padding: 30px 15px 30px 15px;
  }
}

.choose-us-content h2 {
  font-size: 46px;
  font-family: "Dancing Script", cursive;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .choose-us-content h2 {
    font-size: 40px;
  }
}

.choose-us-content p {
  font-size: 24px;
  font-family: "Dancing Script", cursive;
  margin: 25px 0 58px;
  line-height: 1.4074;
  width: 60%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .choose-us-content p {
    width: 93%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-us-content p {
    width: 100%;
    margin: 15px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-us-content p {
    width: 100%;
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-us-content p {
    width: 100%;
    margin: 15px 0 30px;
  }
}

.choose-us-content .choose-us-list .single-choose-list {
  display: flex;
  align-items: center;
}

.choose-us-content .choose-us-list .single-choose-list .choose-icon {
  margin-right: 30px;
}

.choose-us-content .choose-us-list .single-choose-list .choose-icon i {
  font-size: 24px;
  color: #fff;
  background-color: #ce869c;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
}

.choose-us-content .choose-us-list .single-choose-list .choose-text h4 {
  font-family: "Dancing Script", cursive;
  font-size: 24px;
  margin: 0;
}

.about-us-content-3 {
  padding-left: 90px;
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 {
    padding-left: 50px;
  }
}

.about-us-content-3 h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 4px;
  margin: 0 0 55px;
  font-family: "Playfair Display", serif;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-3 h2 {
    font-size: 30px;
    margin: 0 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-3 h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 h2 {
    font-size: 22px;
    margin: 0 0 20px;
    letter-spacing: 1px;
  }
}

.about-us-content-3 h2:before {
  width: 47px;
  height: 2px;
  background-color: #262626;
  position: absolute;
  content: "";
  left: -90px;
  top: 24px;
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 h2:before {
    left: -60px;
    top: 12px;
  }
}

.about-us-content-3 p {
  font-size: 16px;
  line-height: 32px;
}

.about-us-img {
  margin-left: 123px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-img {
    margin-left: 0px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-img {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.about-us-img img {
  width: 100%;
}

.service-content h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .service-content h3 {
    margin: 0 0 7px;
  }
}

.service-content p {
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 20px;
  width: 90%;
}

@media only screen and (max-width: 767px) {
  .service-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-content.mb-85 {
    margin-bottom: 35px;
  }
}

.custom-container-7 {
  width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-7 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-7 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-7 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-7 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-7 {
    width: 100%;
  }
}

.team-wrap .team-img {
  position: relative;
}

.team-wrap .team-img img {
  width: 100%;
}

.team-wrap .team-img .team-social {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 20px;
  z-index: 99;
  transition: all 0.4s 0.1s;
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
}

.team-wrap .team-img .team-social a {
  display: inline-block;
  margin: 0 5px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap .team-img .team-social a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.team-wrap .team-img .team-social a:hover {
  border: 1px solid #e77878;
  background-color: #e77878;
}

.team-wrap .team-content {
  margin: 28px 0 0;
}

.team-wrap .team-content h3 {
  letter-spacing: 2.67px;
  font-size: 24px;
  font-family: "Playfair Display", serif;
  margin: 0 0 5px;
}

.team-wrap .team-content span {
  letter-spacing: 1.78px;
  font-size: 16px;
  font-family: "Playfair Display", serif;
  color: #777777;
}

.team-wrap:hover .team-img::before {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}

.team-wrap:hover .team-social {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.custom-container-8 {
  width: 1180px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-8 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-8 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-8 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-8 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-8 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team-area.pb-60 {
    padding-bottom: 15px;
  }
}

/*-------- 17. Testimonial style ---------*/
.testimonial-active .single-testimonial p {
  color: #262626;
  font-size: 32px;
  font-family: "Playfair Display", serif;
  line-height: 2;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active .single-testimonial p {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active .single-testimonial p {
    font-size: 20px;
    width: 100%;
    line-height: 35px;
  }
}

.testimonial-active .single-testimonial span {
  font-size: 20px;
  display: block;
  line-height: 1;
  font-family: "Playfair Display", serif;
  color: #777777;
  margin: 26px 0 0;
}

.testimonial-active.owl-carousel .owl-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 25px auto 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-active.owl-carousel .owl-item img {
    margin: 20px auto 0;
  }
}

.testimonial-active.testimonial-style-2 .single-testimonial p {
  color: #fff;
  font-size: 24px;
  width: 59%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active.testimonial-style-2 .single-testimonial p {
    width: 77%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active.testimonial-style-2 .single-testimonial p {
    width: 100%;
    font-size: 20px;
    padding: 0 20px;
  }
}

.testimonial-active.testimonial-style-2 .single-testimonial span {
  font-size: 14px;
  color: #fff;
}

.testimonial-active.testimonial-style-2.owl-carousel .owl-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 25px auto 0;
}

.testimonial-active.testimonial-hm-17.owl-carousel .owl-item img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0px auto;
}

.testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
  font-family: "Caveat", cursive;
  font-size: 32px;
  line-height: 41px;
  width: 76%;
  margin: 54px auto 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active.testimonial-hm-17 .testimonial-style-3 p {
    width: 82%;
    margin: 10px auto 15px;
    font-size: 26px;
    line-height: 37px;
  }
}

.testimonial-active-2 .single-testimonial-2 {
  padding: 0 50px;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .single-testimonial-2 {
    padding: 0 15px;
  }
}

.testimonial-active-2 .single-testimonial-2 p {
  width: 100%;
  background-image: url("../../assets/images/icon-img/testtimonial-icon-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 50px auto;
  padding: 80px 0px 0px 0px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .single-testimonial-2 p {
    padding: 50px 0px 0px 0px;
  }
}

.testimonial-active-2 .single-testimonial-2 p > span {
  line-height: 40px;
  font-size: 32px;
  font-family: "Dancing Script", cursive;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .single-testimonial-2 p > span {
    font-size: 27px;
  }
}

.testimonial-active-2 .single-testimonial-2 > span {
  font-family: "Dancing Script", cursive;
  font-size: 18px;
  margin: 22px 0 0;
  display: block;
}

.testimonial-active-2.owl-carousel .owl-item img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 25px auto 0;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2.owl-carousel .owl-item img {
    margin: 20px auto 0;
  }
}

.testimonial-active-2.testimonial-hm15 .single-testimonial-2 {
  padding: 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active-2.testimonial-hm15 .single-testimonial-2 {
    padding: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active-2.testimonial-hm15 .single-testimonial-2 {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2.testimonial-hm15 .single-testimonial-2 {
    padding: 0 0px;
  }
}

.testimonial-active-2.testimonial-hm15 .single-testimonial-2 p {
  padding: 61px 0px 0px 0px;
}

.testimonial-active-2.testimonial-hm15 .single-testimonial-2 p > span {
  line-height: 44px;
  font-size: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active-2.testimonial-hm15 .single-testimonial-2 p > span {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2.testimonial-hm15 .single-testimonial-2 p > span {
    font-size: 30px;
  }
}

.testimonial-active-2.testimonial-hm15 .single-testimonial-2 > span {
  font-size: 16px;
  margin: 20px 0 0;
}

.testimonial-active-2.testimonial-hm15.owl-carousel .owl-item img {
  width: 92px;
  height: 92px;
  margin: 35px auto 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2.testimonial-hm15.owl-carousel .owl-item img {
    margin: 20px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-style-2.pt-55, .banner-bg-hm14.pt-55, .testimonial-area.pt-55 {
    padding-top: 35px;
  }
  .testimonial-style-2.pt-70, .banner-bg-hm14.pt-70, .testimonial-area.pt-70 {
    padding-top: 50px;
  }
  .testimonial-style-2.pb-70, .banner-bg-hm14.pb-70, .testimonial-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 18. Breadcrumb style ---------*/
.breadcrumb-bg-1 {
  background: #F9F9F9 url("../../assets/images/banner/bg-title-shop-fullwidth.png") no-repeat 20% bottom;
}

.breadcrumb-bg-2 {
  background-image: url(../../assets/images/banner/breadcrumb-bg-2.jpg);
  background-repeat: repeat;
  background-position: center center;
}

.breadcrumb-bg-3 {
  background: #E4E4EA url("../../assets/images/banner/breadcrumb-bg-3.png") no-repeat 12% bottom;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-bg-3 {
    position: relative;
  }
  .breadcrumb-bg-3:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .65;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-bg-3 {
    position: relative;
  }
  .breadcrumb-bg-3:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .65;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-bg-3 {
    background-size: auto 100%;
    background-position: -20px bottom;
    position: relative;
  }
  .breadcrumb-bg-3:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .65;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-bg-3 {
    position: relative;
  }
  .breadcrumb-bg-3:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .65;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-bg-3 {
    position: relative;
  }
  .breadcrumb-bg-3:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .65;
    width: 100%;
    height: 100%;
  }
}

.breadcrumb-bg-4 {
  color: #ffffff;
  background-image: url(../../assets/images/banner/breadcrumb-bg-4.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.breadcrumb-bg-5 {
  background: #F9F9F9 url("../../assets/images/banner/breadcrumb-bg-5.png") no-repeat center center;
}

.breadcrumb-bg-6 {
  background: #FFEBEB url("../../assets/images/banner/breadcrumb-bg-6.jpg") no-repeat center center;
  background-size: cover;
}

.breadcrumb-bg-7 {
  background-image: url(../../assets/images/banner/breadcrumb-bg-7.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content .breadcrumb-title {
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title {
    margin: 0 0 10px;
  }
}

.breadcrumb-content .breadcrumb-title h2 {
  font-size: 60px;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 30px;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 2px;
  text-transform: capitalize;
}

.breadcrumb-content ul li span {
  margin-right: 4px;
  display: inline-block;
  margin: 6px 4px 0 0;
  line-height: 1;
  position: relative;
  top: 1px;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
}

.breadcrumb-content.breadcrumb-white .breadcrumb-title h2 {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li span {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li a {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li a:hover {
  color: #e77878;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area.breadcrumb-bg-3.pt-200 {
    padding-top: 120px;
  }
  .breadcrumb-area.breadcrumb-bg-3.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area.pt-200 {
    padding-top: 75px;
  }
  .breadcrumb-area.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area.pt-200 {
    padding-top: 55px;
  }
  .breadcrumb-area.pb-120 {
    padding-bottom: 60px;
  }
}

/*-------- 19. Shop style ---------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  font-weight: 700;
  line-height: 24px;
  display: block;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  margin-left: 45px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 45px;
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #777777;
  margin-right: 11px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active {
  border-bottom: 1px solid #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #777777;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #282828;
  font-weight: 700;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 30px 0;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #282828;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #fff;
  background-color: #262626;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #fff;
  background-color: #262626;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #777777;
  font-size: 18px;
  margin-right: 10px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #262626;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 40px 55px 34px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 16px;
  margin: 0 0 37px;
  font-family: "Playfair Display", serif;
  text-transform: capitalize;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .price-filter ul li, .product-filter-wrapper .product-filter .color-filter ul li, .product-filter-wrapper .product-filter .product-tags ul li {
  margin-bottom: 5px;
  display: block;
}

.product-filter-wrapper .product-filter .price-filter ul li:last-child, .product-filter-wrapper .product-filter .color-filter ul li:last-child, .product-filter-wrapper .product-filter .product-tags ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .price-filter ul li a, .product-filter-wrapper .product-filter .color-filter ul li a, .product-filter-wrapper .product-filter .product-tags ul li a {
  color: #535353;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li a:hover, .product-filter-wrapper .product-filter .color-filter ul li a:hover, .product-filter-wrapper .product-filter .product-tags ul li a:hover {
  color: #e77878;
}

.product-filter-wrapper .product-filter .price-filter ul li, .product-filter-wrapper .product-filter .color-filter ul li, .product-filter-wrapper .product-filter .product-tags ul li {
  margin-bottom: 10px;
}

.product-filter-wrapper .product-filter .product-tags ul {
  width: 100%;
}

.product-filter-wrapper .product-filter .product-tags ul li {
  width: 50%;
  display: inline-block;
  float: left;
}

.product-filter-wrapper .product-filter .fliter-size ul li {
  display: inline-block;
  margin-right: 9px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li {
    margin-right: 2px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li:last-child {
  margin-right: 0px;
}

.product-filter-wrapper .product-filter .fliter-size ul li a {
  color: #535353;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 16px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li a {
    padding: 3px 8px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li a:hover {
  color: #fff;
  border: 1px solid #e77878;
  background-color: #e77878;
}

.product-filter-wrapper .filter-close-wrap a {
  font-size: 16px;
  color: #e77878;
}

.product-filter-wrapper .filter-close-wrap a i {
  position: relative;
  top: 3px;
  font-size: 20px;
  margin-right: 3px;
}

.shop-list-wrap .row div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap.mb-70 {
    margin-bottom: 40px;
  }
}

.shop-list-wrap:hover .shop-list-quickview {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}

.col-32 {
  flex: 0 0 32%;
  max-width: 32%;
}

@media only screen and (max-width: 767px) {
  .col-32 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-32 {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.col-68 {
  flex: 0 0 68%;
  max-width: 68%;
}

@media only screen and (max-width: 767px) {
  .col-68 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-68 {
    flex: 0 0 55%;
    max-width: 55%;
  }
}

.shop-list-content h3 {
  font-size: 24px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .shop-list-content h3 {
    font-size: 22px;
  }
}

.shop-list-content .pro-list-rating {
  margin: 20px 0 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content .pro-list-rating {
    margin: 5px 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content .pro-list-rating {
    margin: 5px 0 5px;
  }
}

.shop-list-content .pro-list-rating i {
  font-size: 12px;
  color: #d5d6d8;
}

.shop-list-content .pro-list-rating i.yellow {
  color: #f5b223;
}

.shop-list-content .pro-list-price span {
  font-size: 18px;
}

.shop-list-content .pro-list-price span.old {
  color: #777;
  margin-right: 5px;
  text-decoration: line-through;
}

.shop-list-content p {
  color: #777777;
  margin: 8px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content p {
    margin: 8px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content p {
    margin: 8px 0 20px;
  }
}

.shop-list-content .product-list-action {
  display: flex;
  align-items: center;
}

.shop-list-content .product-list-action > a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 54px;
  height: 54px;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  color: #262626;
  font-size: 18px;
  text-align: center;
}

.shop-list-content .product-list-action > a:hover {
  color: #e77878;
}

.shop-list-content .product-list-action > a i {
  line-height: 54px;
}

.shop-list-content .product-list-action .pro-list-actioncart a {
  background-color: #262626;
  font-size: 16px;
  color: #fff;
  padding: 17px 45px 18px;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .shop-list-content .product-list-action .pro-list-actioncart a {
    padding: 17px 20px 18px;
  }
}

.shop-list-content .product-list-action .pro-list-actioncart a:hover {
  background-color: #e77878;
}

.shop-list-content .product-list-action .pro-list-actioncart.pro-list-actioncart2 a {
  padding: 17px 22px 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content .product-list-action .pro-list-actioncart.pro-list-actioncart2 a {
    padding: 13px 9px 14px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content .product-list-action .pro-list-actioncart.pro-list-actioncart2 a {
    padding: 17px 15px 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content .product-list-action.pro-list-action2 > a {
    width: 45px;
    height: 45px;
  }
  .shop-list-content .product-list-action.pro-list-action2 > a i {
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content.ml-20 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content {
    margin-top: 10px;
  }
  .shop-list-content.ml-20 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content {
    margin-top: 0px;
  }
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 4px;
  }
}

.pro-pagination-style ul li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #535353;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.pro-pagination-style ul li a.active, .pro-pagination-style ul li a:hover {
  background-color: #262626;
  color: #fff;
  border: 1px solid #262626;
}

.pro-pagination-style ul li a i {
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a i {
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-35 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-30 {
    margin-top: 10px;
  }
}

.pro-pagination-style.mrg-pagination ul li {
  margin: 0 6px 0 0;
}

.pro-pagination-style.mrg-pagination ul li:last-child {
  margin: 0 0px 0 0;
}

.shop-classic-img a {
  display: block;
}

.shop-classic-img a img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-classic-img.mr-100 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-classic-img.mr-100 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-classic-img.mr-100 {
    margin-right: 0;
  }
}

.shop-classic-content {
  margin: -10px 0 0;
}

@media only screen and (max-width: 767px) {
  .shop-classic-content {
    margin: 20px 0 0;
  }
}

.shop-classic-content h2 {
  line-height: 1.4074;
  font-size: 46px;
  font-weight: 700;
  margin: 0px 0 39px;
  padding: 0 0 36px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-classic-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-classic-content h2 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-classic-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-classic-content h2 {
    font-size: 22px;
    margin: 0px 0 25px;
    padding: 0 0 21px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-classic-content h2 {
    font-size: 20px;
    margin: 0px 0 20px;
    padding: 0 0 16px;
  }
}

.shop-classic-content h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 80px;
  height: 2px;
  background-color: #d8d8d8;
}

.shop-classic-content p {
  line-height: 1.714286;
  font-size: 24px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-classic-content p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-classic-content p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-classic-content p {
    font-size: 17px;
  }
}

.shop-classic-content .shop-classic-btn {
  margin: 54px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-classic-content .shop-classic-btn {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-classic-content .shop-classic-btn {
    margin: 20px 0 0;
  }
}

.shop-classic-content .shop-classic-btn a {
  text-transform: uppercase;
}

.shop-classic-content .shop-classic-btn a i {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.shop-classic-slider .owl-item {
  overflow: hidden;
}

.shop-classic-slider .owl-item a img {
  transition: all .35s ease 0s;
  transform: scale(1);
}

.shop-classic-slider .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 30px;
}

.shop-classic-slider .owl-nav div {
  display: inline-block;
  background-color: #262626;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  color: #fff;
  margin: 0 3px;
  transition: all .3s ease 0s;
}

.shop-classic-slider .owl-nav div:hover {
  color: #e77878;
}

.shop-classic-slider .owl-nav div i {
  line-height: 42px;
  font-size: 23px;
}

.shop-classic-slider:hover a img {
  transform: scale(1.2);
}

.shop-instagram-top {
  text-align: center;
}

.shop-instagram-top h2 {
  font-size: 32px;
  line-height: 1.714286;
  width: 54%;
  margin: 0 auto;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-top h2 {
    font-size: 30px;
    width: 73%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-top h2 {
    font-size: 30px;
    width: 73%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-top h2 {
    font-size: 28px;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-top h2 {
    font-size: 20px;
    width: 100%;
  }
}

.shop-instagram-top h2 span {
  font-weight: 700;
}

.shop-instagram-wrap {
  position: relative;
  overflow: hidden;
}

.shop-instagram-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(231, 120, 120, 0.9);
  transition: all .4s;
  pointer-events: none;
}

.shop-instagram-wrap a img {
  width: 100%;
}

.shop-instagram-wrap .shop-insta-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 42px 29px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 15px 29px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
  max-width: 90%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 600;
  font-size: 32px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .1s;
  margin: 0 0 8px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 a {
  color: #fff;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content p {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .3s;
  margin: 0;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup a {
  color: #fff;
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 3px solid #fff;
  transform: translateX(20px);
  opacity: 0;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  transition: all .4s ease-in-out .4s;
  line-height: 45px;
}

.shop-instagram-wrap:hover::after {
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content h3 {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content p {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-popup a {
  transform: translateX(0px);
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .classic-slider-wrap.mr-100 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .classic-slider-wrap.mr-100 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .classic-slider-wrap.mr-100 {
    margin-right: 0;
  }
}

/*-------- 20. Sidebar style ---------*/
.sidebar-widget {
  display: block;
  overflow: hidden;
}

.sidebar-widget.sw-overflow {
  overflow: inherit;
}

.sidebar-widget h4.pro-sidebar-title {
  font-size: 24px;
  margin: 0;
  font-family: "Playfair Display", serif;
  position: relative;
  font-weight: 700;
  padding: 0 0 22px;
}

.sidebar-widget h4.pro-sidebar-title:before, .sidebar-widget h4.pro-sidebar-title:after {
  position: absolute;
  content: "";
}

.sidebar-widget h4.pro-sidebar-title:before {
  width: 60px;
  height: 3px;
  bottom: 0px;
  z-index: 2;
  background-color: #e77878;
}

.sidebar-widget h4.pro-sidebar-title:after {
  width: 180px;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  bottom: 1px;
  z-index: 1;
}

.sidebar-widget h4.sidebar-title-2 {
  font-size: 24px;
  margin: 0;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  line-height: 25px;
}

.sidebar-widget .sidebar-widget-list ul li {
  padding-bottom: 27px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list ul li {
    padding-bottom: 15px;
  }
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding-bottom: 0px;
}

.sidebar-widget .sidebar-widget-list ul li a {
  font-size: 16px;
  color: #262626;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.sidebar-widget .price-filter #slider-range {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #262626 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 23px;
  margin-left: 0;
  width: 23px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  top: -10px;
  cursor: ew-resize;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  margin: 25px 0 0;
  display: flex;
  align-items: center;
}

.sidebar-widget .price-filter .price-slider-amount .label-input span {
  font-size: 12px;
  color: #777777 !important;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 12px;
  font-weight: 400;
  width: 60%;
  height: auto;
  color: #777777 !important;
}

.sidebar-widget .price-filter .price-slider-amount button {
  width: 90px;
  background-color: transparent;
  padding: 8px 10px;
  margin-top: 15px;
  border: 1px solid #262626;
  font-size: 12px;
  font-weight: 400;
  order: 2;
  color: #262626;
  transition: all 0.3s;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #e77878;
  color: #fff;
  border: 1px solid #e77878;
}

.sidebar-widget .sidebar-widget-size ul li {
  display: inline-block;
  margin-right: 7px;
}

.sidebar-widget .sidebar-widget-size ul li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 16px;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s;
  color: #535353;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 6px 10px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a:hover {
  background-color: #e77878;
  color: #fff;
  border: 1px solid #e77878;
}

.sidebar-widget .sidebar-widget-color ul li {
  margin-bottom: 12px;
  display: inline-block;
  margin-right: 18px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 10px;
  }
}

.sidebar-widget .sidebar-widget-color ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.blue {
  background-color: #5097f7;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.brown {
  background-color: #7a5816;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.green {
  background-color: #81d742;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.pink {
  background-color: #ff0c94;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.violet {
  background-color: #8224e3;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.white {
  background-color: #f3f3f3;
}

.sidebar-widget .sidebar-widget-brand ul li {
  width: 50%;
  float: left;
  display: block;
  padding-bottom: 15px;
}

.sidebar-widget .sidebar-widget-brand ul li a {
  color: #535353;
}

.sidebar-widget .sidebar-widget-brand ul li a span {
  color: #535353;
}

.sidebar-widget .sidebar-widget-brand ul li a:hover {
  color: #e77878;
}

.sidebar-widget .shop-sidebar-banner {
  position: relative;
  overflow: hidden;
}

.sidebar-widget .shop-sidebar-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 100%;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content {
    padding: 10px;
  }
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 7px;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 3px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 18px;
  }
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
}

.sidebar-widget .shop-sidebar-banner:hover a img {
  transform: scale(1.05);
}

.sidebar-widget .shop-sidebar-banner:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-style.mr-35 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.mb-70 {
    margin-bottom: 40px;
  }
  .sidebar-widget.mb-65 {
    margin-bottom: 35px;
  }
  .sidebar-widget.mb-60 {
    margin-bottom: 20px;
  }
  .sidebar-widget-list.mt-50, .sidebar-widget-color.mt-50, .sidebar-widget-brand.mt-50 {
    margin-top: 25px;
  }
  .sidebar-widget-size.mt-55 {
    margin-top: 30px;
  }
  .price-filter.mt-65 {
    margin-top: 40px;
  }
  .shop-sidebar-style.mt-25 {
    margin-top: 50px;
  }
  .shop-sidebar-style.mr-35 {
    margin-right: 0;
  }
}

.sidebar-widget-search .search-form-2 {
  position: relative;
}

.sidebar-widget-search .search-form-2 input {
  background: transparent;
  height: 54px;
  border: 1px solid #262626;
  color: #262626;
  padding: 2px 40px 2px 20px;
}

.sidebar-widget-search .search-form-2 .button-search-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #262626;
  line-height: 1;
}

.sidebar-widget-search .search-form-2 .button-search-2:hover {
  color: #e77878;
}

.sidebar-post-wrap .single-sidebar-post {
  display: flex;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.sidebar-post-wrap .single-sidebar-post:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 72px;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img a img {
  width: 100%;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
    margin-left: 12px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-weight: 700;
  font-size: 18px;
  font-family: "Playfair Display", serif;
  margin: -5px 0 4px;
  line-height: 1.6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
    font-size: 16px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  font-size: 12px;
  color: #777;
}

@media only screen and (max-width: 767px) {
  .sidebar-post-wrap .single-sidebar-post.mb-40 {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-post-wrap.mt-45 {
    margin-top: 30px;
  }
}

.blog-categories-list ul li {
  padding-bottom: 30px;
  color: #262626;
}

.blog-categories-list ul li:last-child {
  padding-bottom: 0px;
}

.blog-categories-list ul li a {
  color: #535353;
}

.blog-categories-list ul li a:hover {
  color: #e77878;
  text-decoration: underline;
}

.blog-categories-list ul li span {
  float: right;
}

@media only screen and (max-width: 767px) {
  .blog-categories-list.mt-40 {
    margin-top: 25px;
  }
}

.blog-Archives select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -10px center;
  background-origin: content-box;
  background-repeat: no-repeat;
  max-width: 100%;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 10px 30px;
}

@media only screen and (max-width: 767px) {
  .blog-Archives.mt-40 {
    margin-top: 30px;
  }
}

.blog-tag ul li {
  display: inline-block;
  margin: 0 1px 8px 0;
}

.blog-tag ul li a {
  display: inline-block;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 1;
  padding: 7px 20px 8px;
}

@media only screen and (max-width: 767px) {
  .blog-tag.mt-40 {
    margin-top: 25px;
  }
}

.blog-banner {
  position: relative;
}

.blog-banner a img {
  width: 100%;
  transition: all .35s ease 0s;
}

.blog-banner .blog-banner-content {
  position: absolute;
  left: 30px;
  bottom: 31px;
  z-index: 99;
}

.blog-banner .blog-banner-content h3 {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 8px;
}

.blog-banner .blog-banner-content a {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.blog-banner:hover a img {
  transform: scale(1.05);
}

.blog-banner:hover::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.sidebar-newsletter {
  border: 1px solid #262626;
  padding: 8px 0;
}

.sidebar-newsletter input {
  height: 50px;
  background-color: transparent;
  color: #262626;
  border: none;
  padding: 2px 20px;
}

.sidebar-newsletter a {
  font-size: 16px;
  margin-left: 10px;
  padding: 16px 10px 0px 0px;
  display: block;
}

/*-------- 21. Product details style ---------*/
.custom-container-6 {
  width: 1230px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-6 {
    width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-6 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-6 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-6 {
    width: 708px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-6 {
    width: 100%;
  }
}

.col-pro-60 {
  flex: 0 0 60%;
  max-width: 60%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-pro-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-pro-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-pro-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-pro-40 {
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-pro-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-pro-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-pro-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-dec-right {
  float: right;
  position: relative;
  width: calc(100% - 101px);
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 999;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  color: #e77878;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.easyzoom-style span.product-sale {
  background-color: #DE3535;
  display: inline-block;
  color: #fff;
  right: 20px;
  top: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  font-weight: 300;
  z-index: 999;
}

.product-dec-left {
  position: relative;
  float: left;
  width: 92px;
}

.product-dec-left .product-dec-small {
  margin: 0 0 10px;
}

.product-dec-left .product-dec-small img {
  width: 100%;
}

.product-dec-left .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-left .product-dec-small.slick-active.slick-current {
  border: 1px solid #262626;
}

.product-dec-left span.pro-dec-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  right: auto;
  font-size: 20px;
  cursor: pointer;
  color: #262626;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 93%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 100%;
  }
}

.product-dec-left span.pro-dec-icon:hover {
  color: #e77878;
}

.product-dec-left span.pro-dec-icon.pro-dec-prev {
  margin-left: -25px;
}

.product-dec-left span.pro-dec-icon.pro-dec-next {
  margin-right: -25px;
}

.pro-details-next-prv {
  float: right;
  line-height: 20px;
  margin-top: -70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-next-prv {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-next-prv {
    margin-top: 10px;
  }
}

.pro-details-next-prv a {
  font-size: 20px;
  margin: 0 3px;
}

.product-details-slider2 .pro-details-next-prv {
  margin-top: 6px;
}

.col-38 {
  flex: 0 0 38%;
  max-width: 38%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 767px) {
  .col-38 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-62 {
  flex: 0 0 62%;
  max-width: 62%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .col-62 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.description-review-topbar {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar {
    margin-bottom: 20px;
  }
}

.description-review-topbar a {
  font-size: 18px;
  font-weight: 600;
  padding: 28px 40px 30px;
  text-align: center;
  color: #777;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a {
    font-size: 16px;
    padding: 14px 20px 16px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    font-size: 14px;
    padding: 10px 12px 12px;
  }
}

.description-review-topbar a.active {
  background-color: #262626;
  color: #fff;
}

.pro-details-banner {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .pro-details-banner {
    margin-bottom: 20px;
  }
}

.pro-details-banner a {
  display: block;
}

.pro-details-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.pro-details-banner:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.pro-details-banner:hover a img {
  transform: scale(1.05);
}

.product-dec-content {
  padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

.product-dec-content p {
  line-height: 1.88;
  margin: 0;
}

.product-dec-content ul {
  list-style-position: inside;
  margin: 30px 0 50px 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content ul {
    margin: 15px 0 20px 12px;
  }
}

.product-dec-content ul li {
  margin-bottom: 16px;
  list-style: disc;
  line-height: 1.88;
}

.product-dec-content ul li:last-child {
  margin-bottom: 0;
}

.review-wrapper h2 {
  font-size: 24px;
  margin: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (max-width: 767px) {
  .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.single-review .review-img img {
  border-radius: 100%;
}

.single-review .review-content {
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 12px;
  margin: 0;
}

.single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: bold;
}

.single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-top-wrap .review-rating i {
  font-size: 12px;
  color: #d5d6d8;
}

.single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.single-review .review-content p {
  font-size: 16px;
  color: #535353;
  font-family: "Playfair Display", serif;
  line-height: 1.714286;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > span {
  display: block;
  line-height: 1;
}

.ratting-form-wrapper p {
  font-size: 12px;
  margin: 6px 0 0;
}

.ratting-form-wrapper p span {
  color: red;
}

.ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  margin: 16px 0 29px;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
}

.ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 10px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 500;
  display: inline-block;
  width: auto;
  height: auto;
  background: #262626;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #e77878;
}

.about-shiping-content p {
  margin-bottom: 30px;
  line-height: 1.714286;
}

.about-shiping-content ul {
  margin-bottom: 20px;
  padding-left: 35px;
}

.about-shiping-content ul li {
  margin-bottom: 16px;
  list-style: disc;
  line-height: 1.88;
}

.about-shiping-content ul li:last-child {
  margin-bottom: 0px;
}

.section-padding-8 .container-fluid {
  padding: 0 75px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-8 .container-fluid {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

.related-slider-active .owl-nav div {
  color: #777;
  font-size: 30px;
  left: -30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .related-slider-active .owl-nav div {
    left: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .related-slider-active .owl-nav div {
    left: -10px;
  }
}

.related-slider-active .owl-nav div.owl-next {
  left: auto;
  right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .related-slider-active .owl-nav div.owl-next {
    right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .related-slider-active .owl-nav div.owl-next {
    right: -10px;
  }
}

.related-slider-active .owl-nav div:hover {
  color: #e77878;
}

.related-slider-active:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .sidebar-active .product-details-content {
    margin-top: 0;
  }
}

.sidebar-active.col-pro-40 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.col-pro-40.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.col-pro-40.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

.pro-details-silder2 span.pro-dec-icon2 {
  position: absolute;
  top: 87%;
  left: 50%;
  right: auto;
  font-size: 20px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #fff;
  margin: 0 3px;
  transition: all .3s ease 0s;
  text-align: center;
  cursor: pointer;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-silder2 span.pro-dec-icon2 {
    top: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-silder2 span.pro-dec-icon2 {
    top: 85%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-silder2 span.pro-dec-icon2 {
    top: 80%;
  }
}

.pro-details-silder2 span.pro-dec-icon2 i {
  line-height: 40px;
}

.pro-details-silder2 span.pro-dec-icon2.pro-dec-next2 {
  margin-right: -42px;
}

.pro-details-silder2 span.pro-dec-icon2.pro-dec-prev2 {
  margin-left: -42px;
}

.pro-details-silder2 span.pro-dec-icon2:hover {
  background-color: #e77878;
}

.pro-details-silder2 .slick-list {
  margin: 0 -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-details-silder2 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-details-silder2 .slick-list {
    margin: 0 -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-silder2 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-silder2 .slick-list {
    margin: 0 -15px;
  }
}

.pro-details-silder2 .slick-list .easyzoom-style.slick-slide {
  margin: 0 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-details-silder2 .slick-list .easyzoom-style.slick-slide {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-details-silder2 .slick-list .easyzoom-style.slick-slide {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-silder2 .slick-list .easyzoom-style.slick-slide {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-silder2 .slick-list .easyzoom-style.slick-slide {
    margin: 0 15px;
  }
}

.product-details-slider2 .pro-dec-slider-content {
  display: flex;
  justify-content: space-between;
  margin: 0 0 28px;
}

@media only screen and (max-width: 767px) {
  .product-details-slider2 .pro-dec-slider-content {
    display: block;
    justify-content: inherit;
  }
}

.product-details-slider2 .pro-dec-slider-content .pro-dec-slider-price > h3 {
  font-size: 24px;
  margin: 0;
}

.product-details-slider2 .pro-dec-slider-content .pro-dec-slider-price > h3 span {
  text-decoration: line-through;
  font-size: 14px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-slider2 .pro-dec-slider-content .pro-dec-slider-price > h3 {
    margin: 20px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-slider2 .pro-dec-slider-content .pro-dec-slider-price > h3 {
    margin: 10px 0 10px;
  }
}

.configurable-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 44px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

.configurable-wrap .configurable-color {
  display: flex;
  align-items: center;
  margin: 0 27px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-color {
    margin: 0 25px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .configurable-wrap .configurable-color {
    margin: 0 25px 25px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .configurable-wrap .configurable-color {
    margin: 0 25px 0px 0;
  }
}

.configurable-wrap .configurable-color > span {
  font-size: 18px;
  margin-right: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-color > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-color ul li {
  display: inline-block;
  margin-right: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-color ul li {
    margin-right: 5px;
  }
}

.configurable-wrap .configurable-color ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.blue {
  background-color: #5097f7;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.brown {
  background-color: #7a5816;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.green {
  background-color: #81d742;
}

.configurable-wrap .configurable-size {
  display: flex;
  align-items: center;
}

.configurable-wrap .configurable-size > span {
  font-size: 18px;
  margin-right: 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-size ul li {
  display: inline-block;
  margin-right: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size ul li {
    margin-right: 5px;
  }
}

.configurable-wrap .configurable-size ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor {
  font-size: 18px;
  display: inline-block;
  position: relative;
  color: #8D8D8D;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.pro-details-group {
  margin: 30px 0 0;
}

.pro-details-group .single-pro-group {
  display: flex;
  align-items: center;
}

.pro-details-group .single-pro-group .pro-group-content {
  margin-left: 20px;
}

.pro-details-group .single-pro-group .pro-group-content h5 {
  font-size: 13px;
  margin: 0 0 3px;
}

.pro-details-group .single-pro-group .pro-group-content span {
  font-size: 14px;
  color: #e77878;
}

/*-------- 22. Login register style ---------*/
.login-register-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.login-register-wrap h3 i {
  margin-right: 3px;
}

.login-register-wrap .login-register-form {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form {
    margin: 10px 0 0;
  }
}

.login-register-wrap .login-register-form .sin-login-register {
  margin: 0 0 21px;
}

.login-register-wrap .login-register-form .sin-login-register label {
  font-size: 12px;
}

.login-register-wrap .login-register-form .sin-login-register label span {
  color: red;
}

.login-register-wrap .login-register-form .sin-login-register input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 5px 10px;
}

.login-register-wrap .login-register-form .sin-login-register input:focus {
  border: 1px solid #262626;
}

.login-register-wrap .login-register-form .login-register-btn-remember {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form .login-register-btn-remember {
    margin: 0 0 10px;
  }
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember {
  margin-left: 25px;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember input {
  width: 13px;
  height: auto;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember label {
  line-height: 1;
  margin: 0;
  font-size: 12px;
}

.login-register-wrap .login-register-form a {
  font-size: 12px;
  color: #e77878;
}

.login-register-wrap .login-register-form a:hover {
  text-decoration: underline;
}

.login-register-wrap .login-register-form p {
  color: #777777;
  font-size: 12px;
  margin: 0 0 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
  .login-register-wrap.register-wrap {
    margin-top: 30px;
  }
}

.login-register-btn button {
  border: none;
  background-color: #262626;
  color: #fff;
  font-size: 14px;
  padding: 20px 42px 23px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .login-register-btn button {
    padding: 14px 30px 17px;
  }
}

.login-register-btn button:hover {
  background-color: #e77878;
}

/*-------- 23. Compare style ---------*/
.compare-table .table tbody tr th.first-column {
  min-width: 292px;
  font-size: 15px;
  color: #262626;
  margin: 0;
  background-color: #e9e9e9;
  font-weight: 400;
  padding: 10px 30px;
  border: none;
  background-color: #e9e9e9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  padding-top: 53px;
}

.compare-table .table tbody tr:last-child th {
  padding-bottom: 55px;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 17px 15px;
  vertical-align: middle;
  border-color: #e7e7e7;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 27px 10px 35px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 13px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 5px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #e77878;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 14px 0 7px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: auto;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #e77878;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
  font-family: "Playfair Display", serif;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #e77878;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 14px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #343538;
  padding: 10px 20px;
  color: #fff;
  display: inline-block;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #e77878;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 13px;
  line-height: 1.88;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #343538;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-stock, .compare-table .table tbody tr td.pro-weight {
  font-size: 13px;
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-dimensions {
  font-size: 13px;
  font-weight: 400;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 24. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 11px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #8A8A8A;
  letter-spacing: 1px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0 33px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 0px 0px 40px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 0px 0px 40px;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 30px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #777777;
}

.cart-table-content table tbody > tr td.product-img {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 370px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-left: 20px;
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 12px;
  color: #777;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #e77878;
}

.cart-table-content table tbody > tr td.product-price {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #1f2226;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 110px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #e77878;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 54px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  padding: 50px 0 35px;
}

.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 16px;
  margin-bottom: 17px;
}

.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #e77878;
}

/*-------- 2. Header style ---------*/
.order-tracking-area {
  position: relative;
}

.order-tracking-area .order-tracking-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-area .order-tracking-img {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: static;
  }
}

.order-tracking-area .order-tracking-img img {
  height: 100%;
  width: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
}

.order-tracking-content {
  padding: 94px 235px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-tracking-content {
    padding: 50px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-content {
    padding: 30px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-content {
    padding: 30px 15px 50px;
  }
}

.order-tracking-content p {
  line-height: 1.714286;
  margin: 0;
  text-align: center;
  width: 98%;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 30px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 10px 20px;
  color: #737373;
  font-size: 12px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 18px 40px;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #e77878;
}

/*-------- 26. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
}

.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-login-info form input[type="text"] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 48%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-login-info form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 45px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-login-info form input[type="submit"]:hover {
  background-color: #e77878;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 55px;
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -15px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #8a8a8a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #CDCDCD;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}

.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
}

.your-order-area .your-order-wrap .payment-method p {
  color: #777;
  font-size: 12px;
  margin: 14px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #e77878;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 27. Contact style ---------*/
.contact-form-area {
  margin-right: 57px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

.contact-form-area h2 {
  font-weight: 600;
  font-size: 36px;
  margin: 0 0 56px;
}

@media only screen and (max-width: 767px) {
  .contact-form-area h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

.contact-form-area input, .contact-form-area textarea, .contact-form-area select {
  height: 58px;
  background-color: transparent;
  border: 1px solid #777;
  color: #5d5d5d;
  font-size: 12px;
  padding: 2px 20px;
  margin-bottom: 20px;
}

.contact-form-area input::-webkit-input-placeholder, .contact-form-area textarea::-webkit-input-placeholder, .contact-form-area select::-webkit-input-placeholder {
  color: #5d5d5d;
  opacity: 1;
}

.contact-form-area textarea {
  padding: 20px 20px;
  height: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area textarea {
    height: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area textarea {
    height: 120px;
  }
}

.contact-form-area select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -10px center;
  background-origin: content-box;
  background-repeat: no-repeat;
  max-width: 100%;
}

.contact-form-area button.submit {
  background-color: #181818;
  color: #fff;
  padding: 15px 45px 15px;
  border: none;
}

.contact-form-area button.submit:hover {
  background-color: #e77878;
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin: 30px 0 0px;
  }
}

.contact-info-area h2 {
  font-weight: 600;
  font-size: 36px;
  margin: 0 0 56px;
}

@media only screen and (max-width: 767px) {
  .contact-info-area h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address {
  display: flex;
  align-items: center;
  margin: 0 0 8px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address i {
  color: #818a91;
  font-size: 20px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address span {
  font-weight: 600;
  margin: 0 0 0 14px;
  display: inline-block;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap p {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.contact-info-area .contact-info-bottom {
  border-top: 1px solid rgba(119, 119, 119, 0.2);
  margin-top: 34px;
  padding-top: 33px;
}

.contact-info-area .contact-info-bottom ul li {
  font-size: 12px;
  margin: 0 0 15px;
}

.contact-info-area .contact-info-bottom ul li:last-child {
  margin-bottom: 0;
}

.contact-info-area .contact-info-bottom ul li i {
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 14px;
}

.contact-info-area .contact-info-bottom .contact-info-social {
  margin: 40px 0 0;
}

.contact-info-area .contact-info-bottom .contact-info-social a {
  font-size: 20px;
  margin-right: 17px;
}

#contact-2 {
  height: 465px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #contact-2 {
    height: 365px;
  }
}

@media only screen and (max-width: 767px) {
  #contact-2 {
    height: 365px;
  }
}

/*-------- 28. Faq style ---------*/
.faq-wrap {
  margin: 0 0 0px;
}

.faq-wrap h2 {
  color: #e77878;
  font-size: 36px;
  margin: 0 0 67px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-wrap h2 {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap h2 {
    margin: 0 0 20px;
    font-size: 23px;
  }
}

.faq-wrap .single-faq-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-wrap .single-faq-wrap h3 {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}

.faq-wrap .single-faq-wrap p {
  line-height: 28px;
  color: #777;
  margin: 0;
  width: 88%;
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

/*-------- 25. My account style ---------*/
.myaccount-tab-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 10px 10px 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 0px 0px 0px;
  }
}

.myaccount-tab-menu a {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  padding: 13px 30px 12px;
  text-transform: capitalize;
  float: left;
  margin: 0 3px 0 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-tab-menu a {
    padding: 10px 15px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    padding: 4px 6px;
    margin: 0 2px 3px 2px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    font-size: 13px;
    margin: 0 2px 0px 2px;
  }
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #e77878;
  border-color: #e77878;
  color: #ffffff;
}

#myaccountContent {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #myaccountContent {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-bottom: 30px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content .welcome {
  margin: 0 0 29px;
}

.myaccount-content .welcome p {
  color: #777;
}

.myaccount-content .welcome p a {
  color: #777;
}

.myaccount-content .welcome p a:hover {
  color: #e77878;
}

.myaccount-content .welcome strong {
  color: #777777;
}

.myaccount-content > p {
  color: #777;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 25px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item label span {
  color: red;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #262626;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #262626;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #262626;
  padding: 17px 32px;
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #e77878;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #e77878;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
