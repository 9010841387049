
/*-------- 18. Breadcrumb style ---------*/

.breadcrumb-bg-1 {
	background: #F9F9F9 url("../../assets/images/banner/bg-title-shop-fullwidth.png") no-repeat 20% bottom;
}

.breadcrumb-bg-2 {
    background-image: url(../../assets/images/banner/breadcrumb-bg-2.jpg);
    background-repeat: repeat;
    background-position: center center;
}
.breadcrumb-bg-3 {
    background: #E4E4EA url("../../assets/images/banner/breadcrumb-bg-3.png") no-repeat 12% bottom;
    @media #{$xx-layout} {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: .65;
            width: 100%;
            height: 100%;
        }
    }
    @media #{$xl-layout} {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: .65;
            width: 100%;
            height: 100%;
        }
    }
    @media #{$lg-layout} {
        background-size: auto 100%;
        background-position: -20px bottom;
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: .65;
            width: 100%;
            height: 100%;
        }
    }
    @media #{$md-layout} {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: .65;
            width: 100%;
            height: 100%;
        }
    }
    @media #{$xs-layout} {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: .65;
            width: 100%;
            height: 100%;
        }
    }
}
.breadcrumb-bg-4 {
    color: #ffffff;
    background-image: url(../../assets/images/banner/breadcrumb-bg-4.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.breadcrumb-bg-5 {
    background: #F9F9F9 url("../../assets/images/banner/breadcrumb-bg-5.png") no-repeat center center;
}
.breadcrumb-bg-6 {
    background: #FFEBEB url("../../assets/images/banner/breadcrumb-bg-6.jpg") no-repeat center center;
    background-size: cover;
}

.breadcrumb-bg-7 {
    background-image: url(../../assets/images/banner/breadcrumb-bg-7.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.breadcrumb-content {
    position: relative;
    z-index: 9;
    .breadcrumb-title {
        margin: 0 0 15px;
        @media #{$xs-layout} {
            margin: 0 0 10px;
        }
        h2 {
            font-size: 60px;
            margin: 0;
            font-family: $playfairdisplay;
            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
        }
    }
    ul {
        li {
            display: inline-block;
            margin: 0 2px;
            text-transform: capitalize;
            span {
                margin-right: 4px;
                display: inline-block;
                margin: 6px 4px 0 0;
                line-height: 1;
                position: relative;
                top: 1px;
            }
            a {
               text-transform: capitalize;
            }
        }
    }
    &.breadcrumb-white {
        .breadcrumb-title {
            h2 {
                color: #fff;
            }
        }
        ul {
            li {
                color: #fff;
                span {
                    color: #fff;
                }
                a {
                    color: #fff;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}

@media #{$lg-layout} {
    .breadcrumb-area {
        &.breadcrumb-bg-3 { 
            &.pt-200 {
                padding-top: 120px;
            }
            &.pb-120 {
                padding-bottom: 80px;
            }
        }
    }
}
@media #{$md-layout} {
    .breadcrumb-area {
        &.pt-200 {
            padding-top: 75px;
        }
        &.pb-120 {
            padding-bottom: 80px;
        }
    }
}

@media #{$xs-layout} {
    .breadcrumb-area {
        &.pt-200 {
            padding-top: 55px;
        }
        &.pb-120 {
            padding-bottom: 60px;
        }
    }
}












