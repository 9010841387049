/*-------- 13. Banner style ---------*/

.custom-container-2 {
	width: 1180px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        width: 708px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
}

.banner-wrap {
    overflow: hidden;
    a {
        img {
            width: 100%;
            transform: scale(1);
            transition: all .35s ease 0s;
        }
    }
    .banner-content {
        position: absolute;
        left: 0;
        bottom: 95px;
        right: 0;
        text-align: center;
        z-index: 99;
        h4 {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 300;
            color: #fff;
            font-family: $playfairdisplay;
            letter-spacing: 2.5px;
            transition: all .35s ease 0s;
            margin: 0 0 3px;
            @media #{$md-layout} {
                font-size: 14px;
            }
            @media #{$xs-layout} {
                font-size: 16px;
                letter-spacing: 1.5px;
            }
        }
        h2 {
            text-transform: uppercase;
            font-size: 80px;
            font-weight: 400;
            color: #fff;
            font-family: $playfairdisplay;
            letter-spacing: 10px;
            line-height: 1em;
            margin: 0;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 40px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    .banner-content-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        span {
            font-size: 18px;
            font-weight: 300;
            line-height: 1;
            display: block;
            @media #{$lg-layout} {
                font-size: 16px;
            }
        }
        h2 {
            font-size: 46px;
            font-weight: 700;
            line-height: 1;
            display: block;
            margin: 13px 0 40px;
            @media #{$xl-layout} {
                font-size: 35px;
            }
            @media #{$lg-layout} {
                font-size: 30px;
                margin: 13px 0 30px;
            }
            @media #{$md-layout} {
                font-size: 22px;
                margin: 13px 0 20px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                margin: 7px 0 10px;
            }
            @media #{$sm-layout} {
                margin: 15px 0 20px;
            }
        }
    }
    .banner-content-3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 99;
        h5 {
            font-size: 16px;
            color: #fff;
            font-weight: 700;
            letter-spacing: 0px;
            margin: 0;
            transition: all 0.35s;
        }
        h2 {
            font-size: 120px;
            color: #fff;
            margin: 27px 0 44px;
            line-height: 90px;
            @media #{$xx-layout} {
                font-size: 100px;
            }
            @media #{$xl-layout} {
                font-size: 66px;
                line-height: 50px;
            }
            @media #{$lg-layout} {
                font-size: 60px;
                line-height: 48px;
                margin: 20px 0 37px;
            }
            @media #{$md-layout} {
                font-size: 45px;
                line-height: 35px;
                margin: 10px 0 20px;
            }
            @media #{$xs-layout} {
                font-size: 40px;
                line-height: 30px;
                margin: 10px 0 20px;
            }
        }
        .banner-btn {
            a {
                display: inline-block;
                border: 2px solid #fff;
                line-height: 1;
                color: #fff;
                padding: 14px 30px 16px;
                @media #{$md-layout} {
                    padding: 10px 30px 12px;
                }
                @media #{$xs-layout} {
                    padding: 10px 30px 12px;
                }
            }
        }
    }
    .banner-content-4 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 80px;
        z-index: 99;
        @media #{$lg-layout} {
            left: 30px;
        }
        @media #{$md-layout} {
            left: 30px;
        }
        @media #{$xs-layout} {
            left: 30px;
        }
        h5 {
            font-size: 16px;
            color: #fff;
            font-weight: 400;
            margin: 0;
        }
        h2 {
            font-size: 66px;
            color: #fff;
            margin: 33px 0 33px;
            line-height: 50px;
            @media #{$lg-layout} {
                font-size: 60px;
                line-height: 45px;
                margin: 26px 0 26px;
            }
            @media #{$md-layout} {
                font-size: 45px;
                line-height: 35px;
                margin: 10px 0 20px;
            }
            @media #{$xs-layout} {
                font-size: 40px;
                line-height: 30px;
                margin: 10px 0 20px;
            }
        }
        .banner-btn {
            a {
                display: inline-block;
                border: 2px solid #fff;
                line-height: 1;
                color: #fff;
                padding: 14px 30px 16px;
                @media #{$md-layout} {
                    padding: 10px 30px 12px;
                }
                @media #{$xs-layout} {
                    padding: 10px 30px 12px;
                }
            }
        }
    }
    .banner-content-5 {
        position: absolute;
        top: 50%;
        transform: translate3d(0, -80%, 0);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: transform 0.35s, color 0.35s;
        h3 {
            color: #ffffff;
            font-size: 24px;
            font-weight: 300;
            margin: 0;
            @media #{$sm-layout} {
                font-size: 20px;
            }
        }
    }
    .banner-content-6 {
        position: absolute;
        left: 50px;
        bottom: 48px;
        z-index: 9;
        @media #{$md-layout} {
            left: 35px;
            bottom: 30px;
        }
        @media #{$xs-layout} {
            left: 20px;
            bottom: 20px;
        }
        @media #{$sm-layout} {
            left: 15px;
        }
        h5 {
            letter-spacing: 2px;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            line-height: 1;
            margin: 0;
            @media #{$sm-layout} {
                letter-spacing: 0px;
                font-size: 15px;
            }
        }
    }
    .banner-content-7 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        text-align: center;
        margin: 0;
        z-index: 99;
        a {
            display: inline-block;
            color: #262626;
            background-color: #fff;
            font-size: 24px;
            font-family: $dancingscript;
            padding: 18px 18px 19px;
            @media #{$md-layout} {
                padding: 14px 16px 15px;
                font-size: 20px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
            }
        }
    }
    .banner-content-8 {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 99;
        h2 {
            font-size: 76px;
            font-weight: 700;
            font-family: $dancingscript;
            margin: 0 0 29px;
            color: #fff;
            transition: all 0.35s;
            @media #{$xx-layout} {
                font-size: 60px;
            }
            @media #{$xl-layout} {
                font-size: 52px;
            }
            @media #{$lg-layout} {
                font-size: 35px;
            }
            @media #{$md-layout} {
                font-size: 32px;
                margin: 0 0 19px;
            }
            @media #{$xs-layout} {
                font-size: 40px;
                margin: 0 0 19px;
            }
            @media #{$sm-layout} {
                font-size: 26px;
            }
        }
        a {
            font-size: 11px;
            display: inline-block;
            border: 2px solid #fff;
            line-height: 1;
            color: #fff;
            padding: 14px 45px 17px;
            @media #{$sm-layout} {
                padding: 10px 30px 13px;
            }
        }
        
    }
    .banner-content-9 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 90px;
        text-align: center;
        z-index: 99;
        @media #{$sm-layout} {
            bottom: 30px;
        }
        h3 {
            font-size: 46px;
            font-weight: 700;
            font-family: $dancingscript;
            margin: 0px;
            color: #fff;
            transition: all 0.35s;
            @media #{$xl-layout} {
                font-size: 44px;
            }
            @media #{$lg-layout} {
                font-size: 30px;
            }
            @media #{$md-layout} {
                font-size: 26px;
            }
            @media #{$xs-layout} {
                font-size: 32px;
            }
            @media #{$sm-layout} {
                font-size: 20px;
            }
        }
        h2 {
            font-size: 86px;
            font-weight: 400;
            font-family: $dancingscript;
            margin: 12px 0 58px;
            color: #fff;
            @media #{$lg-layout} {
                font-size: 55px; 
                margin: 12px 0 30px;
            }
            @media #{$md-layout} {
                font-size: 40px; 
                margin: 12px 0 18px;
            }
            @media #{$xs-layout} {
                font-size: 50px; 
                margin: 12px 0 20px;
            }
            @media #{$sm-layout} {
                font-size: 40px; 
            }
        }
        a {
            font-size: 11px;
            display: inline-block;
            border: 2px solid #fff;
            line-height: 1;
            color: #fff;
            padding: 14px 45px 17px;
            @media #{$sm-layout} {
                padding: 10px 30px 13px;
            }
        }
        
    }
    .banner-content-10 {
        position: absolute;
        left: 80px;
        bottom: 40px;
        z-index: 9;
        @media #{$xx-layout} {
            left: 40px;
        }
        @media #{$xl-layout} {
            left: 40px;
        }
        @media #{$lg-layout} {
            left: 20px;
        }
        @media #{$md-layout} {
            left: 15px;
            bottom: 15px;
        }
        @media #{$xs-layout} {
            left: 20px;
        }
        @media #{$sm-layout} {
            bottom: 20px;
        }
        h2 {
            font-family: $caveat;
            font-size: 88px;
            margin: 0;
            line-height: 1;
            @media #{$xx-layout} {
                font-size: 70px;
            }
            @media #{$xl-layout} {
                font-size: 70px;
            }
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 38px;
            }
            @media #{$xs-layout} {
                font-size: 54px;
            }
        }
        h3 {
            font-family: $caveat;
            font-size: 36px;
            margin: 2px 0 30px;
            line-height: 1;
            @media #{$xx-layout} {
                font-size: 30px;
            }
            @media #{$xl-layout} {
                font-size: 30px;
            }
            @media #{$lg-layout} {
                font-size: 23px;
                margin: 2px 0 20px;
            }
            @media #{$md-layout} {
                font-size: 24px;
                margin: 2px 0 15px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
                margin: 2px 0 18px;
            }
        }
        a {
            font-family: $caveat;
            font-size: 24px;
            font-weight: 700;
            display: inline-block;
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: rgba(0,0,0,0.38);
            border-radius: 50px;
            padding: 12px 40px 16px;
            @media #{$lg-layout} {
                padding: 6px 30px 10px;
            }
            @media #{$md-layout} {
                padding: 6px 15px 10px;
                font-size: 18px;
            }
            @media #{$xs-layout} {
                padding: 8px 25px 12px;
                font-size: 22px;
            }
        }
    }
    .banner-content-11 {
        position: absolute;
        top: 57px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        @media #{$xl-layout} {
            top: 20px;
        }
        @media #{$lg-layout} {
            top: 20px;
        }
        @media #{$md-layout} {
            top: 15px;
        }
        h3 {
            font-family: $caveat;
            font-size: 32px;
            margin: 0;
            line-height: 1;
            transition: all .3s ease 0s;
            @media #{$lg-layout} {
                font-size: 22px;
            }
            @media #{$md-layout} {
                font-size: 19px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
            }
        }
        h4 {
            font-family: $caveat;
            font-size: 36px;
            margin: 13px 0 30px;
            line-height: 1;
            color: $theme-color;
            @media #{$xl-layout} {
                margin: 3px 0 12px;
            }
            @media #{$lg-layout} {
                margin: 3px 0 12px;
                font-size: 30px;
            }
            @media #{$md-layout} {
                margin: 3px 0 9px;
                font-size: 26px;
            }
            @media #{$xs-layout} {
                margin: 8px 0 20px;
            }
        }
        a {
            font-family: $caveat;
            font-size: 24px;
            font-weight: 700;
            display: inline-block;
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: rgba(0,0,0,0.38);
            border-radius: 50px;
            padding: 12px 40px 16px;
            @media #{$lg-layout} {
                padding: 6px 30px 10px;
            }
            @media #{$md-layout} {
                padding: 6px 15px 10px;
                font-size: 18px;
            }
            @media #{$xs-layout} {
                padding: 8px 25px 12px;
                font-size: 22px;
            }
        }
    }
    .banner-content-12 {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: transform 0.35s, color 0.35s;
        transform: translate3d(0, -80%, 0);
        z-index: 99;
        h3 {
            color: #ffffff;
            font-family: $robotocondensed;
            font-size: 24px;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-align: center;
            font-weight: 700;
            margin: 0;
            @media #{$md-layout} {
                letter-spacing: 0px;
                font-size: 20px;
            }
        }
    }
    .shop-banner-position1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 140px;
        z-index: 99;
        @media #{$md-layout} {
            bottom: 40px;
        }
        @media #{$xs-layout} {
            bottom: 30px;
        }
    }
    .shop-banner-position2 {
        position: absolute;
        left: 80px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        @media #{$md-layout} {
            left: 40px;
        }
        @media #{$xs-layout} {
            left: 30px;
        }
    }
    .shop-banner-position3 {
        position: absolute;
        left: 80px;
        bottom: 105px;
        z-index: 99;
        @media #{$md-layout} {
            left: 40px;
            bottom: 40px;
        }
        @media #{$xs-layout} {
            left: 30px;
            bottom: 30px;
        }
    }
    .shop-banner-position4 {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
    }
    .shop-banner-position5 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 80px;
        z-index: 99;
        @media #{$md-layout} {
            bottom: 40px;
        }
        @media #{$xs-layout} {
            bottom: 30px;
        }
    }
    .shop-banner-position6 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 145px;
        z-index: 99;
        @media #{$md-layout} {
            bottom: 40px;
        }
        @media #{$xs-layout} {
            bottom: 30px;
        }
    }
    .banner-content-13 , .banner-content-14 {
        h2 {
            font-size: 80px;
            margin: 0 0 30px;
            font-family: $playfairdisplay;
            color: #fff;
            font-weight: 700;
            line-height: 1.3em;
            transition: all 0.35s;
            @media #{$xx-layout} {
                font-size: 65px;
            }
            @media #{$xl-layout} {
                font-size: 55px;
            }
            @media #{$lg-layout} {
                font-size: 45px;
            }
            @media #{$md-layout} {
                font-size: 32px;
            }
            @media #{$xs-layout} {
                font-size: 28px;
                margin: 0 0 20px;
            }
        }
    }
    .sb-letter-spacing {
        h2 {
            letter-spacing: 7px;
            @media #{$xs-layout} {
                letter-spacing: 3px;
            }
        }
    }
    .banner-content-14 {
        h2 {
            font-size: 120px;
            @media #{$xx-layout} {
                font-size: 80px;
            }
            @media #{$xl-layout} {
                font-size: 70px;
            }
            @media #{$lg-layout} {
                font-size: 55px;
            }
            @media #{$md-layout} {
                font-size: 45px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    .banner-content-15 {
        h2 {
            font-size: 75px;
            margin: 0 0 30px;
            font-family: $playfairdisplay;
            color: #fff;
            font-weight: 700;
            line-height: 1.3em;
            transition: all 0.35s;
            @media #{$xl-layout} {
                font-size: 62px;
            }
            @media #{$lg-layout} {
                font-size: 45px;
            }
            @media #{$md-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 28px;
                margin: 0 0 20px;
            }
        }
    }
    .banner-content-16 {
        h2 {
            font-size: 80px;
            margin: 0 0 30px;
            font-family: $playfairdisplay;
            color: #262626;
            font-weight: 700;
            line-height: 1.3em;
            transition: all 0.35s;
            @media #{$xl-layout} {
                font-size: 62px;
            }
            @media #{$lg-layout} {
                font-size: 55px;
            }
            @media #{$md-layout} {
                font-size: 45px;
            }
            @media #{$xs-layout} {
                font-size: 34px;
                margin: 0 0 20px;
                line-height: 1.2em;
            }
        }
    }
    .shop-banner-btn {
        a {
            display: inline-block;
            line-height: 1;
            background-color: #262626;
            color: #ffffff;
            padding: 20px 40px 20px 40px;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
    &.banner-zoom {
        &:hover {
            a {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
    &:hover {
        &.banner-overlay-1:before {
            background-color: #000;
            opacity: .4;
            z-index: 1;
            pointer-events: none;
        }
        &.banner-overlay-2:before {
            background-color: #000;
            opacity: .7;
            z-index: 1;
            pointer-events: none;
        }
        &.banner-overlay-3:before {
            background-color: #000;
            opacity: .5;
            z-index: 1;
            pointer-events: none;
        }
        .banner-content {
            h4 {
               letter-spacing: 1px;
            }
        }
        .banner-content-3 {
            h5 {
               letter-spacing: 1px;
            }
        }
        .banner-content-8 {
            h2 {
               letter-spacing: 1px;
            }
            a {
                border: 2px solid $theme-color;
                background-color: $theme-color;
            }
        }
        .banner-content-9 {
            h3 {
               letter-spacing: 1px;
            }
            a {
                border: 2px solid $theme-color;
                background-color: $theme-color;
            }
        }
        .banner-content-10 {
            a {
                border: 1px solid $theme-color;
                background-color: $theme-color;
                color: #fff;
            }
        }
        .banner-content-11 {
            h3 {
               letter-spacing: 1px;
            }
            a {
                border: 1px solid $theme-color;
                background-color: $theme-color;
                color: #fff;
            }
        }
        .banner-btn {
            a {
                background-color: $theme-color;
                border: 2px solid $theme-color;
            }
        }
        .banner-content-5 {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, -50%, 0);
        }
        &:hover .banner-content-12 {
            opacity: 1;
            top: 50%;
            transition: transform 0.35s, color 0.35s;
            transform: translate3d(0, -50%, 0);
        }
        .shop-banner-btn a {
            background-color: $theme-color;
        }
        .sb-letter-spacing h2 {
            letter-spacing: 1px;
        }
    }
    @media #{$xl-layout} {
        &.mb-70 {
            margin-bottom: 30px;
        }
    }
    @media #{$lg-layout} {
        &.mb-70 {
            margin-bottom: 60px;
        }
    }
    @media #{$md-layout} {
        &.mb-70 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-70 {
            margin-bottom: 30px;
        }
    }
}



.banner-action-wrap {
    line-height: 1;
    a {
        position: relative;
        display: inline-block;
        line-height: 1;
        overflow: hidden;
        margin-right: 1px;
        .banner-action-common {
            transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
            padding: 18px 30px 20px 30px;
            border: 1px solid #777;
            text-align: center;
            @media #{$md-layout} {
                padding: 14px 30px 16px 30px;
            }
            @media #{$xs-layout} {
                padding: 12px 25px 14px 25px;
            }
            span {
                color: #262626;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .banner-action-normal {
            opacity: 1;
            transform: scale(1);
        }
        .banner-action-hover {
            opacity: 0;
            transform: scale(1.5);
            background-color: $theme-color;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            span {
                color: #fff;
            }
        }
        &:hover {
            > .banner-action-common {
                border: 1px solid $theme-color;
            }
            > .banner-action-normal {
                opacity: 0;
                transform: scale(0);
            }
            > .banner-action-hover {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}
.custom-container-4 {
	width: 1170px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
    @media #{$xl-layout} {
        width: 1200px;
    }
    @media #{$lg-layout} {
        width: 960px;
    }
    @media #{$md-layout} {
        width: 708px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
}

.banner-bg-hm14 {
	background-color: #ffe8e8;
	background-image: url("../../assets/images/banner/img-bannerhm14.png");
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: 314px auto;
    > span {
        font-size: 32px;
        font-family: $dancingscript;
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    h2 {
        color: #fe5e5e;
        font-size: 46px;
        font-weight: 700;
        letter-spacing: 7px;
        margin: 10px 0 25px;
        @media #{$md-layout} {
            letter-spacing: 5px;
            font-size: 35px;
        }
        @media #{$xs-layout} {
            letter-spacing: 2px;
            font-size: 20px;
            margin: 10px 0 18px;
        }
    }
}

@media #{$xs-layout} {
    .banner-area {
        &.pb-65 {
            padding-bottom: 20px;
        }
        &.pt-70 {
            padding-top: 50px;
        }
        & .mt-55 {
            margin-top: 20px;
        }
    }
}























