/*-------- 24. Cart style ---------*/


.cart-main-area {
    @media #{$xs-layout}{
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}
h3.cart-page-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 15px;
}

.cart-table-content {
    table {
        thead > tr {
            th {
                border-top: medium none;
                font-size: 11px;
                text-transform: capitalize;
                vertical-align: middle; 
                white-space: nowrap;
                color: #8A8A8A;
                letter-spacing: 1px;
                font-weight: 400;
                text-transform: uppercase;
                padding: 0 0 33px 0;
                @media #{$lg-layout}{
                    padding: 0 0 33px 0;
                }
                @media #{$xs-layout}{
                    padding: 0 20px 33px 20px;
                }
            }
        }
        tbody > tr {
            td {
                font-size: 14px;
                padding: 0px 0px 40px;
                @media #{$xs-layout}{
                    padding: 0px 0px 40px;
                }
            }
            td.product-remove {
                width: 30px;
                a {
                    color: #777777;
                }
            }
            td.product-img {
                width: 90px;
            }
            td.product-name {
                width: 370px;
                @media #{$md-layout}{
                    width: 250px;
                }
                @media #{$xs-layout}{
                    width: 200px;
                    padding-left: 20px;
                    padding-right: 0px;
                }
                a {
                    font-size: 12px;
                    color: #777;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
            td.product-price {
                width: 90px;
                span {
                    color: #1f2226;
                }
            }
            td.cart-quality {
                width: 110px;
                
            }
            td.product-total {
                width: 70px;
                span {
                    font-weight: 600;
                }
            }
            td.product-wishlist-cart {
                > a {
                    background-color: #232323;
                    color: #fff;
                    font-size: 12px;
                    line-height: 1;
                    padding: 7px 8px;
                    text-transform: capitalize;
                    &:hover {
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
}
.cart-shiping-update-wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #CDCDCD;
    padding: 18px 0 0;
    @media #{$xs-layout}{
        display: block;
    }
    .discount-code {
        position: relative;
        flex: 0 0 50%;
        input {
            padding: 0 115px 0 0;
            border: none;
            height: 34px;
            font-size: 12px;
            color: #282828;
            background-color: transparent;
            border-bottom: 1px solid #D8D8D8;
        }
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            padding: 0;
            background-color: transparent;
            font-weight: 600;
            font-size: 14px;
            
        }
    }
    .cart-clear {
        a {
            font-weight: 600;
            display: inline-block;
            margin: 3px 0 0 7px;
        }
        @media #{$xs-layout}{
            margin-top: 20px;
        }
    }
}

.grand-total-wrap {
    background: #F9F9F9;
    padding: 54px 30px 0;
    @media #{$md-layout}{
        margin-top: 40px;
    }
    @media #{$xs-layout}{
        margin-top: 40px;
    }
    h4 {
        font-weight: 800;
        font-size: 14px;
        margin: 0;
    }
    .grand-total-content {
        padding: 50px 0 35px;
        ul {
            li {
                font-weight: bold;
                border-bottom: 1px solid #CDCDCD;
                padding-bottom: 16px;
                margin-bottom: 17px;
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                }
                span {
                    float: right;
                    font-weight: 400;
                }
            }
        }
    }
    .grand-btn {
        margin-left: -30px;
        margin-right: -30px;
        a {
            background-color: #262626;
            color: #fff;
            text-align: center;
            font-size: 14px;
            display: block;
            padding: 23px 10px 20px;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
}






