
/*-------- 12. Brand logo style ---------*/

.brand-logo-wrap {
    margin: 50px 13px 0;
    @media #{$xs-layout} {
        margin: 0px 13px 0;
    }
    .row {
        justify-content: center;
        .single-brand-logo {
            text-align: center;
            opacity: .4;
            transition: all .35s ease 0s;
            padding: 25px 20px;
            a {
                display: block;
                img {
                    width: 100%;
                }
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    &.brand-opacity-none {
        margin: 0px 13px 0;
        .row {
            .single-brand-logo {
                opacity: 1;
            }
        }
    }
}
.brand-logo-wrap-3 {
    margin: 56px 13px 0;
    @media #{$xs-layout} {
        margin: 30px 0px 0;
    }
    .row {
        justify-content: center;
        align-items: center;
        .single-brand-logo-3 {
            text-align: center;
            padding: 0px 37px 30px;
            @media #{$lg-layout} {
                padding: 0px 25px 30px;
            }
            @media #{$xs-layout} {
                padding: 0px 15px 30px;
            }
            a {
                display: block;
                img {
                    width: 100%;
                    transition: all .3s ease 0s;
                }
            }
            &:hover a img {
                transform: scale(1.1);
            }
        }
    }
}


.single-brand-logo-2 {
    text-align: center;
    opacity: .4;
    transition: all .35s ease 0s;
    a {
        img {
            width: 126px;
        }
    }
    &:hover {
        opacity: 1;
    }
}

@media #{$xs-layout} {
    .brand-logo-area {
        &.pb-60 {
            padding-bottom: 20px;
        }
        &.pb-40 {
            padding-bottom: 20px;
        }
        &.pt-70 {
            padding-top: 50px;
        }
    }
}







