/*-------- 23. Compare style ---------*/

.compare-table {
    & .table {
        & tbody {
            & tr {
                & th {
                    &.first-column {
                        min-width: 292px;
                        font-size: 15px;
                        color: #262626;
                        margin: 0;
                        background-color: #e9e9e9;
                        font-weight: 400;
                        padding: 10px 30px;
                        border: none;
                        background-color: #e9e9e9;
                        @media #{$md-layout} {
                            min-width: 200px;
                        }
                        @media #{$xs-layout} {
                            min-width: 150px;
                            padding: 10px 20px;
                        }
                    }
                }
                &:first-child th {
                    padding-top: 53px;
                }
                &:last-child th {
                    padding-bottom: 55px;
                }
                & td {
                    text-align: center;
                    padding: 17px 15px;
                    vertical-align: middle;
                    border-color: #e7e7e7;
                    &.product-image-title {
                        min-width: 293px;
                        vertical-align: bottom;
                        padding: 27px 10px 35px;
                        @media #{$xs-layout} {
                            min-width: 220px;
                        }
                        .compare-remove {
                            a {
                                font-size: 13px;
                                i {
                                    padding-right: 5px;
                                }
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                        & .image {
                            clear: both;
                            width: 100%;
                            margin: 14px 0 7px;
                            display: block;
                            img {
                                width: auto;
                            }
                        }
                        & .category {
                            float: left;
                            clear: both;
                            color: $theme-color;
                            text-transform: capitalize;
                            letter-spacing: 0.5px;
                        }
                        & .pro-title {
                            display: block;
                            overflow: hidden;
                            > a {
                                float: left;
                                clear: both;
                                font-size: 16px;
                                font-weight: 400;
                                text-transform: capitalize;
                                margin-top: 5px;
                                display: block;
                                width: 100%;
                                font-family: $playfairdisplay;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                        .compare-btn {
                            margin: 14px 0 0;
                            a {
                                background-color: #343538;
                                padding: 10px 20px;
                                color: #fff;
                                display: inline-block;
                                &:hover {
                                    background-color: $theme-color;
                                }
                            }
                        }
                    }
                    &.pro-desc {
                        & p {
                            margin: 0;
                            font-size: 13px;
                            line-height: 1.88;
                        }
                    }
                    &.pro-price {
                        font-size: 14px;
                        font-weight: 400;
                        color: #343538;
                    }
                    &.pro-sku , &.pro-stock , &.pro-weight {
                        font-size: 13px;
                        font-weight: 400;
                    }
                    &.pro-dimensions {
                        font-size: 13px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
/*------ end Compare Page Wrapper -----*/