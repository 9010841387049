// Font Family
$montserrat: 'Montserrat', sans-serif;
$playfairdisplay: 'Playfair Display', serif;
$dancingscript: 'Dancing Script', cursive;
$caveat: 'Caveat', cursive;
$robotocondensed: 'Roboto Condensed', sans-serif;
$alegreya: 'Alegreya', serif;


// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f8f8f8;




// Theme Color
$theme-color: #e77878;


// Responsive Variables 
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
















